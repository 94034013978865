// Customizable Area Start
import React, {useState} from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

interface MarketingSidebarProps {
  toggleMenu? : () => void;
  sentEmailCount?: string;
  draftEmailCount?:string;
  scheduleEmailCount?:string;
  draftClickHandler? : () => void;
  sentClckHandler? : () => void;
  scheduleClickHandler? : () => void;
  t:any;
  contactCount?:string;
  groupsCount?:string;
  dealsCount?:string;
}
const useStyles = makeStyles((theme) => ({
  marketingSidebar: {  
    "& .sidebar-title": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "48px",
            display: "flex",
            alignItems: "center",
        },
        "& .back-btn-wrap": {
            [theme.breakpoints.down("xs")]: {
            marginRight: "16px",
            },
        },
        "& .heading": {
            [theme.breakpoints.down("xs")]: {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "22px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
            },
        },
    },  
    "& .sidebar-group": {
      "&.activeGroup":{
        "& .sidebar-heading":{
          background: "#F2F8FC",
          display: "inline-block",
          padding: "8px 15px",
          borderRadius: "4px",
        }
      },
      marginBottom: "32px",
      "& .hide-underline":{
        textDecoration : "none",
      }
    },
    "& .sidebar-heading": {
      color: "#2B2B2B",
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Expletus Sans",
      fontWeight: 400,
      marginBottom: "8px",
    },
    "& .sidebar-ul": {
      borderLeft: "1px solid #E8ECF2",
      padding: "17px 0 0",
      "& .sidebar-li": {
        padding: "0",
        opacity: "1",
        minHeight: "unset",
        marginBottom: "16px",
        position: "relative",
        "&:before": {
          content: '""',
          width: "17px",
          height: "1px",
          background: "#E8ECF2",
          marginRight: "16px",
        },
        "&:last-child": {
          marginBottom: "0",
        },
        "&.label-selected": {
          "& .tab-label": {
            background: "#F2F8FC",
          },
          "& .tab-no": {
            color: "#2B2B2B",
          }
        },
        "& .label-wrapper": {
          display: "flex",
          alignItems: "center",
          flexDirection: "unset",
          justifyContent: "space-between",
          width: "100%",
          color: "#2B2B2B",
          fontSize: "14px",
          lineHeight: "19px",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          letterSpacing: "0",
          textTransform: "capitalize",
          textDecoration: "none",
          "& .tab-label": {
            padding: "8px 20px",
            borderRadius: "6px",
            background: "#ffffff",
          },
          "& .tab-no": {
            color: "#94A3B8",
          }
        },
      },
    },
    "& .deals-tab":{
      "& .label-wrapper": {
        display: "flex",
        alignItems: "center",
        flexDirection: "unset",
        justifyContent: "space-between",
        width: "100%",
        color: "#2B2B2B",
        fontSize: "14px",
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        letterSpacing: "0",
        textTransform: "capitalize",
        textDecoration: "none",
        "& .tab-label": {
          padding: "8px 20px",
          borderRadius: "6px",
          background: "#ffffff",
        },
        "& .tab-no": {
          color: "#94A3B8",
        }
      },
    },
    
  },
}));

function CustomMarketingSidebar(props: MarketingSidebarProps) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const {sentEmailCount,t,contactCount,groupsCount,toggleMenu, draftClickHandler, scheduleClickHandler, sentClckHandler,draftEmailCount, scheduleEmailCount, dealsCount} = props;
  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };
  const pageLink =  window.location.pathname;
  // if(pageLink === '/email-campaigns'){ 
  //   handleTabClick('sent')
  // }
  const contactDefaultValue = localStorage.getItem("contactCount")
  const groupDefaultValue = localStorage.getItem("groupCount")
  const sentEmailDefaultValue = localStorage.getItem("emailsCount")
  return (    
    <Box className={classes.marketingSidebar}>
     <Box className="sidebar-title">
        <Box
          className="back-btn-wrap"
          onClick={toggleMenu}
        >
          <i>
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
            </svg>
          </i>
        </Box>
        <Typography className="heading" variant="h1">
          {t("Email Direct")}
        </Typography>
      </Box>
      <Box className="sidebar-group">      
        <Box className="sidebar-heading">{t("Email Campaigns")}</Box>
        <List className="sidebar-ul">
        <ListItem className={`sidebar-li ${pageLink === '/email-campaigns' && selectedTab === null && selectedTab !== "scheduled" ? 'label-selected' : ''}`}>
            <Link to="/email-campaigns" className="label-wrapper" onClick={() => handleTabClick("sent")}>
              <Box component="span" className="tab-label" onClick={sentClckHandler} data-test-id="sent-email-list">{t("Sent")}</Box>
              <Box component="span" className="tab-no">{sentEmailCount}</Box>
            </Link>
          </ListItem>
          <ListItem className={`sidebar-li ${selectedTab === "draft" ? "label-selected" : ""}`}>
            <Link to="#" className="label-wrapper" onClick={() => handleTabClick("draft")}>
              <Box component="span" className="tab-label" onClick={draftClickHandler} data-test-id="draft-email-list">{t("Drafts")}</Box>
              <Box component="span" className="tab-no">{draftEmailCount}</Box>
            </Link>
          </ListItem>
          <ListItem className={`sidebar-li ${selectedTab === "scheduled" ? "label-selected" : ""}`}>
            <Link to="#" className="label-wrapper" onClick={() => handleTabClick("scheduled")}>
              <Box component="span" className="tab-label" onClick={scheduleClickHandler} data-test-id="scheduled-email-list">{t("Scheduled")}</Box>
              <Box component="span" className="tab-no">{scheduleEmailCount}</Box>
            </Link>
          </ListItem>
        </List>
      </Box>
      <Box className="sidebar-group">
        <Box className="sidebar-heading">{t("Direct Emails")}</Box>
        <List className="sidebar-ul">
          <ListItem className={`sidebar-li ${pageLink === "/direct-emails" ? "label-selected" : ""}`}>
            <Link to="/direct-emails" className="label-wrapper">
              <Box component="span" onClick={sentClckHandler} className="tab-label">{t("Sent")}</Box>
              <Box component="span" className="tab-no">{sentEmailCount ?? sentEmailDefaultValue}</Box>
            </Link>
          </ListItem>
          <ListItem className="sidebar-li">
            <Link to="/direct-emails" className="label-wrapper">
              <Box component="span" onClick={draftClickHandler} className="tab-label">{t("Drafts")}</Box>
              <Box component="span" className="tab-no">{draftEmailCount}</Box>
            </Link>
          </ListItem>
        </List>
      </Box>
      <Box className="sidebar-group">
        <Box className="sidebar-heading">{t("Contacts")}</Box>
        <List className="sidebar-ul">
          <ListItem  className={`sidebar-li ${pageLink === "/contacts" ? "label-selected" : ""}`}>
            <Link to="/contacts" className="label-wrapper">
              <Box component="span" className="tab-label">{t("Contacts")}</Box>
              <Box component="span" className="tab-no">{contactCount ?? contactDefaultValue}</Box>
            </Link>
          </ListItem>
          <ListItem className={`sidebar-li ${pageLink === "/groups" ? "label-selected" : ""}`}>
            <Link to="/groups" className="label-wrapper">
              <Box component="span" className="tab-label">{t("Groups")}</Box>
              <Box component="span" className="tab-no">{groupsCount ?? groupDefaultValue}</Box>
            </Link>
          </ListItem>
        </List>
      </Box>
      <Box className={`sidebar-group deals-tab ${pageLink === "/deals" ? "activeGroup" : ""}`}>
      <Link to="/deals" className="label-wrapper hide-underline">
      <Box component="span" className="tab-label sidebar-heading">{t("Deals")}</Box>
        <Box component="span" className="tab-no">{dealsCount}</Box>
        </Link>
        
      </Box>
    </Box>
  );
}

export default CustomMarketingSidebar;
// Customizable Area End
