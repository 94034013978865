import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { toast } from "react-toastify";
import CloseIcon from '@material-ui/icons/Close';
import { warningIcon } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
// Customizable Area Start
addingsteps: number;
chosenItem: string;
itemStepsLabel: string[];
//Chemical start
chemicalName: string;
maxDosage:string; 
chemicalQty:string;
chemicalArea:string;
target: any;
colourValue:any[];
risk:string;
chemicalDescription:string;
qtyStock:string;
chemicalBrand:any;
expiryDate:string;
colour:any;
lotNo:string;
cost:string;
fileview: File[];
filedata: string[];
addedFiles:any[];

brandList:any[];
targetList:any[];
isDeletingBrand: boolean;
deleteBrandId: string;
isDeletingTarget: boolean;
deleteTargetId: string;
isDeletingSelctor:boolean;
deleteSelectorId:string;
deleteDropDownId:number;
selectorType:string;
error: {
  chemicalName: string;
  chemicalQty: string;
  chemicalArea:string;
  chemicalDescription:string;
  qtyStock:string;
  chemicalBrand:string;
  expiryDate:string;

  landName:string;
  landUnit:string;
};

landName:string;
landArea:string;
landUnit:string;
mapUrl:string;
mapPlaceName:string;
mapLatLng:{lat:number, lng:number} | null
landDescription:string;
isLoading:boolean;


vesselVol:string;
vesselQty:string;
vesselspec:any[];
specification:any;
vesselSrlNo:string
vesselStock:string;
vesselexpDate:string;
materialList:any[];
material:any;

nContent:string;
pContent:string;
kContent:string;

colorSelectorId:number;
specSelectorId:number;
materialSelectorId:number;

vesselUnits:any[];
areaUnits:any[];
quantityUnits:any[];
//chemical end

defaultApiCalls:number;
categorySwitch:{
  Tool:boolean
  Asset:boolean,
  Consumable:boolean,
  Product: boolean,
};
subCategorySwitch:{
  PhytoProduct:boolean
  Land:boolean,
  Spirit:boolean,
  OtherProduct:boolean,
  Hardware:boolean,
  Machinery:boolean,
  Beer:boolean,
  Vessel:boolean,
  Chemical:boolean,
  Fertilizer:boolean,
  Wine: boolean,
}
removedImgFiles:number[];
isError:boolean;
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class AddItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  userSessionData: any;
  userToken: any;
  brandListApiID: string = "";
  createBrandApiID: string = "";
  deleteBrandApiID: string = "";
  fetchColourApiId: string = "";
  fetchVesselUnitsApiId: string = "";
  fetchAreaUnitsApiId: string = "";
  fetchQunatityUnitsApiId: string = "";
  targetListApiID: string = "";
  createTargetApiID: string = "";
  deleteTargetApiID: string = "";
  createChemicalApiId: string = "";
  fetchSpecificationApiId: string = "";
  createSelectorApiId: string = "";
  fetchMaterialApiId: string = "";
  deleteSelectorApiId: string = "";
  getCatalogueApiId: string="";
  getPermittedInventoryApiId:string="";
  
  
  setErrorBrandList: any;
  showDialogBrandList: any;
  setErrorTargetList: any;
  showDialogTargetList: any;
  currentTarget: { id: number };
  currentBrand: { id: number };
  currentMaterial: { id: number,title:string };
  currentSpec: { id: number,title:string };
  currentColor: { id: number,title:string };

  setErrorColor:any;
  showDialogColor:any;
  setErrorSpec:any;
  showDialogSpec:any;
  setErrorMaterial:any;
  showDialogMaterial:any;
  itemId:string;
  validationApiId:string="";
  // Customizable Area End
  threeStepCategories: string[] = [configJSON.ChemicalsText,configJSON.FertilizersText,configJSON.vesselsText,configJSON.landText,configJSON.PhytoProductsText];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      addingsteps: 0,
      chosenItem: 'Wine', 
      itemStepsLabel: configJSON.addingItemSteps,
      //Chemical
      chemicalName:"",
      maxDosage:"",
      chemicalQty:"",
      chemicalArea:"",
      target:null,
      colourValue: [], 
      risk:"",
      chemicalDescription:"",
      qtyStock:"",
      chemicalBrand:null,
      expiryDate:"",
      colour:null,
      lotNo:"",
      cost:"",
      fileview:[],
      filedata:[],
      addedFiles:[],
      brandList:[],
      targetList:[],
      isDeletingBrand:false,
      deleteBrandId: "",
      isDeletingTarget: false,
      deleteTargetId: "",
      isDeletingSelctor:false,
      deleteSelectorId:"",
      deleteDropDownId:0,
      selectorType:"",
      error: {
        chemicalName: "",
        chemicalQty: "",
        chemicalArea:"",
        chemicalDescription:"",
        qtyStock:"",
        chemicalBrand:"",
        expiryDate:"",  
        landName:"",
        landUnit:"",
      },


      landName:"",
      landArea:"",
      landUnit:"",
      mapUrl:"",
      mapPlaceName:"",
      mapLatLng:null,

      landDescription:"",
      isLoading:true,


      vesselVol:"",
      vesselQty:"",
      vesselspec:[],
      specification:null,
      vesselSrlNo:"",
      vesselStock:"",
      vesselexpDate:"",
      materialList:[],
      material:null,
      nContent: "",
      pContent: "",
      kContent: "",

      colorSelectorId:0,
      specSelectorId:0,
      materialSelectorId:0,
      defaultApiCalls:8,
      categorySwitch:{
        Tool:true,
        Asset:true,
        Consumable:true,
        Product: true,
      },
      subCategorySwitch:{
        Fertilizer:true,
        Spirit:true,
        OtherProduct:true,
        Hardware:true,
        Machinery:true,
        Beer:true,
        Land:true,
        Vessel:true,
        Chemical:true,
        Wine: true,
        PhytoProduct:true
      },
      removedImgFiles:[],
      vesselUnits:[],
      areaUnits:[],
      quantityUnits:[],
      isError:false,
      // Customizable Area End
    };
    this.currentBrand = { id: 0 };
    this.currentTarget = { id: 0 };
    this.currentMaterial = { id: 0 ,title:""};
    this.currentSpec = { id: 0 ,title:""};
    this.currentColor = { id: 0 ,title:""};
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData); 
    this.itemId= this.getLastPartOfURL()
    
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    if(this.itemId === ""){
      this.setState({isLoading:false})
    }
    
    this.getBrandList()
    this.fetchColour()  
    this.fetchSpecification()  
    this.getTargetList()
    this.fetchMaterial()
    this.fetchAreaUnits()
    this.fetchQuantityUnits()
    this.fetchVesselUnits()
    this.getPermittedInventory();
    if(this.itemId !== ""){
      this.getCatalogue(this.itemId);
    }
    
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if(this.state.defaultApiCalls==0 && this.state.isLoading && prevState.defaultApiCalls!==this.state.defaultApiCalls){
      this.getCatalogue(this.itemId);
    }
    this.checkPrevStateTarget(prevState);
    this.checkPrevStateBrand(prevState);
    this.checkPrevStateMaterial(prevState);
    this.checkPrevSpec(prevState);
    this.checkPrevColor(prevState);
    this.clearError(prevState);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          
          switch (apiRequestCallId) {
              case this.brandListApiID:
                this.setState((prevState)=>({
                  brandList: responseJson.data,
                  defaultApiCalls:prevState.defaultApiCalls-1
                }));
                break;
              case this.createBrandApiID:
                this.createBrandSuccess(responseJson);
                break;
              case this.deleteBrandApiID:
                this.deleteBrandSuccess(responseJson);
                break;
              
              case this.targetListApiID:
                this.setState((prevState)=>({
                  targetList: responseJson,
                  defaultApiCalls:prevState.defaultApiCalls-1
                }));
                break;
              case this.createTargetApiID:
                this.createTargetSuccess(responseJson);
                break;
              case this.deleteTargetApiID:
                this.deleteTargetSuccess(responseJson);
                break;
              case this.validationApiId:
                if(responseJson.is_valid){
                  this.setState({
                    isError: responseJson.is_valid
                  })
                }
              break;
            default:
              this.catogoryApiresponse(apiRequestCallId,responseJson)
              this.selectorApiresponse(apiRequestCallId,responseJson)
              this.inventoryApiresponse(apiRequestCallId,responseJson)

          }
        }
        else{
          this.checkSelectorError(responseJson,apiRequestCallId)
          switch(apiRequestCallId){
            case this.brandListApiID:
            case this.fetchColourApiId:
            case this.targetListApiID:
            case this.fetchSpecificationApiId:
            case this.fetchMaterialApiId:
            case this.fetchAreaUnitsApiId:
            case this.fetchVesselUnitsApiId:
            case this.fetchQunatityUnitsApiId:

              this.setState((prevState)=>({
                defaultApiCalls:prevState.defaultApiCalls-1
              }));
          }
        }
      }
    }
    // Customizable Area End
  }

  createToastNotificationError = (toastMesssage: string) => {
    toast.error(
      <div className="toast-notification">
      <div className="notification-txt">{toastMesssage}</div>
      <div className="toast-link" >
       <CloseIcon />
      </div>
    </div>, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 4000,
      icon: () => <img src={warningIcon} alt="emptydata" />,
    });
  };

  inventoryApiresponse = (apiRequestCallId:any,responseJson:any) => {
    switch(apiRequestCallId)
    {

      case this.createChemicalApiId:
        if (responseJson.data.attributes.errors && responseJson.data.attributes.errors.name) {
          const nameErrors = responseJson.data.attributes.errors.name;
          const nameErrormsg = "Name has already been taken"
          if (nameErrors.includes("has already been taken")) {
            this.itemAddedSuccess(nameErrormsg);
          }          
        }
        else{
          this.itemAddedSuccess();
        }
        this.setState({ isLoading: false });
        break;
      case this.getPermittedInventoryApiId:
        this.setState({
          subCategorySwitch: this.switchSubCategorySet(responseJson
            ),
          categorySwitch : this.switchCategorySet(responseJson
            ),
        })
        this.setState({isLoading: false})
        break;

  
    }
  }


  selectorApiresponse = (apiRequestCallId:any,responseJson:any) => {
    switch(apiRequestCallId)
    {
      case this.fetchAreaUnitsApiId:
        this.setState((prevState)=>({areaUnits: responseJson.options, defaultApiCalls:prevState.defaultApiCalls-1}));
        break;
      case this.fetchVesselUnitsApiId:
        this.setState((prevState)=>({vesselUnits: responseJson.options, defaultApiCalls:prevState.defaultApiCalls-1}));
        break;
      case this.fetchQunatityUnitsApiId:
        this.setState((prevState)=>({quantityUnits: responseJson.options, defaultApiCalls:prevState.defaultApiCalls-1}));
        break;
      case this.createSelectorApiId:
        this.createSelectorSuccess(responseJson);
        break;
      case this.fetchMaterialApiId:
        this.setState((prevState)=>({materialList: responseJson.options,materialSelectorId:responseJson.id,
        defaultApiCalls:prevState.defaultApiCalls-1}));
        break;
      case this.deleteSelectorApiId:
        this.deleteSelectorSuccess(responseJson)
        break;
      case this.fetchSpecificationApiId:
        this.setState((prevState)=>({vesselspec: responseJson.options,specSelectorId:responseJson.id,defaultApiCalls:prevState.defaultApiCalls-1}));
        break;
      case this.fetchColourApiId:
        this.setState((prevState)=>({colourValue: responseJson.options,colorSelectorId:responseJson.id, defaultApiCalls:prevState.defaultApiCalls-1}));
        break;
    }
  }

  setItemData=(metaData:any, itemData:any)=>{
    this.setState({
      nContent:metaData.n_Content ?? "",
        pContent:metaData.p_Content ?? "",
        kContent:metaData.k_Content ?? "",
        chemicalDescription:itemData.description,
        qtyStock:itemData.stock_qty,
        chemicalBrand:itemData.brand ? {id:itemData.brand.id,title:itemData.brand.name}:null,
        expiryDate:metaData.expiration_date ?? "",
        lotNo:metaData.lot_no ?? "",
        cost:metaData.cost ?? "",
        maxDosage:metaData.max_dosage ?? "",
        chemicalQty:metaData.quantity_unit ?? "",
        chemicalArea:metaData.area_unit ?? "",        
        landDescription:itemData.description,
        vesselStock: itemData.stock_qty,
        addedFiles:itemData.images?? [] ,
        target: itemData.target ? {id:itemData.target.id,title:itemData.target.name}:null,
    })
  }

  setOtherItemData=(metaData:any)=>{
    this.setState({
      vesselSrlNo: metaData.serial_no ?? "",
      vesselexpDate: metaData.expiration_date ?? "",
      landArea:metaData.area ?? "",
      landUnit:metaData.unit_of_measurement ?? "",
      vesselVol:metaData.volume ?? "",       
      mapUrl:metaData.google_maps_url ?? "",
      mapPlaceName:metaData.google_maps_place ?? "",
      risk:metaData.risk ?? ""  ,
      kContent:metaData.k_content ?? ""  ,
      pContent:metaData.p_content ?? ""  ,
      nContent:metaData.n_content ?? ""  ,
    })
  }

  catogoryApiresponse = (apiRequestCallId:any,responseJson:any) => {
    if(apiRequestCallId == this.getCatalogueApiId){
      let itemData = responseJson.data.attributes;
      let metaData = itemData.meta_data;
      let subCategory = itemData.sub_category.name.trim();
      let color = this.state.colourValue.find((x:any)=>x.name == metaData.color);
      if(color){
        color = {id:color.id,title:color.name}
      }
      let material = this.state.materialList.find((x:any)=>x.name == metaData.material);
      if(material){
        material = {id:material.id,title:material.name}
      }
      let specification = this.state.vesselspec.find((x:any)=>x.name == metaData.specification);
      if(specification){
        specification = {id:specification.id,title:specification.name}
      }
      const subcategoryStaticId = itemData.sub_category.static_id;
      const categoryStaticId = itemData.category.static_id;
      
      if (categoryStaticId === configJSON.ProductsStaticId) {
        this.setItemBasicInfoData(itemData,metaData);
        this.setItemDetailsData(itemData,metaData);
        this.setItemMoreDetailsData(metaData);
        this.setLocationData(itemData);
        this.setTastingData(itemData);
      } 
      switch (subcategoryStaticId) {
        case configJSON.WineStaticId: subCategory = configJSON.wineText; break; 
        case configJSON.BeerStaticId: subCategory = configJSON.beerText; break; 
        case configJSON.SpritStaticId: subCategory = configJSON.spiritText; break;
        case configJSON.OtherProductStaticId: subCategory = configJSON.otherText; break;
        
        case configJSON.MachineryStaticId: 
          this.setToolsSessionData(itemData,metaData,color);
          subCategory = configJSON.machineText; 
          break;
        case configJSON.HardwareStaticId: 
          this.setToolsSessionData(itemData,metaData,color);
          subCategory = configJSON.hardwareText; 
          break;

        case configJSON.LandStaticId: subCategory = configJSON.landText; break; 
        case configJSON.VesselStaticId: subCategory = configJSON.vesselsText; break; 
        case configJSON.FertilizerStaticId : subCategory = configJSON.FertilizersText; break; 
        case configJSON.PhytoProductStaticId :subCategory = configJSON.PhytoProductsText; break; 
        case configJSON.CheimcalStaticId : subCategory = configJSON.ChemicalsText; break; 
      }
      
      this.setStepsLabel(subCategory);
      this.setState({
        isLoading:false,
        addingsteps:1,
        chosenItem:subCategory,
        chemicalName:itemData.name,      
        landName:itemData.name,
        colour:color ?? null,
        material:material ?? null,
        specification: specification ?? null,                      
      })
      this.setItemData(metaData,itemData)
      this.setOtherItemData(metaData)
      this.viewMap(metaData.google_maps_url)
    }
    
  }

  setToolsSessionData = (itemData:any,metaData:any,color:any) => {
    const toolsData ={
      "brand_id": itemData.brand && itemData.brand.id,
      "brand_name": itemData.brand && itemData.brand.name,
      "product_name": itemData.name,
      "color":color,
      "addedImages":itemData.images?? []
    }
    const toolsAdditionalData = {
      "description": metaData.description,
      "stockQuantity": itemData.stock_qty,
      "serialNo": metaData.serial_no,
      "revisionDate":metaData.revision_date,
      "isEditing":true,
      "itemId":this.itemId,
      "machineCost":metaData.hourly_cost,
    }
    StorageProvider.set("toolsdata", JSON.stringify(toolsData));
    StorageProvider.set("toolsAdditionalData", JSON.stringify(toolsAdditionalData));
  }

  setItemBasicInfoData = (itemData:any,metaData:any) => {
    const infoData = {
      "product_name": itemData.name,
      "producer": metaData.producer,
      "brand": metaData.brand,
      "grape_variety":metaData.grape_variety,
      "grape_variety_per": metaData.grape_variety_percent,
      "complement": metaData.name_complement,
      "color": metaData.color,
      "dosage": metaData.dosage, 
      "quantity": metaData.quantity,
      "raw_material": metaData.raw_material,
      "raw_material_per": metaData.raw_material_percent,
      // "item_image": itemData.imagePreview,
      "age": metaData.age,
      "vintage": metaData.vintage,
      "style": metaData.style,
      "category": metaData.category,
      "prodImages":itemData.images?? []
    };
    StorageProvider.set("itemInfodata", JSON.stringify(infoData));
  }

  setItemDetailsData = (itemData:any,metaData:any) => {
    const detailsData = {
      "description": metaData.short_description,
      "aging": metaData.aging_potential,
      "volume": metaData.container_with_volume,
      "alcohol":metaData.alcohol,
      "production": metaData.production_process,
      // "productionProcessDes":metaData.productionProcessDes,
      "agingProcess": metaData.aging_process,
      "sku": metaData.sku,
      "gtinNumber": metaData.gtin, 
      "trophyData": itemData.trophies.map((trophy:any,index:number)=>{
        return {
          id:index+1,
          trophyId:trophy.id,
          trophyName: trophy.name,
          trophyYear: trophy.year,
          trophyResult: trophy.result,
          trophyComment: trophy.comment,
          // trophyImages: []
        }
      }),
      "priceData" : itemData.prices.map((price:any)=>{
        return {
          priceLabel: price.title,
          itemPrice: price.amount,
          isTaxIncluded: price.tax_included,
          labelId:price.id,
          id:price.id
        }
      }),
      "certificates" : itemData.certificates ?? [],
      "oldCertificates" : itemData.certificates ?? [],
      "otherProComment": metaData.comment,
      "srm":metaData.srm,
      "og":metaData.og,
      "fg":metaData.fg,
      "ebc":metaData.ebc,
      "ibu":metaData.ibu,
    };
    StorageProvider.set("itemDetailsdata", JSON.stringify(detailsData));
  }

  setItemMoreDetailsData = (metaData:any) => {
    const moreDetailsData = {
      "hop": metaData.hop ?? "",
      "mashing": metaData.mashing ?? "",
      "strength": metaData.strength ?? "",
      "fermentation":metaData.fermentation ?? "",
      "ingredients": metaData.ingredients ?? "",
    };
    const allergens = {
      "allergens":metaData?.allergens ?? "",
      "isEditing":true,
      "itemId":this.itemId,
    }
    StorageProvider.set("moreDetailsdata", JSON.stringify(moreDetailsData));
    StorageProvider.set("allergensdata", JSON.stringify(allergens));
  }

  setLocationData = (itemData:any) => {
    const location = itemData.location;
    const locationData = {
      "appellation": location.appellation,
      "appellation_type": location.appellation_type,
      "country": location.country,
      "region":location.region,
      "subregion": location.sub_region,
      "style": location.style ?? "", 
    };
    StorageProvider.set("itemLocationdata", JSON.stringify(locationData));
  }

  setTastingData = (itemData:any) => {
    const tasting = itemData.tasting_notes;
    const tastingData ={
      "openingTime": tasting.opening_time,
      "temperature": tasting.temperature,
      "comment": tasting.comment,
      "eye":String(tasting.eye),
      "nose": String(tasting.nose),
      "mainAroma": String(tasting.main_aromas),
      "mouth":String(tasting.mouth),
      "foodPairing":String(tasting.food_pairing),
      "flavours":String(tasting.flavours),
    }
    StorageProvider.set("tastingdata", JSON.stringify(tastingData));
  }

  switchSubCategorySet = (permitted:any) =>{
    return {
      PhytoProduct:permitted?.Consumable["Phyto Product"]?.is_mark ?? false,
      Land:permitted?.Asset?.Land?.is_mark ?? false,
      OtherProduct:permitted?.Product["Other Product"]?.is_mark ?? false,
      Hardware:permitted?.Tool?.Hardware?.is_mark ?? false,
      Beer:permitted?.Product?.Beer?.is_mark ?? false,
      Machinery:permitted?.Tool?.Machinery?.is_mark ?? false,
      Vessel:permitted?.Asset?.Vessel?.is_mark ?? false,
      Chemical:permitted?.Consumable?.Chemical?.is_mark ?? false,
      Spirit:permitted?.Product?.Spirit?.is_mark ?? false,
      Fertilizer:permitted?.Consumable?.Fertilizer?.is_mark ?? false,
      Wine: permitted?.Product?.Wine?.is_mark ?? false,
    }
  }
  switchCategorySet = (permitted:any) => {
    const subCategory = this.switchSubCategorySet(permitted)
    return {
      Tool:subCategory.Hardware || subCategory.Machinery,
      Consumable:subCategory.Chemical || subCategory.Fertilizer || subCategory.PhytoProduct,
      Asset:subCategory.Land || subCategory.Vessel ,
      Product: subCategory.Wine || subCategory.Beer || subCategory.OtherProduct 
    }
  }
  
 

  checkSelectorError = (responseJson: any, apiRequestCallId: string) => {
    if (
      apiRequestCallId === this.createSelectorApiId 
    ) {
      const msg: string = responseJson.errors[0];
      let errorSelector = this.setErrorColor;
      switch(this.state.selectorType){
        case "color":
          errorSelector = this.setErrorColor;
          break;
        case "specifications":
          errorSelector = this.setErrorSpec;
          break;
        case "material":
          errorSelector = this.setErrorMaterial;
          break;
      }
      
      errorSelector(msg);
    }
  };

  checkPrevStateTarget = (prevState: S) => {
    if (prevState.targetList !== this.state.targetList) {
      if (this.currentTarget.id !== 0) {
        const curTarget = this.state.targetList.find(
          (x) => x.id == this.currentTarget.id
        );
        this.setState({
          target: {
            id: curTarget.id,
            title: curTarget.name,
          },
        });
      }
      this.currentTarget = { id: 0 };
    }
  };


  checkPrevStateMaterial = (prevState: S) => {
    if (prevState.materialList !== this.state.materialList) {
      if (this.currentMaterial.id !== 0) {
        const curMaterial = this.state.materialList.find(
          (x) => x.id == this.currentMaterial.id
        );
        this.setState({
          material: {
            id: curMaterial.id,
            title: curMaterial.name,
          },
        });
      }
      else if (this.currentMaterial.title !== "") {
        const curMaterial = this.state.materialList.find(
          (x) => x.name == this.currentMaterial.title
        );
        this.setState({
          material: {
            id: curMaterial.id,
            title: curMaterial.name,
          },
        });
      }
      this.currentMaterial = { id: 0,title:"" };
    }
  };

  checkPrevSpec = (prevState: S) => {
    if (prevState.vesselspec !== this.state.vesselspec) {
      if (this.currentSpec.id !== 0) {
        const curSpec = this.state.vesselspec.find(
          (x) => x.id == this.currentSpec.id
        );
        this.setState({
          specification: {
            id: curSpec.id,
            title: curSpec.name,
          },
        });
      }
      else if (this.currentSpec.title !== "") {
        const curSpec = this.state.vesselspec.find(
          (x) => x.name == this.currentSpec.title
        );
        this.setState({
          specification: {
            id: curSpec.id,
            title: curSpec.name,
          },
        });
      }
      this.currentSpec = { id: 0,title:"" };
    }
  };

  checkPrevColor = (prevState: S) => {
    if (prevState.colourValue !== this.state.colourValue) {
      if (this.currentColor.id !== 0) {
        const curClr = this.state.colourValue.find(
          (x) => x.id == this.currentColor.id
        );
        this.setState({
          colour: {
            id: curClr.id,
            title: curClr.name,
          },
        });
      }
      else if (this.currentColor.title !== "") {
        const curClr = this.state.colourValue.find(
          (x) => x.name == this.currentColor.title
        );
        this.setState({
          colour: {
            id: curClr.id,
            title: curClr.name,
          },
        });
      }
      this.currentColor = { id: 0,title:"" };
    }
  };

  checkPrevStateBrand = (prevState: S) => {
    if (prevState.brandList !== this.state.brandList) {
      if (this.currentBrand.id !== 0) {
        const curBrand = this.state.brandList.find(
          (x) => x.id == this.currentBrand.id
        );
        this.setState({
          chemicalBrand: {
            id: curBrand.id,
            title: curBrand.attributes.name,
          },
        });
      }
      this.currentBrand = { id: 0 };
    }
  };

isStepCompleted(step: number) {
return this.state.addingsteps > step;
}

chooseSubcategory = (item: string) => {
    if (item) {
        this.resetForms();
        this.setState({
            addingsteps: this.state.addingsteps + 1,
            chosenItem: item,
        })
        this.setStepsLabel(item);
    }
}

setStepsLabel = (item:string) => {
  switch(item){
    case configJSON.wineText:
      return(this.setState({itemStepsLabel: configJSON.addingItemSteps}));
    case configJSON.beerText: 
        return(this.setState({itemStepsLabel: configJSON.beerAddingSteps}));
    case configJSON.otherText: 
    return(this.setState({itemStepsLabel: configJSON.otherProdAddingSteps}));
    case configJSON.hardwareText:
      return(this.setState({itemStepsLabel: configJSON.addingItem3Steps}));
    case configJSON.machineText:
      return(this.setState({itemStepsLabel: configJSON.addingItem3Steps}));
    default: 
    this.setState({itemStepsLabel: this.threeStepCategories.includes(item)?configJSON.addingItem3Steps:configJSON.addingItemSteps})
    break;
}
}
backStep = () => {
  if(this.state.addingsteps==1 && this.itemId)
  {
    this.props.navigation.navigate("ViewItem",{":itemId":this.itemId});
  }
  else {
    this.setState({ addingsteps: this.state.addingsteps - 1})
  }
}

fieldValidation = () => {
  let error = this.state.error;
  let errorFlag = false;
  let { chosenItem } = this.state
  const path = window.location.pathname;
  const editId = path.split("/")[2] || "";
  switch (chosenItem){
  case configJSON.ChemicalsText:
  case configJSON.PhytoProductsText:
  case configJSON.FertilizersText:   
    if (this.state.chemicalName.trim()=="") {
      error.chemicalName = "Please enter Name";
      errorFlag = true;
    }
    if(editId==="" && !this.state.isError){
      error.landName = "Name should be unique";
      errorFlag = true;
    }else{
      error.landName = "";
      errorFlag = false;
    }
    break;
  case configJSON.landText:
  case configJSON.vesselsText:
    if (this.state.landName.trim()=="") {
      error.landName = "Please enter Name";
      errorFlag = true;
    }
    if(editId==="" && !this.state.isError){
      error.landName = "Name should be unique";
      errorFlag = true;
    }else{
      error.landName = "";
      errorFlag = false;
    }

    break;
  }
  if(errorFlag){
    this.setState({error: error})
  }
  return errorFlag
}
handleNameValidationApi = (value:string,staticId:number) => {
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.validationApiId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.nameValidationApi}?name=${value}&sub_category_static_id=${staticId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
nextStep = () => {
  let errorFlag = this.fieldValidation(); 
    
  if (errorFlag) {
    return
  }
  this.setState({ addingsteps: this.state.addingsteps + 1 })
}

goBack = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
}
itemAddedSuccess=(errorMsg?:string)=>{
   if(errorMsg){
    this.createToastNotificationError(errorMsg);
    this.setState({
      addingsteps:1,
      error: {
        ...this.state.error,
        chemicalName:errorMsg,
        landName:errorMsg,
      }
    })
   }
   else{
    StorageProvider.removeMultiple(["itemInfodata","itemDetailsdata","moreDetailsdata","itemLocationdata","tastingdata","toolsdata","toolsAdditionalData","trophyImg","allergensdata"])
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'ItemSuccessPage');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {name: this.state.chosenItem,isUpdate:this.itemId!=""});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
   }
  }

//Chemical Start
handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
    let error = this.state.error;   
    switch (name) {
      case "chemicalName":
        error.chemicalName = "";
        this.handleNameValidationApi(value,configJSON.CheimcalStaticId);
        break;
  
      case "landName":
        error.landName = "";
        this.handleNameValidationApi(value,configJSON.LandStaticId);
        break;
  
      default:
        break;
    }
    this.setState({ error: error });
};

handleNumberOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name as keyof S;
  const value = event.target.value;

  let regexPattern: RegExp;

  if (["cost", "pContent", "nContent", "kContent","vesselStock"].includes(name)) {
    regexPattern =/^([1-9]\d*|0)(\.\d*)?$/;
  } else {
    // Use the original regex pattern for other fields
    regexPattern = /^([1-9]\d*)(\.\d{0,2})?$/;
  }

  const isValidInput = regexPattern.test(value);
  if (isValidInput || value === '') {
    // If the input is valid or empty, update the state
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
  }
}

handleSelectChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
    let error = this.state.error;
    switch (name) {
      case "chemicalQty":
        error.chemicalQty = "";
        break;

      case "chemicalArea":
        error.chemicalArea = "";
        break;

      case "landUnit":
        error.landUnit = "";
        break;
    }
    this.setState({ error: error });
  }

changeExpiryDate = (date: any) => {
  this.setState({
    expiryDate: date?.isValid ? date.format("YYYY-MM-DD") : ""
  });
};
vesselChangeHandler = (date: any) => {
  this.setState({
    vesselexpDate: date?.isValid ? date.format("YYYY-MM-DD") : ""
  });
};

targetChangeHandler = (e: any, newValue: any) => {
    this.setState({     
        target: newValue,
    });
  };
  colorChangeHandler=(e: any, newValue: any)=>{
    this.setState({     
      colour: newValue,
    });
  }
  specChangeHandler = (e: any, newValue: any) => {
    this.setState({     
      specification: newValue,
    });
  };

  brandChangeHandler = (e: any, newValue: any) => {
    this.setState({     
        chemicalBrand: newValue,
    });
 };
  materialChangeHandler=(e: any, newValue: any)=>{
  this.setState({     
    material: newValue,
  });
 }

fileUpload = (e:React.ChangeEvent<HTMLInputElement>) => {
  let fileObject:FileList;
  if(e.target.files != null){
      fileObject= e.target.files;
      const arrayLength = this.state.fileview.length;
      if(arrayLength<6){
        this.setState({fileview: [...this.state.fileview,...Object.values(fileObject).slice(0,6-arrayLength)]});
        this.readFile(Object.values(fileObject))
      }
  }
}

 setFileContent = (e:any) => {
  const fileContent = e.target?.result;
  if(fileContent){
    const fileDataInString = fileContent?.toString().split(",")[1];

    this.setState((prevState)=>({filedata:[...prevState.filedata,fileDataInString]}))
  }
};
 readFile= (files:File[]) => {
  
  files.forEach(file => {
    const reader = new FileReader();
  reader.onload = this.setFileContent;
    reader.readAsDataURL(file);
  });
}

handleEditClick = () => {this.inputRef.current?.click()}

getBrandList=()=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.brandEndPoint
  );
  this.brandListApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

addBrand = (data: any, setError?: any, setShowDialog?: any) => {
  this.setErrorBrandList = setError;
  this.showDialogBrandList = setShowDialog;
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  
  if(data.id != null && data.id > 0){
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteBrandEndPoint + `/${data.id}?name=${data.title}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethod
    );
  }
  else{
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandEndPoint + `?name=${data.title}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
  }
  
  this.createBrandApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

createBrandSuccess = (responseJson: any) => {
  this.currentBrand = { id : responseJson.data.attributes.id}
  this.getBrandList();
  this.showDialogBrandList(false);
};
createTargetSuccess = (responseJson: any) => {
  this.currentTarget = { id : responseJson.data.attributes.id}
  this.getTargetList();
  this.showDialogTargetList(false);
};

deleteBrandSuccess = (responseJson:any) => {  
  if(this.state.chemicalBrand && this.state.chemicalBrand.id == this.state.deleteBrandId){
    this.setState({chemicalBrand:null})
  }
  this.getBrandList();
  this.deleteBrandModalClose();
}
deleteBrand = (data: any) => {
  this.setState({ isDeletingBrand: true, deleteBrandId: data.id });
};
deleteBrandModalClose = () => {
  this.setState({
    isDeletingBrand: false,
    deleteBrandId:""
  });
};
deleteBrandApi = () => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteBrandApiID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.deleteBrandEndPoint + `/${this.state.deleteBrandId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.txtDelete
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

fetchColour = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchColourApiId = requestMessage.messageId;
  this.getDropdownApi("color",requestMessage); 
};

fetchAreaUnits = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchAreaUnitsApiId = requestMessage.messageId;
  this.getDropdownApi("area measurement",requestMessage); 
};

fetchVesselUnits = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchVesselUnitsApiId = requestMessage.messageId;
  this.getDropdownApi("vessel measurement",requestMessage); 
};

fetchQuantityUnits = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchQunatityUnitsApiId = requestMessage.messageId;
  this.getDropdownApi("quantity measurement",requestMessage); 
}

getDropdownApi = (name:string,requestMessage:Message) => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.userToken.meta.token,
  };  

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.productsDropdownAPIEndPoint + name
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getTargetList=()=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.targetEndPoint
  );
  this.targetListApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

addTarget = (data: any, setError?: any, setShowDialog?: any) => {
  this.setErrorTargetList = setError;
  this.showDialogTargetList = setShowDialog;

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  
  if(data.id != null && data.id > 0){
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.targetEndPoint + `/${data.id}?name=${data.title}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethod
    );
  }
  else{
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.targetEndPoint + `?name=${data.title}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
  }
  
  this.createTargetApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

deleteTargetSuccess = (responseJson:any) => {  
  if(this.state.target && this.state.target.id == this.state.deleteTargetId){
    this.setState({target:null})
  }
  this.getTargetList();
  this.deleteTargetModalClose();
}

deleteTarget = (data: any) => {
  this.setState({ isDeletingTarget: true, deleteTargetId: data.id });
};

deleteTargetModalClose = () => {
  this.setState({
    isDeletingTarget: false,
    deleteTargetId:""
  });
};
deleteTargetApi = () => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteTargetApiID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.targetEndPoint + `/${this.state.deleteTargetId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.txtDelete
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

clearError = (prevState: S) => {
  let error = { ...this.state.error };
  if (prevState.qtyStock !== this.state.qtyStock) {
    error.qtyStock = "";
  }

  if (JSON.stringify(error) !== JSON.stringify(this.state.error)) {
    this.setState({ error: error });
  }
};
createConsumables = (subCatId:number) => {
  let error = this.state.error;
  let hasError = false;

  if (this.state.qtyStock.length > 9) {
    error.qtyStock = this.t("Stock quantity cannot exceed 9 digits and must be numeric");
    this.setState({ error: error });
    hasError = true;
  }
  if (hasError) {
    return;
  }
  const { chosenItem } = this.state;

  let formdata = new FormData();

  if(this.state.maxDosage!==''){    
    formdata.append("meta_data[max_dosage]",this.state.maxDosage);
  }
  if(this.state.chemicalBrand!==null){
    formdata.append("brand_id",this.state.chemicalBrand.id);
  }
  if(this.state.target!==null && chosenItem != configJSON.FertilizersText){
    formdata.append("target_id",this.state.target.id);
  }
  if(chosenItem==configJSON.FertilizersText){
    formdata.append("meta_data[p_content]",this.state.pContent);
    formdata.append("meta_data[n_content]",this.state.nContent);
    formdata.append("meta_data[k_content]",this.state.kContent);
  }
  
  formdata.append("name",this.state.chemicalName);
  formdata.append("stock_qty",this.state.qtyStock);
  formdata.append("description",this.state.chemicalDescription);
  formdata.append("meta_data[risk]",this.state.risk);
  formdata.append("meta_data[lot_no]",this.state.lotNo);
  formdata.append("meta_data[expiration_date]",this.state.expiryDate);
  formdata.append("meta_data[cost]",this.state.cost);
  formdata.append("meta_data[quantity_unit]",this.state.chemicalQty);
  formdata.append("meta_data[area_unit]",this.state.chemicalArea);
  if(this.state.colour !== null && chosenItem == configJSON.ChemicalsText){
    formdata.append("meta_data[color]",this.state.colour.title); 
  }
  this.createCatalogueApiCall(formdata,configJSON.ConsumablesStaticId,subCatId);
}

createAssets = (subCatId:number) => {
  let formdata = new FormData();
  const { chosenItem } = this.state;
  
  formdata.append("name",this.state.landName);
  formdata.append("description",this.state.landDescription);
  formdata.append("meta_data[unit_of_measurement]",this.state.landUnit);
  if(chosenItem == configJSON.landText){
    formdata.append("meta_data[area]",this.state.landArea);
    formdata.append("meta_data[google_maps_url]",this.state.mapUrl);
    formdata.append("meta_data[google_maps_place]",this.state.mapPlaceName);
  }
  else{
    formdata.append("meta_data[volume]",this.state.vesselVol);
    if(this.state.chemicalBrand!==null){
      formdata.append("brand_id",this.state.chemicalBrand.id);
    }
    if(this.state.material!==null){
      formdata.append("meta_data[material]",this.state.material.title);
    }
    if(this.state.specification!==null){
      formdata.append("meta_data[specification]",this.state.specification.title);
    }
    formdata.append("meta_data[serial_no]",this.state.vesselSrlNo);
    formdata.append("meta_data[expiration_date]",this.state.vesselexpDate);
    formdata.append("stock_qty",this.state.vesselStock);
   
  }
  this.createCatalogueApiCall(formdata,configJSON.AssetsStaticId,subCatId);
}

createCatalogue= () => {
  const { chosenItem } = this.state;
  switch (chosenItem){
    case configJSON.ChemicalsText:
      this.createConsumables(configJSON.CheimcalStaticId);
      break;
    case configJSON.FertilizersText:
      this.createConsumables(configJSON.FertilizerStaticId);
      break;
    case configJSON.PhytoProductsText:
      this.createConsumables(configJSON.PhytoProductStaticId);
      break;
    case configJSON.landText:
      this.createAssets(configJSON.LandStaticId);
      break;
    case configJSON.vesselsText:
      this.createAssets(configJSON.VesselStaticId);
  }
}

createCatalogueApiCall = (formdata:FormData,catId:number,subCatId:number) => {
  const header = {
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.createChemicalApiId = requestMessage.messageId;

  if(this.itemId != ""){
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCatalogueEndPoint + `/${this.itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editApiMethod
    );
  }
  else{
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCatalogueEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
  }
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)

  );

  formdata.append("category_static_id",catId.toString())
  formdata.append("sub_category_static_id",subCatId.toString());
  

  if(this.state.fileview.length > 0){
    this.state.fileview.forEach((file:File,index:number) => {
      formdata.append(`images[${index}][data]`,file)
    });    
  }
  if(this.state.removedImgFiles.length > 0){
    this.state.removedImgFiles.forEach((id:number,index:number) => {
      formdata.append(`images[${index+this.state.fileview.length}][id]`,String(id))
      formdata.append(`images[${index+this.state.fileview.length}][remove]`,String(true))
    });    
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  
  this.setState({ isLoading:true});
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

resetForms = () => {
  this.setState({
    error: {
      expiryDate:"",  
      landUnit:"",
      chemicalQty: "",
      chemicalArea:"",
      chemicalDescription:"",
      chemicalName: "",
      chemicalBrand:"",
      qtyStock:"",
      landName:"",
    },
    deleteBrandId: "",
    landDescription:"",
    chemicalQty:"",
    chemicalArea:"",
    target:null,
    risk:"",
    landName:"",
    chemicalDescription:"",
    qtyStock:"",
    chemicalBrand:null,
    expiryDate:"",
    colour:null,
    lotNo:"",
    cost:"",
    fileview:[],
    isLoading:false,
    filedata:[],
    isDeletingBrand:false,
    chemicalName:"",
    isDeletingTarget: false,
    maxDosage:"",
    deleteTargetId: "",
   
    mapUrl:"",
    mapPlaceName:"",
    pContent: "",
    nContent: "",
    vesselStock:"",


    landUnit:"",
    landArea:"",
    vesselexpDate:"",


    vesselVol:"",
    vesselQty:"",
    vesselSrlNo:"",
    kContent: ""
  });
  StorageProvider.removeMultiple(["itemInfodata","itemDetailsdata","moreDetailsdata","itemLocationdata","tastingdata","toolsdata","toolsAdditionalData","trophyImg","allergensdata"])
}

fetchSpecification = () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchSpecificationApiId = requestMessage.messageId;
  this.getDropdownApi("specifications",requestMessage);
};
fetchMaterial=()=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchMaterialApiId = requestMessage.messageId;
  this.getDropdownApi("material",requestMessage);
}

addColor = (data: any, setError?: any, setShowDialog?: any) => {
  this.setErrorColor = setError;
  this.showDialogColor = setShowDialog;
  this.currentColor = {id:data.id??0,title:data.title}
  this.setState({selectorType:"color"})
  this.addDeleteSelectorApi(data.id ?? 0,data.title,this.state.colorSelectorId)  
}

addSpecification = (data: any, setError?: any, setShowDialog?: any) => {
  this.setErrorSpec = setError;
  this.showDialogSpec = setShowDialog;
  this.currentSpec = {id:data.id??0,title:data.title}
  this.setState({selectorType:"specifications"})
  this.addDeleteSelectorApi(data.id ?? 0,data.title,this.state.specSelectorId)  
}

addMaterial= (data: any, setError?: any, setShowDialog?: any) => {
  this.setErrorMaterial = setError;
  this.showDialogMaterial = setShowDialog;
  this.currentMaterial = {id:data.id??0,title:data.title}
  this.setState({selectorType:"material"})
  this.addDeleteSelectorApi(data.id ?? 0,data.title,this.state.materialSelectorId)  
}
addDeleteSelectorApi = (id:number,title:string,selectorId:number,isDelete:boolean=false) => {

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  const header = {
    token: this.userToken.meta.token,
  };
  const formdata = new FormData();

  if(isDelete){
    formdata.append(`options_attributes[${id}][_destroy]`, "true");
    formdata.append(`options_attributes[${id}][id]`, id.toString());
    this.deleteSelectorApiId = requestMessage.messageId;
  }
  else{
    formdata.append(`options_attributes[${id}][name]`, title);
    if(id>0){
      formdata.append(`options_attributes[${id}][id]`, id.toString());
    }
    this.createSelectorApiId = requestMessage.messageId;
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.manageSelectorEndPoint + selectorId
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.updateApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

createSelectorSuccess = (responseJson: any) => {

  switch(responseJson.data.attributes.name){
    case "specifications":
      // this.currentTarget = { id : responseJson.data.attributes.id}
      this.fetchSpecification();
      this.showDialogSpec(false);
      break;
    case "color":
      // this.currentTarget = { id : responseJson.data.attributes.id}
      this.fetchColour();
      this.showDialogColor(false);
      break;
    case "material":
      this.fetchMaterial();
      this.showDialogMaterial(false);
      break;
      
  }
};

deleteMaterial=(data:any)=>{
  this.setState({ isDeletingSelctor: true, deleteSelectorId: data.id, deleteDropDownId:this.state.materialSelectorId ,selectorType: "Material"});
}
deleteSpecification=(data:any)=>{
  this.setState({ isDeletingSelctor: true, deleteSelectorId: data.id, deleteDropDownId:this.state.specSelectorId ,selectorType: "Specification"});
}
deleteColor=(data:any)=>{
  this.setState({ isDeletingSelctor: true, deleteSelectorId: data.id, deleteDropDownId:this.state.colorSelectorId ,selectorType: "Color"});
}

deleteSelectorModalClose = () => {
  this.setState({
    isDeletingSelctor: false,
    deleteSelectorId:"",
    deleteDropDownId:0,
    selectorType: ""
  });
};
deleteSelectorApi=()=>{
  this.addDeleteSelectorApi(Number(this.state.deleteSelectorId),"",this.state.deleteDropDownId,true)
}

deleteSelectorSuccess = (responseJson:any) => { 
  switch(responseJson.data.attributes.name){
    case "specifications":
      if(this.state.specification && this.state.specification.id == this.state.deleteSelectorId){
        this.setState({specification:null})
      }
      this.fetchSpecification();
      break;
    case "color":
      if(this.state.colour && this.state.colour.id == this.state.deleteSelectorId){
        this.setState({colour:null})
      }
      this.fetchColour();
      break;
    case "material":
      if(this.state.material && this.state.material.id == this.state.deleteSelectorId){
        this.setState({material:null})
      }
      this.fetchMaterial();
      break; 
  }
  this.deleteSelectorModalClose();
}

removeFile = (index:number) => {
  const updatedArray = [...this.state.fileview.slice(0, index), ...this.state.fileview.slice(index + 1)];
  this.setState({ fileview: updatedArray });
}
removeAddedFile = (id:number) => {
  const updatedArray = this.state.addedFiles.filter((img:any)=>img.id!=id)
  this.setState({ addedFiles: updatedArray,removedImgFiles:[...this.state.removedImgFiles,id] });
}

getLastPartOfURL = () => {
  let url = window.location.pathname;
  let parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  if(isNaN(Number(lastPart))){
    return ""
  }
  return lastPart;
};
getFileNameFromUrl = (url:string) => {
  let parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  return lastPart;
}

getCatalogue=(itemId:string)=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.userToken.meta.token,
  };
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createCatalogueEndPoint+`/${itemId}`
  );
  this.getCatalogueApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}


setMapUrl=(mapUrl:string, latLng:any,placeName:string)=>{
  this.setState({mapUrl:mapUrl,mapPlaceName:placeName})
}

viewMap=(url:string|undefined|null)=>{
  if(!url ){
    return
  }
  const regex = /(?:@|q=)(-?\d+\.\d+),(-?\d+\.\d+)/;
  const match = url.match(regex);
  
  if (match) {
    const lat = parseFloat(match[1]);
    const lng = parseFloat(match[2]);
  
    if (!isNaN(lat) && !isNaN(lng)) {
      this.setState({mapLatLng:{lat:lat, lng:lng}})
    } 
  } 
}

getPermittedInventory=()=>{
  const header = {
     token: this.userToken.meta.token,
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.viewPermittedCategoryEndPoint
  );
  this.getPermittedInventoryApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  this.setState({isLoading : true})

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
t(key:any, variables?: Record<string, any>) {
  return (i18n as any).t(key, { ns: "translation" , ...variables } )
}
//Chemical End
  // Customizable Area End
}
