// Customizable Area Start
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    mainWrapper: {
      display: "flex",
      fontFamily: "Roboto",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "30px",
      background: "#fff",
      "& .mobile-heading": {
        display: "none",
       [theme.breakpoints.down("xs")]: {
         marginBottom: "48px",
         paddingBottom: "0",
         display: "flex",
         borderBottom: "0",
         alignItems: "center",
         justifyContent: "space-between",
         padding: "24px 24px 0",
         width: "100%",
        },
        "& .marketing-menu": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {          
          [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "18px",
            color: "#2B2B2B",
            margin: "0 20px",
            letterSpacing: "0",
            fontFamily: "Expletus Sans",
          },
        },
      },    
      "& .page-wrapper": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxHeight: "100%",

        "& .left-menu": {
          width: 367,
          height: "auto",
          padding: "28px",
          background: "var(--white, #FFF)",
          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",

          "& .box-category": {
            textTransform: "none",
            textDecoration: "none",
            "& .box-title": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px",
            },
            "& .category-container": {
              borderLeft: "1px solid #E8ECF2",
              "& .item-category": {
                height: "35px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                margin: "16px 0",

                "& .divide-line": {
                  height: 1,
                  backgroundColor: "#E8ECF2",
                  width: "17px",
                },
                "& .item-right": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  textDecoration: "none",

                  "& .btn-action": {
                    textTransform: "none",
                    textStyle: "normal",
                    fontWeight: "400",
                    fontFamily: "Expletus Sans",
                    fontSize: "14px",
                    padding: "8px 20px",
                    "&:hover": {
                      background: "#F2F8FC",
                    },
                   "& .MuiButton-label": {
                      padding: 0,
                      margin: 0,
                      textAlign: "left",
                    },
                  },
                  "& .active-link":{
                    background: "#F2F8FC",               
                    borderRadius:"6px",
                  },
                  "& .text-item-number": {
                    fontFamily: "Expletus Sans",
                    fontSize: "16px",
                    color: "#94A3B8",
                  },
                },
              },
            },
          },
        },
        "& .main-content": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",

          "& .empty-box": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height:"100vh",
            "& .img-contact-book": {
              width: "300px",
              height: "244px",
              objectFit: "contain",
            },
            "& .box-empty-right": {
              margin: "0 0 0 127px",
              "& .txt-no-contact-title": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "400",
                linHeight: "normal",
                letterSpacing: "0.25px",
              },
              "& .txt-no-contact-sub-title": {
                color: "var(--task-completed-color, #94A3B8)",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                linHeight: "20px",
                letterSpacing: "0.25px",
                margin: "12px 0",
              },
              "& .MuiButton-root": {
                borderRadius: "6px",
                background: "var(--brand-color-primary-100, #4BA3B7)",
                color: "#ffffff",
                textTransform: "none",
                fontFamily: "Expletus Sans",
                width: "136px",
                height: "48px",
                margin: "48px 0px 0px",
                whiteSpace:"nowrap",
              },
            },
          },
          "& .btn-minimize-create-contact": {
            position: "fixed",
            bottom: "34px",
            right: "230px",
            display: "flex",
            flexDirection: "row",
            padding: "14px 16px",
            alignItems: "center",
            // width: "202px",
            height: "56px",
            gap: "16px",
            borderRadius: "8px",
            background: "var(--white, #FFF)",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
            "& .expand-icon": {
              width: "16px",
              height: "16px",
              cursor:"pointer"
            },
            "& .create-txt": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              cursor:"pointer"
            },
            "& .MuiButton-root": {
              minWidth: "24px",
              "& .close-icon": {
                width: "16px",
                height: "16px",
              },
            },
          },
          "& .create-contact-wrapper": {
            "& .btn-create-contact": {
              position: "fixed",
              bottom: "34px",
              right: "32px",
              borderRadius: "6px",
              background: "var(--brand-color-primary-100, #4BA3B7)",
              boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
              padding: "14px 24px",
              color: "#ffffff",
              textTransform: "none",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
            },            
          },
          "& .create-contact-wrapper-hide":{
            display:"none"
          }
        },
      },
    },
    productsFormControl : {
      marginBottom: "4px",
      display:"block",
      [theme.breakpoints.down("sm")]:{
        marginBottom: "2px",
      },
      "& .MuiSelect-select": {
        fontSize: "12px",
        letterSpacing: "0.4px",
        lineHeight: "16px",
        color: "rgba(0, 0, 0, 0.38)",
        fontFamily: "Roboto",
        fontWeight: 400,
        paddingBottom: "0",
        paddingTop: "0",
        [theme.breakpoints.down("sm")]:{
          lineHeight: "14px",
          fontSize: "10px",
          color: "#94A3B8",
          letterSpacing: "0",
        },
        "&:focus": {
          backgroundColor: "transparent"
        },        
      },
      "& .MuiSelect-icon": {
        width: "20px",
        color: "rgba(0, 0, 0, 0.38)",
      }
    },
    productItemsSection: {
      border: "1px solid #E8ECF2",      
      borderRadius: "12px",
      boxShadow: "none",
      background: "#FFF",
      height: "100%",
      padding: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "35px 16px 16px",
      },     
      "&.inventory-checkbox":{
        "& .info-box-right":{
          display:"flex",
          marginLeft: "10px",
          alignItems:"center",
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            right: "0",
            left: "0",
            marginLeft: "0",
            top: "-19px",
            justifyContent: "space-between",
          },
          "& .in-stock-badge":{
            padding:"2px 8px",
            marginRight:"12px",
            backgroundColor:"#F2F8FC",
            textTransform:"capitalize",
            borderRadius:"6px",
            color:"rgba(43, 43, 43, 0.87)",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            fontSize:"12px",
            fontFamily: "Roboto",
            [theme.breakpoints.down("sm")]: {
              borderRadius: "31px",
              fontFamily: "Expletus Sans",
              background: "#F2F8FC",
              fontSize: "10px",
              lineHeight: "14px",
              fontWeight: "600",
              marginRight: "0",
              letterSpacing: "0",
              minWidth: "20px",
              padding: "3px",
              display: "flex",
              height: "20px",
              justifyContent: "center",
              alignItems: "center",
            },
            "& .badge-name": {
              [theme.breakpoints.down("sm")]: {
                display: "none",
              }
            }
          },
          "& .MuiCheckbox-root":{
            padding:"0",
            marginTop:"0",
            marginLeft: "auto",
          }
        },
      },
      "& .MuiCardContent-root": {
        borderRadius: "12px",
        padding: "40px",
        position: "relative",
        border: "1px solid #E8ECF2",
        [theme.breakpoints.down("md")]: {
          padding: "26px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "0",
          border: "none",
          alignItems: "center",
          display: "flex",
        },
        "&:hover": {
          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
          border: "1px solid #12AEF1",
          cursor: "pointer",
          [theme.breakpoints.down("xs")]: {
            boxShadow: "unset",
            border: "none",
          },
          "& .button-wrapper": {
            display: "block",
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
        },
        "& .MuiButton-root": {
          marginBottom: "8px",
          marginRight: 0,
          boxShadow: "none",
          borderRadius: "62px",
          width: "100%",
          marginLeft: 0,
          height: "48px",
          "&:last-child": {
            marginBottom: 0,
          },
        },
      },
      "& .itemDesc": {
        justifyContent: "space-between",
        display: "flex",
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.38)",
        lineHeight: "16px",
        fontFamily: "Roboto",
        letterSpacing: "0.4px",
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
          fontSize: "10px",
          color: "#94A3B8",
          letterSpacing: "0.125px",
          lineHeight: "17px",
        },
        "& .desc-left": {
          marginRight: "10px",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
        },
        "& .task-about": {
          paddingLeft: "14px",
          position: "relative",
          marginLeft: "8px",
          "& .dot": {
            content: '""',
            height: "6px",
            width: "6px",
            borderRadius: "100%",
            display: "inline-block",
            background: "#E8ECF2",
            position: "absolute",
            top: "5px",
            left: "0",
          },
        },
        "& .timing": {
          fontFamily: "Roboto",
          color: "#2B2B2B",
          fontSize: "10px",
          lineHeight: "17px",
          fontWeight: 400,
          letterSpacing: "0.125px",
        },
      },
      "& .ratingBox":{
        alignItems: 'center',
        display: 'flex',
        marginBottom: "5px",
        "& .ratingText":{
          color: "rgba(0, 0, 0, 0.38)",
          lineHeight: "16px",
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "Roboto",
          marginRight: "6px",
          letterSpacing: "0.4px",
          [theme.breakpoints.down("sm")]: {
            color: "#94A3B8",
            lineHeight: "17px",
            fontSize: "10px",
            letterSpacing: "0.125px",
          },
        },
        "& .ratingImages":{
          width: "6px",
          marginRight: "6px",
          height: "6px",
          [theme.breakpoints.down("sm")]: {
            height: "4px",
            width: "4px",
          }
        },
        "& .starImg":{
          width: "12px",
          [theme.breakpoints.down("sm")]: {
            width: "10px",
          }
        },
      },        
      "& .infoBox":{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: "24px",
        [theme.breakpoints.down("sm")]:{
          marginBottom: "27px",
          position: "relative",
          flexDirection: "column",
        },         
        "&:last-child": {
          marginBottom: "0",
        },
        "& .infoBoxLeft": {
          [theme.breakpoints.down("sm")]:{
            width: "100%",
            marginBottom: "11px",
          }, 
        },
        "& .infoBoxRight": {
          marginLeft: "8px",
          textAlign: "right",
          [theme.breakpoints.down("sm")]:{
            textAlign: "left",
            marginLeft: "0",
          },
        },
        "& .categoryImg":{
          height: '68px',
          objectFit:"contain",
          width: "100%",
          maxWidth: "68px",
          [theme.breakpoints.down("sm")]:{
            height: '56px',
            maxWidth: "56px",
            margin: "0 auto",
          },
        },
        "& .infoText":{
          color:'rgba(0, 0, 0, 0.38)',
          fontSize: '12px',
          fontWeight: 400,
          letterSpacing: "0.4px",
          fontFamily: "Roboto",
          lineHeight: "16px",
          [theme.breakpoints.down("sm")]:{
            lineHeight:'17px',
            fontSize: '10px',
            letterSpacing: "0.125px",
            color:'#94A3B8',
          },
        },
        "& .itemTitle":{
          fontSize: '16px',
          fontFamily: "Roboto",
          lineHeight:'24px',
          letterSpacing: "0.15px",
          color:'rgba(43, 43, 43, 0.87)',
          marginBottom: "4px",
          fontWeight: 500,
          [theme.breakpoints.down("sm")]:{
            lineHeight:'19px',
            fontSize: '14px',
            fontFamily: "Expletus Sans",
            letterSpacing: "0",
            color:'#2B2B2B',
            fontWeight: 400,
          },
        },
        "& .itemHeading": {           
          color:'rgba(0, 0, 0, 0.38)',
          fontSize: '12px',
          fontWeight: 400,
          letterSpacing: "0.4px",
          lineHeight: "16px",
          marginBottom: "4px",
          fontFamily: "Roboto",
          [theme.breakpoints.down("sm")]:{
            lineHeight:'14px',
            fontSize: '10px',
            letterSpacing: "0",
            color:'#94A3B8',
          },
        },
        "& .itemPrice":{
          lineHeight:'24px',
          fontSize: '16px',
          fontFamily: "Roboto",
          color:'rgba(43, 43, 43, 0.87)',
          letterSpacing: "0.15px",
          fontWeight: 400,
          [theme.breakpoints.down("sm")]:{
            fontSize: "14px",
            letterSpacing: "0",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            color: "#2B2B2B",
            fontWeight: 500,
          },
        },
      },
      "& .titleBox":{
        marginBottom: "5px",
      },                     
    },
    contactBooksWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "100%",
      width: "100%",
      "& .contacts-group-heading": {
        padding: "30px 24px",
        "& .back": {
          display: "flex",
          flexDirection: "row",
          textTransform: "none",
          textDecoration: "none",
          "& .back-txt": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "16px",
            fontWeight: 400,
            letterSpacing: "0.15px",
            marginLeft: "16px",
          },
        },
        "& .group-name": {
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Expletus Sans",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          marginTop: "34px",
        },
      },
      "& .filter-box": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: "1px solid #E8ECF2",
        margin: "16px 0 16px 0",
        "& .group-contact-left-box": {
          "& .MuiBox-root": {
            borderLeft: "none",
          },
        },
        "& .left-box": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          "& .search-contacts":{
            height : "24px",
            width: "24px",
            maxWidth: "none",
          },
          "& .line":{
            height: "20px",
            borderLeft: "2px solid #E8ECF2",
          },
          "& .group-line":{
            height: "20px",
            border: "1px solid #E8ECF2",
          },
          "& .total-contact": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "20px",
            height: "20px",
            letterSpacing: "0.1px",
            verticalAlign: "center",
            textAlign: "center",
            marginRight: "16px",
            whiteSpace: "nowrap",
            marginLeft: "16px",
          },
          "& .MuiBox-root": {
            // padding: "0px 16px",
            marginBottom: 0,
            // borderLeft: "2px solid #E8ECF2",
            "& .MuiFormControl-root": {
              border: "none",
              "& fieldset": { border: "none" },
              "& .MuiInputBase-root": {
                "& .MuiInputBase-input": {
                  padding: "10px 14px",
                },
              },
            },
          },
        },
        "& .center-box": {
          display: "flex",
          flexDirection: "row",
          "& .select-control": {
            margin: "0 12px",
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                fontSize: "15px",
                position: "absolute",
                top: "3px",
                right: "18px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "14px",
              color: "#2b2b2b",
              letterSpacing: "0.1px",
              opacity: "0.671",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingTop: "0",
              paddingBottom: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                height: "50px",
                width: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .right-box": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .line":{
            height: "20px",
            borderLeft: "2px solid #E8ECF2",
            margin:"0 16px",
          },
          "& .btn-right": {
            textTransform: "none",
            "& .MuiButton-label": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "normal",
            },
            "& .sort-icon": {
              height: "18px",
              width: "18px",
              marginRight: "6px",
            },
          },
          "& .MuiFormControl-root": {
            marginLeft: "10px",
            marginRight: 13,
            "& .MuiInputBase-root": {
              border: "none",
              "& .MuiSelect-root": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "normal",
              },
            },
          },
          "& .select-control": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                right: "18px",
                fontSize: "15px",
                top: "3px",
                color: "#94A3B8",
                zIndex: 1,
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              color: "#2b2b2b",
              letterSpacing: "0.1px",
              opacity: "0.671",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingTop: "0",
              paddingBottom: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                background: "red",
                height: "50px",
              },
              "& .MuiBox-root": {
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
          "& .sortby-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
            },
            "& .select-icon": {
              top: "3px",
            },
          },
          "& .filter-more": {
            "& .menu-dropdown": {
              display: "flex",
              flexDirection: "row",
              backgroudColor: "transparent",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 6px",            
              "& .more-txt": {
                color: "#737070",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                marginTop:"-3px",
                cursor:"pointer",
                whiteSpace: "nowrap",
              },
              "& .more-columns": {
                color: "#737070",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                marginRight:"3px",
                whiteSpace: "nowrap",
                lineHeight: "normal",
                cursor:"pointer",
              },
            },
            "& .MuiPopover-root": {
              backgroundColor: "green",
              "& .MuiPopover-paper": {
                "& .MuiBox-root": {
                  padding: "16px 24px 8px",
                  "& .MuiBox-root": {
                    display: "flex",
                    flexDirection: "row",
                  },
                },
              },
            },
          },
          "& .groups-paging": {
            display: "flex",
            flexDirection: "row",
            marginRight: "16px",
            "& .pg-txt": {
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "0.1px",
            },
            "& .pg-index-txt": {
              opacity: 1,
              marginRight: "6px",
            },
            "& .pg-total-txt": {
              opacity: 0.67,
            },
          },
          "& .contacts-paging": {
            display: "flex",
            flexDirection: "row",
            minWidth: "max-content",
            overflow: "hidden",
            "& .pg-txt": {
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "0.1px",
            },
            "& .pg-index-txt": {
              opacity: 1,
              marginRight: "6px",
            },
            "& .pg-total-txt": {
              opacity: 0.67,
            },
            "& .MuiTablePagination-actions":{
              marginLeft: "0",
            }
          },
        },
      },
      "& .content-wrapper": {
        padding: "0px 34px 90px",

        "& .table-header": {
          display: "flex",
          flex: 1,
          width: "100%",
          justifyContent: "flex-start",
          // borderTop: "1px solid #E8ECF2",
          "& .hd-column": {
            padding: "18px 0px",
            flex: 1,
            borderBottom: "1px solid #E8ECF2",
            "& .tb-header-title": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              opacity: 0.37,
            },
          },
        },
        "& .table-content": {
          display: "flex",
          flexDirection: "column",
          "& .error-message":{
            display:"flex",
            margin:"20% auto",
            justifyContent: "center",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            height: "20px",
            verticalAlign: "center",
            marginRight: "16px",
            textAlign: "center",
            whiteSpace: "nowrap",
            marginLeft: "16px",
          },
          "& .tb-sort-character": {
            padding: "16px 0px",
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            borderTop: "1px solid #E8ECF2",
            borderBottom: "1px solid #E8ECF2",
          },
          "& .tb-row": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            padding: "0px 0px",
            height: "72px",
            "& .tb-cell": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: 1,
              alignItems: "center",
              borderBottom: "1px solid #E8ECF2",
              paddingRight:"20px",

              "& .tb-cell-content": {
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.1px",
                wordBreak: "break-word",
              },
            },
            "& .tb-cell-1": {
              "& .avatar-wrapper": {
                height: "48px",
                width: "48px",
                borderRadius: "24px",
                backgroundColor: "#FAFCFE",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "12px",
                marginLeft: "-12px",
                "& .text-eh": {
                  color: "#2B2B2B",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                  letterSpacing: "0.1px",
                },
              },
            },
            "& .tb-cell-7": {
              "& .btn-view-action": {
                textTransform: "none",
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.1px",
              },
            },
          },
        },

        "& .groups": {
          flex: 1,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "flex_start",
          flexGrow: 1,
          margin: "30px 0px 30px -24px",
          "& .error-message":{
            display:"flex",
            justifyContent: "center",
            margin:"20% auto !important",
            fontFamily: "Expletus Sans",
            color: "#2B2B2B",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "500",
            letterSpacing: "0.1px",
            verticalAlign: "center",
            height: "20px",
            marginRight: "16px",
            whiteSpace: "nowrap",
            textAlign: "center",
            marginLeft: "16px",
          },
          "& .group-container":{
            position:"relative"
          },
          "& .more-button": {
            position: "absolute",
            right: 0,
            top: "30px",
          },
          "& .group-item-list": {
            wordBreak: "break-all",
            overflowWrap: "break-word",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            maxWidth: "354px",
            padding: "20px 24px",
            height: "96px",
            borderRadius: "12px",
            border: "1px solid var(--stroke, #E8ECF2)",
            marginLeft: "24px",
            marginBottom: "24px",
            textTransform: "none",
            textDecoration: "none",
           
            "& .gr-item-left-box": {
              display: "flex",
              flexDirection: "column",
              width: "314px",
              justifyContent: "space-between",
              alignItems: "flex-start",
              "& .gr-item-title": {
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "22px",
              },
              "& .gr-item-sub-title": {
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "17px",
              },
            },
            "& .right-arrow-icon": {
              width: "24px",
              height: "24px",
            },
            
          },
        },
      },
    },
    campaigndrawerWrapper: {
      flexWrap: "wrap",
      display: "flex",
      width: "100%",
    },
    contactdrawer: {
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      minWidth: "367px",
      top: "88px",
      position: "sticky",
      maxHeight: "calc(100vh - 88px)",
      [theme.breakpoints.down("md")]: {
        minWidth: "320px",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    contactPaper: {
      borderRight: "0",
      width: "100%",
      position: "static",
      maxHeight: "100%",
      padding: "18px 40px 18px 28px",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "18px 28px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
    },
    MergeContactsWrapper: {
      flexDirection: "column",
      display: "flex",
      height: "100%",
      justifyContent: "flex-start",
      background:"#fff",
      width: "100%",
      overflow:"auto",
      "& .heading-left": {
        padding: "50px 0 16px 16px",
        "& .modal-title": {
          color: "var(--gray, rgba(43, 43, 43, 0.87))",
          fontFamily: "Expletus Sans",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "30px",
        },
        "& .sub-txt": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
          letterSpacing: "0.105px",
        },
      },
      "& .modal-heading": {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "40px",
        alignItems: "flex-start",
        padding: "0px 10px",
        "& .heading-content":{
          display:"flex"
        },
        "& .back-txt":{
          fontFamily: 'Expletus Sans',
          color: '#2B2B2B',
          fontStyle: 'normal',
          fontSize: '16px',
          fontWeight: 400,
          letterSpacing: '0.15px',
          lineHeight: '24px',
          marginLeft: '20px',
        }     
       
      },
      "& .contacts-group-heading": {
        padding: "30px 24px",
        "& .back": {
          flexDirection: "row",
          display: "flex",
          textDecoration: "none",
          textTransform: "none",
          "& .back-txt": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontSize: "16px",
            fontFamily: "Expletus Sans",
            letterSpacing: "0.15px",
            fontWeight: 400,
            marginLeft: "16px",
          },
        },
        "& .group-name": {
          color: "rgba(0, 0, 0, 0.87)",
          fontSize: "24px",
          fontFamily: "Expletus Sans",
          fontStyle: "normal",
          lineHeight: "normal",
          fontWeight: "400",
          marginTop: "34px",
        },
      },
      "& .filter-box": {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E8ECF2",
        // width: "100%",
        margin: "16px 0 16px 24px",
        "& .group-contact-left-box": {
          "& .MuiBox-root": {
            borderLeft: "none",
          },
        },
        "& .left-box": {
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "flex-start",
          "& .MuiOutlinedInput-adornedStart":{
           paddingLeft:"1px",
          },
          "& .line":{
            borderLeft: "2px solid #E8ECF2",
            height: "20px",
          },
          "& .group-line":{
            border: "1px solid #E8ECF2",
            height: "20px",
          },
          "& .MuiBox-root": {
            // padding: "0px 16px",
            marginBottom: 0,
            // borderLeft: "2px solid #E8ECF2",
            "& .MuiFormControl-root": {
              border: "none",
              "& fieldset": { border: "none" },
              "& .MuiInputBase-root": {
                "& .MuiInputBase-input": {
                  padding: "10px 14px",
                },
              },
            },
          },
        },
        "& .sort-box": {
          display: "flex",
          flexDirection: "row",
          "& .select-control": {
            margin: "0 12px",
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                top: "3px",
                zIndex: 1,
                right: "18px",
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              fontSize: "14px",
              letterSpacing: "0.1px",
              opacity: "0.671",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              paddingRight: "2px",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .right-box": {
          flexDirection: "row",
          display: "flex",
          "& .btn-right": {
            textTransform: "none",
            "& .MuiButton-label": {
              fontFamily: "Expletus Sans",
              color: "var(--task-completed-color, #2B2B2B)",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "normal",
            },
            "& .sort-icon": {
              height: "18px",
              marginRight: "6px",
              width: "18px",
            },
          },
          "& .MuiFormControl-root": {
            marginLeft: "10px",
            marginTop: "-2px",
            marginRight: 16,
            "& .MuiInputBase-root": {
              border: "none",
              "& .MuiSelect-root": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "normal",
                fontStyle: "normal",
              },
            },
          },
          "& .select-control": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                color: "#94A3B8",
                top: "3px",
                zIndex: 1,
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              opacity: "0.671",
              fontWeight: "500",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
          "& .sortby-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
            },
            "& .select-icon": {
              top: "3px",
            },
          },
          "& .filter-more": {
            "& .menu-dropdown": {
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              backgroudColor: "transparent",
              alignItems: "center",
              "& .more-txt": {
                color: "#737070",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: "500",
                marginTop:"-3px",
                whiteSpace: "nowrap",
                cursor:"pointer",
              },
              "& .more-columns": {
                color: "#737070",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: "500",
                marginRight:"3px",
                whiteSpace: "nowrap",
                cursor:"pointer",
              },
            },
            "& .MuiPopover-root": {
              backgroundColor: "green",
              "& .MuiPopover-paper": {
                "& .MuiBox-root": {
                  padding: "16px 24px 8px",
                  "& .MuiBox-root": {
                    flexDirection: "row",
                    display: "flex",
                  },
                },
              },
            },
          },
          "& .groups-paging": {
            display: "flex",
            marginRight: "16px",
            flexDirection: "row",
            "& .pg-txt": {
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "500",
              letterSpacing: "0.1px",
            },
            "& .pg-index-txt": {
              marginRight: "6px",
              opacity: 1,
            },
            "& .pg-total-txt": {
              opacity: 0.67,
            },
          },
        },
      },
      "& .pagination-txt":{
        justifyContent: "space-between",
        display:"flex",
        "& .groups-paging": {
          display: "flex",
          marginRight: "16px",
          flexDirection: "row",
          "& .pg-txt": {
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "500",
            letterSpacing: "0.1px",
          },
          "& .pg-index-txt": {
            marginRight: "6px",
            opacity: 1,
          },
          "& .pg-total-txt": {
            opacity: 0.67,
          },
        },
        "& .total-contact": {
          color: " #2B2B2B",
          display:"flex",
          fontFamily: "Expletus Sans",
          fontStyle: "normal",
          fontSize: "14px",
          fontWeight: "500",
          height: "20px",
          lineHeight: "20px",
          letterSpacing: "0.1px",
          textAlign: "center",
          verticalAlign: "center",
          whiteSpace: "nowrap",
          marginRight: "16px",
          marginLeft: "34px",
        },
      },
      "& .content-wrapper": {
        padding: "0px 34px 90px",
        "& .table-header": {
          flex: 1,
          display: "flex",
          width: "100%",
          justifyContent: "flex-start",
          // borderTop: "1px solid #E8ECF2",          
          "& .hd-column": {
            flex: 1,
            padding: "18px 0px",
            borderBottom: "1px solid #E8ECF2",
            "& .tb-header-title": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              fontStyle: "normal",
              lineHeight: "20px",
              opacity: 0.37,
              letterSpacing: "0.1px",
            },
          },
          "& .hd-column-0": {
            flex: 0.3,      
          },
        },
        "& .table-content": {
          flexDirection: "column",
          display: "flex",
          "& .error-message":{
            display:"flex",
            justifyContent: "center",
            color: "#2B2B2B",
            margin:"20% auto",
            fontStyle: "normal",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            height: "20px",
            letterSpacing: "0.1px",
            marginRight: "16px",
            verticalAlign: "center",
            textAlign: "center",
            marginLeft: "16px",
            whiteSpace: "nowrap",
          },
          "& .tb-sort-character": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            padding: "16px 10px",
            fontSize: "12px",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            fontStyle: "normal",
            borderTop: "1px solid #E8ECF2",
            borderBottom: "1px solid #E8ECF2",
            lineHeight: "normal",
          },
          "& .tb-row": {
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            height: "72px",
            padding: "0px 0px",
            "& .tb-cell": {
              flexDirection: "row",
              display: "flex",
              flex: 1,
              justifyContent: "flex-start",
              borderBottom: "1px solid #E8ECF2",
              alignItems: "center",
              paddingRight:"20px",

              "& .tb-cell-content": {
                fontFamily: "Expletus Sans",
                color: "#2B2B2B",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: "0.1px",
                lineHeight: "normal",
                wordBreak: "break-word",
              },
            },
            "& .tb-cell-0": {
              flex:"0.2",
              display: "flex",
            },
            "& .tb-cell-1": {
              "& .avatar-wrapper": {
                height: "48px",
                borderRadius: "24px",
                width: "48px",
                display: "flex",
                backgroundColor: "#FAFCFE",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "-12px",
                marginRight: "12px",
                "& .checkbox-selected":{
                 "& .MuiRadio-root": {
                  color: "#E8ECF2",
                 },
                },
                "& .text-eh": {
                  color: "#2B2B2B",
                  fontSize: "14px",
                  fontFamily: "Expletus Sans",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  fontWeight: "700",
                  letterSpacing: "0.1px",
                },
              },
            },
            "& .tb-cell-7": {
              "& .btn-view-action": {
                color: "#2B2B2B",
                textTransform: "none",
                fontStyle: "normal",
                fontFamily: "Expletus Sans",
                lineHeight: "normal",
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: "0.1px",
              },
            },
          },
        },

        "& .groups": {
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexGrow: 1,
          flexDirection: "flex_start",
          margin: "30px 0px 30px -24px",
          "& .error-message":{
            display:"flex",
            margin:"20% auto !important",
            justifyContent: "center",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            lineHeight: "20px",
            fontSize: "14px",
            fontWeight: "500",
            verticalAlign: "center",
            letterSpacing: "0.1px",
            height: "20px",
            whiteSpace: "nowrap",
            marginRight: "16px",
            textAlign: "center",
            marginLeft: "16px",
          },
          "& .group-container":{
            position:"relative"
          },
          "& .more-button": {
            position: "absolute",
            top: "30px",
            right: 0,
          },
          "& .group-item-list": {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            maxWidth: "354px",
            flex: 1,
            padding: "20px 24px",
            borderRadius: "12px",
            height: "96px",
            marginLeft: "24px",
            border: "1px solid var(--stroke, #E8ECF2)",
            textTransform: "none",
            marginBottom: "24px",
            textDecoration: "none",
           
            "& .gr-item-left-box": {
              display: "flex",
              width: "314px",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              "& .gr-item-title": {
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "22px",
              },
              "& .gr-item-sub-title": {
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontSize: "12px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                lineHeight: "17px",
                fontWeight: "400",
              },
            },
            "& .right-arrow-icon": {
              width: "24px",
              height: "24px",
            },
            
          },
        },
      },
    },
    groupViewWrapper: {
      flexDirection: "column",
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
      "& .contacts-group-heading": {
        padding: "30px 24px",
        "& .back": {
          textTransform: "none",
          display: "flex",
          flexDirection: "row",
          textDecoration: "none",
          "& .back-txt": {
            fontFamily: "Expletus Sans",
            color: "var(--task-completed-color, #2B2B2B)",
            fontWeight: 400,
            fontSize: "16px",
            marginLeft: "16px",
            letterSpacing: "0.15px",
          },
        },
        "& .group-name": {
          fontFamily: "Expletus Sans",
          color: "rgba(0, 0, 0, 0.87)",
          fontStyle: "normal",
          fontSize: "24px",
          lineHeight: "normal",
          fontWeight: "400",
          marginTop: "34px",
        },
      },
      "& .filter-box": {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        borderTop: "1px solid #E8ECF2",
        // padding: "16px 32px",
        margin: "16px 0 16px 0",
        paddingTop: "12px",
        "& .group-contact-left-box": {
          "& .MuiBox-root": {
            borderLeft: "none",
          },
        },
        "& .left-box": {
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          "& .line":{
            borderLeft: "2px solid #E8ECF2",
            height: "20px",
          },
          "& .group-line":{
            border: "1px solid #E8ECF2",
            height: "20px",
          },
          "& .total-contact": {
            fontFamily: "Expletus Sans",
            color: "var(--task-completed-color, #2B2B2B)",
            fontSize: "14px",
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            height: "20px",
            textAlign: "center",
            verticalAlign: "center",
            marginRight: "16px",
            marginLeft: "16px",
            whiteSpace: "nowrap",
          },
          "& .MuiBox-root": {
            padding: "0px 16px",
            borderLeft: "2px solid #E8ECF2",
            marginBottom: 0,
            "& .MuiFormControl-root": {
              border: "none",
              "& .MuiInputBase-root": {
                "& .MuiInputBase-input": {
                  padding: "10px 14px",
                },
              },
              "& fieldset": { border: "none" },
            },
          },
        },
        "& .center-box": {
          flexDirection: "row",
          display: "flex",
          "& .select-control": {
            margin: "0 12px",
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                fontSize: "15px",
                top: "3px",
                position: "absolute",
                zIndex: 1,
                right: "18px",
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              fontSize: "14px",
              letterSpacing: "0.1px",
              fontFamily: "Expletus Sans",
              opacity: "0.671",
              paddingTop: "0",
              fontWeight: "500",
              paddingBottom: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                height: "50px",
                background: "red",
                width: "50px",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
          "& .filter-more": {
            "& .menu-dropdown": {
              display: "flex",
              flexDirection: "row",
              backgroudColor: "transparent",
              justifyContent: "center",
              alignItems: "center",
              "& .more-txt": {
                fontSize: "14px",
                color: "#737070",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                whiteSpace: "nowrap",
                marginTop:"-3px",
                cursor:"pointer",
              },
              "& .more-columns": {
                color: "#737070",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: "normal",
                marginRight:"3px",
                whiteSpace: "nowrap",
                lineHeight: "normal",
                cursor:"pointer",
              },
            },
            "& .MuiPopover-root": {
              backgroundColor: "green",
              "& .MuiPopover-paper": {
                "& .MuiBox-root": {
                  padding: "16px 24px 8px",
                  "& .MuiBox-root": {
                    display: "flex",
                    flexDirection: "row",
                  },
                },
              },
            },
          },
        },
        "& .right-box": {
          flexDirection: "row",
          display: "flex",
          "& .btn-right": {
            textTransform: "none",
            "& .MuiButton-label": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              lineHeight: "normal",
              fontWeight: "500",
            },
            "& .sort-icon": {
              height: "18px",
              marginRight: "6px",
              width: "18px",
            },
          },
          "& .MuiFormControl-root": {
            marginRight: 16,
            marginLeft: "10px",
            "& .MuiInputBase-root": {
              border: "none",
              "& .MuiSelect-root": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "normal",
                fontStyle: "normal",
              },
            },
          },
          "& .select-control": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                right: "18px",
                position: "absolute",
                fontSize: "15px",
                color: "#94A3B8",
                top: "3px",
                zIndex: 1,
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              opacity: "0.671",
              paddingTop: "0",
              fontWeight: "500",
              paddingBottom: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
          "& .sortby-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
            },
            "& .select-icon": {
              top: "3px",
            },
          },
          "& .filter-more": {
            "& .menu-dropdown": {
              display: "flex",
              backgroudColor: "transparent",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              "& .more-txt": {
                color: "#737070",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                marginTop:"-3px",
                lineHeight: "normal",
                whiteSpace: "nowrap",
                cursor:"pointer",
              },
              "& .more-columns": {
                fontFamily: "Expletus Sans",
                color: "#737070",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: "normal",
                marginRight:"3px",
                lineHeight: "normal",
                whiteSpace: "nowrap",
                cursor:"pointer",
              },
            },
            "& .MuiPopover-root": {
              backgroundColor: "green",
              "& .MuiPopover-paper": {
                "& .MuiBox-root": {
                  padding: "16px 24px 8px",
                  "& .MuiBox-root": {
                    flexDirection: "row",
                    display: "flex",
                  },
                },
              },
            },
          },
          "& .groups-paging": {
            flexDirection: "row",
            display: "flex",
            marginRight: "16px",
            "& .pg-txt": {
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "500",
              letterSpacing: "0.1px",
            },
            "& .pg-index-txt": {
              marginRight: "6px",
              opacity: 1,
            },
            "& .pg-total-txt": {
              opacity: 0.67,
            },
          },
        },
      },
      "& .content-wrapper": {
        padding: "0px 34px 90px",

        "& .table-header": {
          display: "flex",
          width: "100%",
          flex: 1,
          borderTop: "1px solid #E8ECF2",
          justifyContent: "flex-start",
          "& .hd-column": {
            flex: 1,
            padding: "18px 0px",
            borderBottom: "1px solid #E8ECF2",
            "& .tb-header-title": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              fontStyle: "normal",
              lineHeight: "20px",
              opacity: 0.37,
              letterSpacing: "0.1px",
            },
          },
        },
        "& .table-content": {
          flexDirection: "column",
          display: "flex",
          "& .error-message":{
            display:"flex",
            justifyContent: "center",
            margin:"20% auto",
            color: "#2B2B2B",
            fontStyle: "normal",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            height: "20px",
            letterSpacing: "0.1px",
            verticalAlign: "center",
            textAlign: "center",
            marginRight: "16px",
            marginLeft: "16px",
            whiteSpace: "nowrap",
          },
          "& .tb-sort-character": {
            padding: "16px 0px",
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontSize: "12px",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: "500",
            borderBottom: "1px solid #E8ECF2",
            borderTop: "1px solid #E8ECF2",
          },
          "& .tb-row": {
            flexDirection: "row",
            display: "flex",
            padding: "0px 0px",
            justifyContent: "flex-start",
            height: "72px",
            "& .tb-cell": {
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              paddingRight:"20px",
              borderBottom: "1px solid #E8ECF2",

              "& .tb-cell-content": {
                color: "#2B2B2B",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: "500",
                letterSpacing: "0.1px",
                wordBreak: "break-word",
              },
            },
            "& .tb-cell-1": {
              "& .avatar-wrapper": {
                height: "48px",
                borderRadius: "24px",
                width: "48px",
                display: "flex",
                backgroundColor: "#FAFCFE",
                justifyContent: "center",
                flexDirection: "row",
                marginRight: "12px",
                alignItems: "center",
                marginLeft: "-10px",
                "& .text-eh": {
                  fontFamily: "Expletus Sans",
                  color: "#2B2B2B",
                  fontSize: "14px",
                  fontWeight: "700",
                  fontStyle: "normal",
                  letterSpacing: "0.1px",
                  lineHeight: "normal",
                },
              },
            },
            "& .tb-cell-7": {
              "& .btn-view-action": {
                color: "#2B2B2B",
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: "0.1px",
                lineHeight: "normal",
              },
            },
          },
        },

        "& .groups": {
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexGrow: 1,
          flexDirection: "flex_start",
          margin: "30px 0px 30px -24px",
          "& .error-message":{
            justifyContent: "center",
            display:"flex",
            fontFamily: "Expletus Sans",
            margin:"20% auto !important",
            color: "#2B2B2B",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            height: "20px",
            verticalAlign: "center",
            marginRight: "16px",
            textAlign: "center",
            whiteSpace: "nowrap",
            marginLeft: "16px",
          },
          "& .group-container":{
            position:"relative"
          },
          "& .more-button": {
            position: "absolute",
            top: "30px",
            right: 0,
          },
          "& .group-item-list": {            
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            maxWidth: "354px",
            flex: 1,
            padding: "20px 24px",
            borderRadius: "12px",
            height: "96px",
            marginLeft: "24px",
            border: "1px solid var(--stroke, #E8ECF2)",
            marginBottom: "24px",
            textDecoration: "none",
            textTransform: "none",
           
            "& .gr-item-left-box": {
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
              width: "314px",
              alignItems: "flex-start",
              "& .gr-item-title": {
                fontFamily: "Roboto",
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "22px",
                fontWeight: "400",
              },
              "& .gr-item-sub-title": {
                fontFamily: "Roboto",
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "17px",
                fontStyle: "normal",
              },
            },
            "& .right-arrow-icon": {
              height: "24px",
              width: "24px",
            },
            
          },
        },
      },
    },
    addOptionWrapper: {
      filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
      borderRadius: "8px",
      padding: "19px 22px 22px",
      backgroundColor: "#fafafa",
      margin: "5px 0",
      "& .MuiOutlinedInput-input": {
        padding: "10px 0",
        letterSpacing: "0px",
        fontSize: "14px",
        color: "#000000",
        lineHeight: "20px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
      },
      "& .MuiFormLabel-root": {
        transform: "none",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "& .form-control": {
        marginBottom: "0",
      },
      "& .button-wrapper": {
        borderTop: "1px solid #e8ecf2",
        paddingTop: "16px",
        marginTop: "9px",
        justifyContent: "flex-end",
        display: "flex",
        "& .add-link": {
          fontSize: "14px",
          opacity: "0.671",
          letterSpacing: "0",
          lineHeight: "18px",
          fontWeight: 500,
          color: "#4ba3b7",
          textTransform: "capitalize",
          fontFamily: "Expletus Sans",
          "&.secondary-link": {
            color: "#2b2b2b",
            textTransform: "capitalize",
          },
          "&:hover": {
            textDecoration: "underline",
          },
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .add-item-title input::placeholder":{
        color:"#2B2B2B"
      }
    },

    createContactBox: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "0",
      overflow: "auto",
      maxWidth: "591px",
      width: "100%",
      marginLeft: "auto",
      maxHeight: "unset",

      display: "inline-block",
      top: "auto !important",
      bottom: "95px",
      right: "32px",
      position: "fixed",
      [theme.breakpoints.down("sm")]: {
        bottom: "65px",
      },
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        bottom: "0",
        left: "0 !important",
        right: "0",
        maxWidth: "100%",
        borderRadius: "0",
        boxShadow: "none",
      },
      "& .contact-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        marginBottom: "0",
        padding: "40px 40px 14px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 17px",
        },
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
        },
        "& .sub-txt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.09px",
          marginTop: "4px",
        },
        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          "& .heading-icon": {
            height: "16px",
            width: "16px",
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
              height: "24px",
              width: "24px",
            },
          },
        },
      },
      "& .favorite-product": {
        textTransform: "none",
        textDecoration: "none",
      },
      "& .contact-content":{
        height: "calc(100vh - 300px)",
        padding: "10px 40px",
        overflowY:" auto", 
        [theme.breakpoints.down("sm")]: {
          height: "calc(100vh - 355px)",
        },
        [theme.breakpoints.down("xs")]: {
          height: "calc(100vh - 174px)",
          padding: "10px 24px",
        },   
      },
      "& .contact-content-group":{
        // height: "calc(100vh - 392px)",
        padding: "10px 40px",
        overflowY:" auto", 
        [theme.breakpoints.down("sm")]: {
          // height: "calc(100vh - 392px)",
        },
        [theme.breakpoints.down("xs")]: {
          height: "calc(100vh - 174px)",
          padding: "10px 24px",
        },   
      },
     
      "& .info-group": {
        padding: "16px 0px",
        overflowY: "auto",
        height: "auto",
        width: "100%",
        "& .group-title": {
          color: "#000",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
          marginBottom: "16px",
        },
        "& .select-outer": {
          width: "100%",
          // marginTop:'-1.3vw',
          marginBottom: "16px",
           "& svg":{
            fill:'#94A3B8',  
          },
          "& .error-select":{
            color:'#C7263E',  
          },
          "& .Mui-error":{
            "& .MuiOutlinedInput-notchedOutline":{
              borderColor:'#C7263E',  
            }
          },       
          "& .MuiInputLabel-formControl": {
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "19px",
            color: "#94A3B8",
            letterSpacing: "0.1px",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
              fontSize: "12px",
              color : "rgba(43,43,43,0.87)",
              lineHeight: "16px",
              fontFamily: "Roboto",
              letterSpacing: "0.0075em",
              fontWeight: "400",
            }
          },
          "& .MuiSelect-select": {
            letterSpacing: "0",
            fontSize: "14px",
            opacity: "0.671",
            color: "#000000",
            fontWeight: "500",
            display:'flex',
            fontFamily: "Expletus Sans",
            justifyContent:'space-between',
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
              fontSize: "12px",
              color : "rgba(43,43,43,0.87)",
              letterSpacing: "0.0075em",
              lineHeight: "16px",
              fontWeight: "400",
              fontFamily: "Roboto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              lineHeight: "23px",
              fontSize: "18px",
              marginLeft: "auto",
            }
          }
        },
        "& .billing-address-subtitle": {
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& .billing-address-sub-title": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
            marginBottom: "16px",
          },
          "& .shipping-address-chkbox": {
            color: "#4BA3B7",
          },
        },
        "& .shipping-address-txt": {
          // marginBottom: "16px",
          marginTop:"-13px",
        },
        "& .group-sub-title": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
          letterSpacing: "0.105px",
          marginTop: "-20px",
          marginBottom: "16px",
          display:"flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .MuiIconButton-label " :{
            color: "#E8ECF2",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
        "& .group-caption": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
      },
      "& .form-info-wrapper": {
        marginBottom: "32px",
        "&:last-child": {
          marginBottom: "0",
        },
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        "& .form-col": {
          flex: "0 0 100%",
          maxWidth: "100%",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          "& .form-control": {
            marginBottom: "16px",
          },
          "& .right-8": {
            marginRight: "8px",
          },
          "& .left-8": {
            marginLeft: "8px",
          },
          "& .MuiFormLabel-root": {
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              marginLeft: "auto",
              fontSize: "18px",
            },
          },
        },
        "& .choose-contact": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "6px",
          width: "100%",
          height: "56px",
          border: "1px solid var(--stroke, #E8ECF2)",
          textTransform: "none",
          textDecoration: "none",
          padding: "0px 12px",
          justifyContent: "space-between",
          "& .count-selected": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          },
        },
      },
      "& .createtask-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "40px 40px 40px",
        "& .createtask-left": {
          display: "flex",
          alignItems: "center",
        },
        "& .outerbutton-wrapper": {
          marginRight: "8px",
          position: "relative",
          "& .outer-link": {
            minWidth: "48px",
            height: "48px",
            borderRadius: "48px",
            border: "1px dashed #E8ECF2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            padding: "10px",
            "& .priority": {
              color: "#94A3B8",
              "&.active-priority": {
                color: "#2B2B2B",
              },
            },
            "& .time": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              lineHeight: "19px",
              marginLeft: "5px",
            },
          },
        },
        "& .timestand-dropbox": {
          zIndex: "1300",
        },
        "& .primary-btn": {
          minWidth: "165px",
        },
      },

      "& .favorite-box": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "8px",
        padding: "24px",
        border: "1px solid #E8ECF2",
        "& .category-left-box": {
          "& .left-title": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginBottom: "10px",
          },
          "& .left-sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
          },
        },
        "& .category-right-box": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // marginTop: "24px",
          "& .total-amount-txt": {
            color: "var(--text-secondary, #94A3B8)",
            texAlign: "right",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .amount-txt": {
            color: "var(--text-secondary, #2B2B2B)",
            texAlign: "right",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "6px",
          },
          "& .arrow-btn": {
            // minWidth: "24px",
            "& .btn-icon": {
              height: "16px",
              width: "16px",
              color:"#94A3B8"
            },
          },
        },
      },

      "& .wrap-option": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        marginBottom: "24px",

        "& .btn-add-option": {
          textTransform: "none",
          display: "flex",
          flexDirection: "row",
          padding: " 6px 12px",
          alignItems: "flex-start",
          gap: "8px",
          borderRadius: "15px",
          border: "1px solid var(--stroke, #E8ECF2)",
          color: "var(--gray, rgba(43, 43, 43, 0.87))",

          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",

          marginRight: "12px",
          marginBottom: "12px",
        },
      },
      "& .create-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 40px 40px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 55px",
        },
        "& .full-btn": {
          width: "100%",
          "& .MuiButton-root": {
            width: "100%",
          },
        },
        "& .create-contact-btn": {
          minWidth: "165px",
        },
      },
      "& .create-contact-btn": {
        display: "flex",
        height: "56px",
        padding: "8px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        flex: "1 0 0",
        borderRadius: "6px",
        background: "var(--brand-color-primary-100, #4BA3B7)",
        width: "100%",
        textTransform: "none",
        color: "var(--white, #FFF)",

        /* CTA/CTA_SMALL */
        fontFamily: "Expletus Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",       
      },
    },
    popoverStyle: {
      display: "inline-flex",
      height: "auto",
      width: "310px",
      top:"140px",
      flexDirection: "column",
      alignItems: "center",
      flexShrink: 0,
      padding: "10px",
      borderRadius: "12px",
      background: "var(--white, #FFF)",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      marginTop:"24px",
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        lineHeight: "20px",
        fontSize: "14px",
        color: "#000000",
        letterSpacing: "0px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          justifyContent: "space-between",
          width: "100%",
          color: "#2B2B2B",
          opacity: "1",
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#d3d9e1",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          padding: "5px",
          height: "20px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        boxShadow: "none",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
      "& .MuiSwitch-colorSecondary.Mui-checked":{
        color:'#FFF' 
      },
      "& .view-more-dropdown":{
        width: "310px",
        "& .dropdown-menu-item":{
          display: 'flex',
          padding: '12px 24px',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
        },
        "& .MuiFormControl-root":{
          width:"280px",
          marginLeft:"20px"
        },
      
        "& .dropdown-menu-item-column":{
          display: 'flex',
          padding: '8px 16px',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
        },
        "& .MuiSvgIcon-root":{
          color:"#E8ECF2"
        },
        // "& .dropdown-menu-item-checked":{
        //   color:"#4BA3B7",
        // }, 
        "& .MuiTouchRipple-root": {
          display: "none",
        },      
        "& .dropdown-menu-item-text":{
          color: '#2B2B2B',
          fontFamily: 'Expletus Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
        }
      },
    },
    productListPopoverStyle: {
      display: "inline-flex",
      height: "auto",
      width: "500px",
      marginLeft:"-160px",
      flexDirection: "column",
      flexShrink: 0,
      padding: "20px",
      borderRadius: "24px",
      background: "var(--white, #FFF)",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      marginTop:"68px",
      maxHeight: "calc(100% - 90px)",
      "& .view-selected-item-dropdown-list":{
        "& .headding":{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
          "& .headding-txt":{
            color: 'var(--Task-Completed-Color, #2B2B2B)',
            fontFamily: 'Expletus Sans',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '26px',
          }
        },
        "& .sub-headding-txt":{
          color: 'var(--Task-Completed-Color, #2B2B2B)',
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '17px',
          marginTop:"6px",
        },
        "& .product-container":{
          padding:"24px 16px",
          borderBottom: "1px solid #E8ECF2",
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
         "& .product-img":{
          width:"56px",
          height:"56px",
         },
         "& .delete-content":{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
          "& .delete-img":{
            width:"24px",
            height:"24px",
            position:"absolute",
            right:"32px",
          },
         },
         "& .product-content":{
          "& .product-name": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px" /* 150% */,
            letterSpacing: "0.15px,",
          },
          "& .product-item": {
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "0.4px",
            margin:"6px 0",
          },
         },
         "& .product-add-remove":{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
          marginTop:"-32px",
          "& .secondary-btn": {
            width:"32px",
            height:"32px"
          },
          "& .product-count":{
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px" /* 150% */,
            letterSpacing: "0.15px,",
            margin:"0 8px",
          }
         },
         "& .wrap-price": {
          display: "flex",
          // flexDirection: "column",
          alignItems: "flex-end",
          "& .price-btn": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            textTransform: "none",
            "& .select-icon":{
              width:"20px"
            },
            "& .retail-price-txt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.4px",
              whiteSpace:"nowrap",
              display: "flex",            
              alignItems: "center",
            },
            "& .icon-down": {
              width: "24px",
              height: "24px",
              color: "#94A3B8",
            },
          },
          "& .price-txt": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px" /* 150% */,
            letterSpacing: "0.15px,",
          },
        },
        }
      }
    },
    selectFormControl : {
      marginRight: "0",
      "&:last-child": {
        marginRight: "0",
      },
      "& .MuiSelect-select": {
        letterSpacing: "0.1px",
        fontSize: "14px",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Expletus Sans !important",
        opacity: "0.671",
        fontWeight: "500",
        paddingTop: "0",
        paddingBottom: "0",
        "&:focus": {
          backgroundColor: "transparent"
        },        
      },
      "& .MuiSelect-icon": {
        opacity: "0.671",
        color: "rgba(43, 43, 43, 0.87)",
      }
    },
    dropdownStyle: {
      maxWidth: "190px",
      width: "100%",
      // borderRadius: "12px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "& .MuiPopover-paper ": {
        borderRadius:"12px",
        boxShadow: "0px 5px 12px 0px rgb(191 186 186 / 22%)",
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingRight: "24px",
            paddingLeft: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                letterSpacing: "0",
                lineHeight: "17px",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          padding: "14px 24px",
          color: "#2B2B2B",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": { 
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
          },
          "& .multiselect-check": {
            marginLeft: "5px",
            display: "none",
            maxWidth: "16px",
            flex: "0 0 16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .new-group":{
          color: '#4BA3B7',
          fontFamily: 'Expletus Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          alignItems: 'center',
          textAlign: 'center',
          marginLeft: '12px',
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "20px",
        color: "#000000",
        padding: "14px 17px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          letterSpacing: "0",
          font: "inherit",
        },
      },
      "& .MuiList-padding": {
        paddingBottom: "8px",
        paddingTop: "4px",
      },
    },

    productListWrapper: {
      display: "flex",
      flexDirection: "column",
      background:"#fff",
      overflow: 'auto',
      width:"100%",
      height:"100vh",

      "& .product-top-header": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: "84px",
        padding: "12px 24px",
        borderBottom: "1px solid #E8ECF2",
        "& .back-icon": {
          cursor:"pointer"
        },

        "& .top-left": {},
        "& .top-right": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",

          "& .calendar-icon": {
            height: "24x",
            width: "24px",
            marginRight: "12px",
          },
          "& .selected-txt": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            whiteSpace: "nowrap",
          },
          "& .add-to-contact-btn": {
            textTransform: "none",
            borderRadius: "6px",
            background: "var(--brand-color-disable, #92C7D3)",
            width: "147px",
            height: "48px",
            padding: "8px 14px",
            color: "#ffffff",
            marginLeft: "32px",
          },
          "& .add-to-contact-btn-active": {
            textTransform: "none",
            borderRadius: "6px",
            background: "var(--brand-color-disable, #4BA3B7)",
            width: "147px",
            height: "48px",
            padding: "8px 14px",
            color: "#ffffff",
            marginLeft: "32px",
          },
        },
      },
      "& .main-wrapper": {
        padding:"0 90px",
        [theme.breakpoints.down("xs")]: {
          padding:"0 30px",
        },
        "& .top-main-wrapper": {
          display: "flex",
          flexDirection: "column",
          "& .title-section": {
            display: "flex",
            flexDirection: "column",
            padding: "67px 0px 0",
            "& .choose-item-txt": {
              color: "var(--brand-color-brand-color-dark, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "26px",
            },
            "& .choose-item-sub-txt": {
              color: "#505050",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px",
              letterSpacing: "0.25px",
              marginTop: "16px",
            },
          },
          "& .filter-section": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "16px",
            borderBottom: "1px solid #E8ECF2",
            "& .left-box": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              // paddingLeft: "184px",
              paddingRight: "20px",
              // width: "580px",
              // borderRight: "1px solid #E8ECF2",

              "& .filter-txt": {
                color: "#505050",
                fontFamily: "Expletus Sans",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.25px",
              },
              "& .clear-all-btn": {
                color: "var(--brand-color-primary-100, #4BA3B7)",
                textAlign: "right",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                textTransform: "none",
                whiteSpace: "nowrap",
              },
            },
            "& .right-box": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              // width: "calc(100vw - 580px)",
              paddingRight: "12px",

              "& .check-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                "& .chk-select-all": {
                  color: "#94A3B8",
                  width: "24px",
                  height: "24px",
                  marginRight: "6px",
                },
                "& .selected-txt": {
                  color: "rgba(0, 0, 0, 0.60)",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                  whiteSpace: "nowrap",
                },
              },
              "& .search-sort-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "& .form-control": {
                  marginBottom: 0,
                  "& .MuiTextField-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    height: "48px",
                    marginRight: "68px",
                  },
                },
                "& .top-right": {
                  "& .select-control": {                    
                    "& .MuiInput-underline:before": {
                      display: "none",
                    },
                    "& .MuiInput-underline:after": {
                      display: "none",
                    },
                    "& .MuiInputBase-root": {
                      color:"rgb(109 106 106 / 87%)",
                      fontFamily: "Expletus Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                },
              },
              "& .select-control": {
                "&.multi-select": {
                  "& .MuiInput-formControl": {
                    marginTop: "0",
                  },
                  "& .multiselect-label": {
                    display: "none",
                  },
                  "& .MuiSelect-select": {
                    paddingRight: "40px",
                  },
                  "& .multiple-clear": {
                    position: "absolute",
                    right: "18px",
                    fontSize: "15px",
                    zIndex: 1,
                    top: "3px",
                    color: "#94A3B8",
                  },
                },
                "& .MuiSelect-select": {
                  letterSpacing: "0.1px",
                  fontSize: "14px",
                  color: "#2b2b2b",
                  opacity: "0.671",
                  fontFamily: "Expletus Sans",
                  paddingBottom: "0",
                  fontWeight: "500",
                  paddingTop: "0",
                  paddingRight: "2px",
                  "&:focus": {
                    backgroundColor: "transparent",
                  },
                  "& .clear-icon": {
                    height: "50px",
                    background: "red",
                    width: "50px",
                  },
                  "& .MuiBox-root": {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    "& .select-icon": {
                      marginRight: "6px",
                    },
                  },
                },
                "& .MuiInput-underline:after": {
                  display: "none",
                },
                "& .MuiInput-underline:before": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .main-content-wrapper": {
          display: "flex",
          flexDirection: "row",
          "& .left-wrapper": {
            display: "flex",
            flexDirection: "column",
            // width: "580px",
            // height: "50vh",           

            "& .section-header": {
              display: "block",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1px solid #E8ECF2",
              marginBottom:"30px",
                "& .select-control": {
                  "& .MuiSelect-select": {
                    paddingRight: "0",
                    "& .down-arrow": {
                      color: "rgba(43, 43, 43, 0.67)",
                    },
                  },
                },
                "& .MuiAccordion-root": {
                  borderRadius: "0",
                  boxShadow: "none",
                  // margin: "0 0 10px",
                  "&:before": {
                    display: "none",
                  },
                  "& .MuiAccordionSummary-root": {
                    marginBottom: "28px",
                    padding: "0",
                    minHeight: "unset",
                    [theme.breakpoints.down("sm")]: {
                      // marginBottom: "16px",
                    },
                    "& .MuiAccordionSummary-content": {
                      margin: 0,
                    },
                    "& .MuiIconButton-root": {
                      marginRight: "0",
                      padding: "0",
                      "& .MuiSvgIcon-root": {
                        width: "20px",
                        height: "20px",
                        fontSize: "20px",
                        color: "#94A3B8",
                      },
                    },
                  },
                  "& .MuiAccordionDetails-root": {
                    flexDirection: "column",
                    padding: "0",
                    display: "flex",
                  },
                  "& .form-control":{
                    margin: "-16px 0px 8px 0",
                  }
                },               
              "& .section-title": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.1px",
              },
              "& .hide-content-section-btn": {
                margin: 0,
                minWidth: 24,
              },
            },
            "& .form-control": {
              "& .MuiTextField-root": {
                "& fieldset": { border: "none" },
                borderBottom: "1px solid #E8ECF2",
                height: "48px",
              },
            },
            "& .wrap-items": {
              "& .product-item": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // marginBottom: "8px",

                "& .chk-item": {
                  color: "#94A3B8",
                  marginRight: "8px",
                },
                "& .item-txt": {
                  color: " #2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px" /* 171.429% */,
                  letterSpacing: "0.25px",
                },
              },
            },
            "& .wrap-expand": {
              paddingLeft: "18px",
              "& .expand-list-btn": {
                textTransform: "none",
                color: "var(--brand-color-primary-100, #4BA3B7)",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.25px",
              },
            },
          },
          "& .right-wrapper": {
            display: "flex",
            flexDirection: "row",
            columns: 3,
            width: "calc(100vw - 580px)",
            height: "50vh",
            flex: 1,
            padding: "10px 0px 0px 0px",
            "& .grid-container":{            
            "& .left-side-info":{
              paddingTop:"35px",
              borderRight: "1px solid #E8ECF2",
            },
            "& .right-side-info":{
              paddingLeft:"35px",
              paddingTop:"40px",
            }
            },

            "& .product-list-item": {
              display: "flex",
              flexDirection: "column",
              flex: 1,
              border: "1px solid #E8ECF2",
              borderRadius: "12px",
              padding: "16px",
              minHeight: "203px",

              "& .item-top": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                "& .check-section":{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& .instock-quantity":{
                    color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0.09px',
                    background: '#F2F8FC',
                    borderRadius: '6px',
                    padding: '2px 8px',
                    whiteSpace: "nowrap",
                  },
                },
                "& .left-top": {
                  display: "flex",
                  flexDirection: "column",
                  
                  "& .product-img": {
                    height: "68px",
                    width: "68px",
                    marginBottom: "25px",
                  },
              
                  "& .wrap-under-img": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    "& .dot-img": {
                      width: "6px",
                      height: "6px",
                      margin: "0 6px",
                    },
                    "& .star-icon": {
                      width: "12px",
                      height: "11.4px",
                      marginLeft: "6px",
                    },
                  },
                },

                "& .right-top-chk": {
                  color: "#94A3B8",
                },
              },
              "& .item-bottom-info": {
                marginTop: "25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",

                "& .wrap-name": {
                  flexDirection: "column",
                  display: "flex",
                  "& .dot-section":{
                   display:"flex",
                   alignItems:"center",
                   "& .product-info":{
                     fontFamily: "Roboto",
                     color: "rgba(0, 0, 0, 0.38)",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontStyle: "normal",
                    letterSpacing: "0.4px",
                    lineHeight: "normal",
                   },
                   "& .product-dot-info":{
                    color: "rgba(0, 0, 0, 0.38)",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    letterSpacing: "0.4px",
                    marginLeft:"6px",
                    "&:before": {
                      content: '""',
                      width: "8px",
                      borderRadius: "100%",
                      height: "8px",
                      display: "inline-block",
                      background: "#ECF1F4",
                      marginRight: "8px",
                    },
                   }
                  },
                  "& .product-name-txt": {
                    fontFamily: "Roboto",
                    color: "var(--gray, rgba(43, 43, 43, 0.87))",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    margin:"6px 0"
                  },
                  "& .product-additional-info": {
                    color: "rgba(0, 0, 0, 0.38)",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    letterSpacing: "0.4px",
                  },
                },
                "& .wrap-price": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  "& .price-btn": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    textTransform: "none",
                    marginTop:"20px",
                    "& .retail-price-txt": {
                      color: "rgba(0, 0, 0, 0.38)",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.4px",
                      whiteSpace:"nowrap",
                      display: "flex",            
                      alignItems: "center",
                    },
                    "& .icon-down": {
                      width: "24px",
                      height: "24px",
                      color: "#94A3B8",
                    },
                  },
                  "& .price-txt": {
                    color: "var(--gray, rgba(43, 43, 43, 0.87))",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px" /* 150% */,
                    letterSpacing: "0.15px,",
                    marginRight:"8px",
                  },
                },
              },
            },
          },
        },
      },
    },

    modalBoxCenter: {
      display: "flex",
      // alignItems: "flex-end",
      justifyContent: "flex-end",
    },
   
    modalMergeBoxCenter: {
      backgroundColor: 'var(--White, #FFF)',
      display: "flex",
      width:"100%",
      justifyContent: "flex-end",
      "& .alphabets-content":{
        padding: '251px 13px 10px 0px',
        // background: 'var(--White, #FFF)',
        overflow: 'auto',
        position:"absolute",
        top:"0",
        right:"0",
        pointerEvents: "none",
        "& .alphabets":{
          color: '#4F4F4F',
          fontFamily: 'Roboto',
          textAlign: 'center',
          fontStyle: 'normal',
          fontSize: '10px',
          lineHeight: '20px',
          fontWeight: 400,
          listStyle:"none",
          border: '1px solid var(--Stroke, #E8ECF2)',
          borderRadius: '32px',
          padding: "10px",
        },
      }
    },
    contactGroupsModal: {
      width: "680px",
      height: "100vh",
      backgroundColor: "#ffffff",
      position: "relative",
      overflow: "auto",
      // padding: "0px 60px",
      "& .modal-heading": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: "50px",
        padding: "0px 60px",
        "& .gr-heading-left": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor:"pointer",
          "& .nav-text": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "10px",
          },
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
        },
        "& .heading-right": {
          dispplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .plus-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
            marginLeft: "16px",
          },
        },
      },
      "& .MergeContacts": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        marginTop: "50px",
        justifyContent: "space-between",
        padding: "0px 60px",
        "& .gr-heading-left": {
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          "& .nav-text": {
            fontFamily: "Expletus Sans",
            color: "var(--task-completed-color, #2B2B2B)",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "500",
            marginLeft: "10px",
            lineHeight: "normal",
          },
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "24px",
            fontFamily: "Expletus Sans",
            fontWeight: "400",
            fontStyle: "normal",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            fontFamily: "Roboto",
            color: "var(--text-secondary, #94A3B8)",
            fontSize: "14px",
            fontWeight: "400",
            fontStyle: "normal",
            letterSpacing: "0.105px",
            lineHeight: "26px",
          },
        },
        "& .heading-right": {
          flexDirection: "row",
          dispplay: "flex",
          alignItems: "center",
          "& .plus-icon": {
            height: "24px",
            width: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            height: "24px",
            width: "24px",
            color: "#94A3B8",
            marginLeft: "16px",
          },
        },
      },
      "& .modal-title-box": {
        margin: "45px 0px",
        padding: "0px 60px",
        "& .title-txt": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
        },
        "& .sub-title-txt": {
          color: "var(--task-completed-color, #2B2B2B)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
          marginTop: "8px",
        },
      },
      "& .search-box": {
        margin: "24px 0",
        padding: "0px 60px",
        "& .MuiOutlinedInput-root":{
          borderRadius: "12px",
          marginLeft: "-4px",
        },
        "& .MuiOutlinedInput-input":{
          marginLeft: "6px",
        },

      },
      "& .assign-contact": {
        "& .save-group-button": {
          bottom: "24px",
          width: "100%",
          textDecoration: "none",
          textTransform: "none",
          color: "white",
          display: "flex",
          height: "48px",
          justifyContent: "center",
          alignItems: "center",
          padding: "8px 24px",
          background: "#4BA3B7",
          borderRadius: "6px",
          margin:"20px auto"
        },
        "& .line":{
          borderBottom: "1px solid #E8ECF2",
          marginTop: "20px",
        },
        "& .top-title": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "normal",
          fontWeight: "500",
          margin: '25px 0 20px 70px',
        },
        "& .top-list": {
          justifyContent: "space-between",
          display: "flex",
          margin: "0px 70px",
          flexDirection: "row",
         
          "& .count-contact": {
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            lineHeight: "normal",
            fontStyle: "normal",
            fontWeight: "500",
          },
          "& .select-control": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "3px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              letterSpacing: "0.1px",
              opacity: "0.671",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingBottom: "0",
              paddingRight: "2px",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .selected-contact-header": {
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0px 66px",
          marginBottom: "20px",
          "& .selected-contact-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .remove-contact-txt": {
            color: "var(--hr-red, #C7263E)",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: "500",
            textTransform: "none",
          },
          "& .remove-contact-disable": {
            color: "var(--hr-red, #efafb9)",
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            lineHeight: "normal",
            textTransform: "none",
          },
        },
        "& .selected-contact": {
           padding:"0 60px",
          "& .select-contact-item": {
            display: "block",
            alignItems: "center",
            flexDirection: "row",
           
            padding: "8px 0px",
            borderBottom: '1px solid #E8ECF2',
            marginTop: "26px",
            "&.bg-light-blue":{
              background: "var(--background-background-light, #F2F8FC)",
              borderTop: '1px solid #E8ECF2',
            },
            "& .select-contact-item-block":{
              display: "flex",
              marginTop:"24px",
              padding:"8px 0",
            },
            "& .avatar": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "39px",
              height: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontSize: "15.485px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                lineHeight: "21.679px",
                fontWeight: "600",
                letterSpacing: "-0.395px",
              },
            },
            "& .contact-info": {
              display: "flex",
              marginLeft: "12px",
              flexDirection: "column",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: "500",
                textTransform: "capitalize",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: "500",
              },
              "& .departments": {
                display: "flex",
                marginTop:"4px",
                flexWrap: "wrap",
                marginBottom: "6px",
                "& .dep-item": {
                  display: "flex",
                  marginRight: "16px",
                  padding: "6px",
                  alignItems: "flex-start",
                  borderRadius: "6px",
                  gap: "8px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontSize: "10px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    fontWeight: "400",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              color: "#4BA3B7",
              right: 60,
              marginTop:"-10px",
            },
          },
        },
      },
      "& .un-assign-contact": {
        marginTop: "20px",
        padding: "0 60px",
        "& .top-title": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        },
        "& .un-assign-list": {
          paddingTop: "16px",
          paddingBottom: "40px",
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 0px",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              height: "39px",
              justifyContent: "center",
              width: "39px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              borderRadius: "19.5px",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "15.485px",
                fontWeight: "600",
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
            },
            "& .contact-info": {
              display: "flex",
              marginLeft: "12px",
              flexDirection: "column",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "normal",
                fontWeight: "400",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                marginTop: "2px",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "6px",
                marginTop: "2px",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 60,
              color: "#4BA3B7",
              marginTop: "26px",
            },
          },
          "& .contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "8px 0",
            borderBottom: "1px solid #E8ECF2",
            marginTop: "20px",
            "& .checkedCirlceContact":{
              position: "absolute",
              right: 60,
              color: "#4BA3B7",
              marginTop: "-30px",
            },
            "& .avatar": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "39px",
              height: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                fontFamily: "Roboto",
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontSize: "15.485px",
                fontStyle: "normal",
                lineHeight: "21.679px",
                fontWeight: "600",
                letterSpacing: "-0.395px",
              },
            },
            "& .contact-info": {
              flexDirection: "column",
              display: "flex",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "capitalize",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "normal",
                marginTop: "2px",
                marginBottom: "6px",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                marginTop: "4px",
                marginBottom: "6px",
                "& .dep-item": {
                  display: "flex",
                  marginRight: "16px",
                  padding: "6px",
                  alignItems: "flex-start",
                  borderRadius: "6px",
                  gap: "8px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    fontFamily: "Roboto",
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontSize: "10px",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    fontWeight: "400",
                  },
                },
              },
            },
            "& .circleCheck": {
              position: "absolute",
              right: 60,
              marginTop:"-30px",
              color: "#E8ECF2",
            },
          },
        },
        "& .bottom-button": {
          bottom: "24px",
          width: "100%",
          textTransform: "none",
          textDecoration: "none",
          color: "white",
          display: "flex",
          height: "48px",
          padding: "8px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
          marginTop:"100px"
        },
        "& .bottom-button-disabled": {
          bottom: "24px",
          width: "100%",
          textTransform: "none",
          textDecoration: "none",
          color: "white",
          display: "flex",
          height: "48px",
          padding: "8px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "#92C7D3",
          marginTop:"100px",
          borderColor:"#92C7D3",
        },
        "& .top-list": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0px 70px",
         
          "& .count-contact": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .select-control": {
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .multiple-clear": {
                fontSize: "15px",
                position: "absolute",
                right: "18px",
                zIndex: 1,
                top: "3px",
                color: "#94A3B8",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              opacity: "0.671",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingRight: "2px",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
          },
        },
      },
    },

    removeGroupsModal: {
      width: "480px",
      height: "100vh",
      backgroundColor: "#ffffff",
      position: "relative",
      overflow: "auto",
      padding:" 45px 60px",
      "& .modal-heading": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginBottom: "48px",        
        marginTop: "20px",
        "& .title-txt": {
          color: 'rgba(43, 43, 43, 0.87)',
          fontFamily: 'Expletus Sans',
          fontStyle: 'normal',
          fontSize: '24px',
          lineHeight: 'normal',         
          fontWeight: 400,
        },
        "& .heading-left": {
          "& .modal-title": {
            fontFamily: "Expletus Sans",
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
        },
        "& .heading-right": {
          flexDirection: "row",
          alignItems: "center",
          dispplay: "flex",
          "& .plus-icon": {
            height: "24px",
            color: "#94A3B8",
            width: "24px",
          },
          "& .close-icon": {
            height: "24px",
            marginLeft: "16px",
            width: "24px",
            color: "#94A3B8",
          },
        },
       },
       "& .MergeContacts": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        marginTop: "20px",
        justifyContent: "space-between",
        marginBottom: "48px",        
        "& .title-txt": {
          color: 'rgba(43, 43, 43, 0.87)',
          fontSize: '24px',
          fontFamily: 'Expletus Sans',
          fontStyle: 'normal',
          lineHeight: 'normal',         
          fontWeight: 400,
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontWeight: "400",
            fontSize: "24px",
            lineHeight: "30px",
            fontStyle: "normal",
          },
          "& .sub-txt": {
            fontSize: "14px",
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            lineHeight: "26px",
            fontStyle: "normal",
            letterSpacing: "0.105px",
            fontWeight: "400",
          },
        },
        "& .heading-right": {
          flexDirection: "row",
          alignItems: "center",
          dispplay: "flex",
          "& .plus-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            height: "24px",
            marginLeft: "16px",
            width: "24px",
            color: "#94A3B8",
          },
        },
       },
      "& .rename-group-button": {
        bottom: "24px",
        width: "100%",
        textTransform: "none",
        textDecoration: "none",
        display: "flex",
        color: "white",
        height: "48px",
        justifyContent: "center",
        padding: "8px 24px",
        alignItems: "center",
        background: "#4BA3B7",
        borderRadius: "6px",
        marginTop:"50px"
      },
    },
    mergeInfoGroupsModal: {
      height: "100vh",
      width: "480px",
      position: "relative",
      backgroundColor: "#ffffff",
      overflow: "auto",
      "& .merge-wrapper":{
        padding:"20px 40px",
      },
      "& .btn-merge": {
        display: "flex",
        padding: "8px 24px",
        height: "48px",
        alignItems: "center",
        justifyContent: "center",
        background: "#4BA3B7",
        borderRadius: "6px",
        textDecoration: "none",
        textTransform: "none",
        color: "#FFF",
        width:"100%",
        margin: "48px 0px",
      },
      "& .heading-bar": {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        margin: "20px 20px 0px 0px",
        justifyContent: "end",
        cursor:"pointer",
        "& .close-icon": {
          height: "24px",
          width: "24px",
          color: "#959595",
          cursor:"pointer"
        },
      },
      "& .line":{
        height: "20px",
        borderBottom: "1px solid #E8ECF2",
      },
      "& .profile-name":{
        margin: "5px auto",
        fontSize: "18px",
        fontFamily: "Expletus Sans",
        fontWeight: 400, 
        fontStyle: "normal",
        color: "#2B2B2B",
        lineHeight: "24px",
        textAlign:"center",
        textTransform: "capitalize",
        "& .edit-icon": {
          height: "20px",
          marginLeft: "8px",
          cursor:"pointer",
          width: "20px",
        },
      },
      "& .headding":{
        color: '#000',
        fontSize: '16px',
        fontFamily: 'Expletus Sans',
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: '24px',
        marginTop:"20px",
      },
      "& .heading-title":{
        color: 'var(--text-secondary, #94A3B8)',
        fontStyle: 'normal',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.09px',
        lineHeight: '16px',
        marginTop:"20px",
      },
      "& .headding-wrapper":{
        justifyContent: 'space-between',
        display: 'block',
        marginTop: '10px',
        alignItems: 'center',
      },
      "& .selection-wrapper":{
         position:"relative",
         "& .radio-hide":{
           right: '4px',
           position: 'absolute',
           display: 'none',
           bottom: '61px', 
         },
        },
        "& .radio-hide2":{
         position: 'absolute',
         bottom: '25px', 
         right: '4px',
        },
      "& .heading-title-sub":{
        color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        lineHeight: '26px',
        fontWeight: 400,
        marginLeft:"0",
        letterSpacing: '0.105px',
      },
      "& .heading-avatar": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "15px",
        "& .wrap-avatar": {
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          marginBottom:"16px",
          justifyContent: "center",
          "& .shortcut-name": {
            fontFamily: "Expletus Sans",
            position: "absolute",
            color: "var(--White, #FFF)",
            fontStyle: "normal",
            fontSize: "32px",
            lineHeight: "42px",
            fontWeight: "600",
          },
        },
      },
      "& .modal-heading": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        marginTop: "20px",
        justifyContent: "space-between",
        marginBottom: "48px",        
        "& .title-txt": {
          fontFamily: 'Expletus Sans',
          color: 'rgba(43, 43, 43, 0.87)',
          fontSize: '24px',
          fontWeight: 400,
          fontStyle: 'normal',
          lineHeight: 'normal',         
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
        },
        "& .heading-right": {
          flexDirection: "row",
          alignItems: "center",
          dispplay: "flex",
          "& .plus-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
            marginLeft: "16px",
          },
        },
       },
       "& .MergeContacts": {
         flexDirection: "row",
         display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "48px",        
        marginTop: "20px",
        "& .title-txt": {
          color: 'rgba(43, 43, 43, 0.87)',
          fontSize: '24px',
          fontFamily: 'Expletus Sans',
          fontWeight: 400,
          lineHeight: 'normal',         
          fontStyle: 'normal',
        },
        "& .heading-left": {
          "& .modal-title": {
            fontSize: "24px",
            fontFamily: "Expletus Sans",
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontWeight: "400",
            lineHeight: "30px",
            fontStyle: "normal",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "26px",
            fontStyle: "normal",
            letterSpacing: "0.105px",
            fontWeight: "400",
          },
        },
        "& .heading-right": {
          flexDirection: "row",
          alignItems: "center",
          dispplay: "flex",
          "& .plus-icon": {
            height: "24px",
            color: "#94A3B8",
            width: "24px",
          },
          "& .close-icon": {
            width: "24px",
            height: "24px",
            marginLeft: "16px",
            color: "#94A3B8",
          },
        },
       },
      "& .rename-group-button": {
        bottom: "24px",
        width: "100%",
        textTransform: "none",
        textDecoration: "none",
        display: "flex",
        color: "white",
        height: "48px",
        justifyContent: "center",
        padding: "8px 24px",
        alignItems: "center",
        background: "#4BA3B7",
        borderRadius: "6px",
        marginTop:"50px"
      },
    },

    popover: {
      "& .MuiPopover-root": {
        backgroundColor:"red",
        "& .MuiPaper-root": {
          "& .contact-item-menu": {
            width: "285px",
            "& .menu-item": {
              display: "flex",
              flexDirection: "row",
            },
          },
        },
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    editContactModal: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 24,
      width: 680,
      backgroundColor: "#ffffff",
      padding: 24,
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      display: "flex",
      flexDirection: "column",

      "& .profile-name":{
        margin: "5px auto",
        fontFamily: "Expletus Sans",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400, 
        lineHeight: "24px",
        color: "#2B2B2B",
        textTransform: "capitalize",
      },

      "& .profile-email":{
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0.09px',
        color: '#2B2B2B',
        margin: "0 auto",
      },

      "& .heading-bar": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        "& .heading-title": {
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Expletus Sans",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        },
        "& .close-icon": {
          width: "24px",
          height: "24px",
          color: "#959595",
          cursor:"pointer",
        },
      },
      "& .heading-avatar": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "32px",
        "& .wrap-avatar": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom:"16px",
          "& .shortcut-name": {
            position: "absolute",
            color: "var(--White, #FFF)",
            fontFamily: "Expletus Sans",
            fontSize: "32px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "42px",
          },
        },
      },
      "& .info-group": {
        padding: "16px 0px",
        width: "100%",
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "&:last-child": {
            "& .form-control": {
              marginBottom: "0",
            },
          },
          "& .form-col": {
            maxWidth: "100%",
            flex: "0 0 100%",
            padding: "0 8px",
            "&.col6": {
              maxWidth: "50%",
              flex: "0 0 50%",
            },
            "& .form-control": {
              marginBottom: "16px",
            },           
          },
        },
        "& .select-outer": {
          width: "100%",
          // marginTop:'-1.3vw',
          marginBottom: "16px",
          "& .error-select":{
            color:'#C7263E',  
          },
          "& svg":{
           fill:'#94A3B8',  
         },
          "& .Mui-error":{
            "& .MuiOutlinedInput-notchedOutline":{
              borderColor:'#C7263E',  
            }
          },       
          "& .MuiInputLabel-formControl": {
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            letterSpacing: "0.1px",
            lineHeight: "19px",
            color: "#94A3B8",
            width: "calc(100% - 46px)",
            display: "flex",
            "&.MuiFormLabel-filled": {
              width: "auto",
              fontSize: "12px",
              color : "rgba(43,43,43,0.87)",
              lineHeight: "16px",
              fontFamily: "Roboto",
              letterSpacing: "0.0075em",
              fontWeight: "400",
            }
          },
          "& .MuiSelect-select": {
            letterSpacing: "0",
            fontSize: "14px",
            color: "#000000",
            fontWeight: "500",
            opacity: "0.671",
            fontFamily: "Expletus Sans",
            justifyContent:'space-between',
            display:'flex',
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              color : "rgba(43,43,43,0.87)",
              fontSize: "12px",
              width: "auto",
              letterSpacing: "0.0075em",
              lineHeight: "16px",
              fontWeight: "400",
              fontFamily: "Roboto",
            },
            "& .MuiInputLabel-asterisk": {
              lineHeight: "23px",
              marginLeft: "auto",
              color: "#e86577",
              fontSize: "18px",
            }
          }
        },
        "& .group-title": {
          color: "#000",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
          marginBottom: "16px",
        },
        "& .shipping-address-chkbox": {
          color: "#4BA3B7",
        },
        "& .shipping-address-txt": {
          marginBottom: "16px",
        },
        "& .group-sub-title": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
          letterSpacing: "0.105px",
          marginBottom: "16px",
          display:"flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .group-caption": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .btn-save": {
        display: "flex",
        height: "48px",
        padding: "8px 24px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px",
        background: "#4BA3B7",
        textTransform: "none",
        textDecoration: "none",
        color: "#FFF",
        margin: "48px 0px",
      },
    },

    contactMenuItem: {
      width: "285px",
      padding: "10px 20px",
      "& .menu-item": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "56px",
        justifyContent: "space-between",
        cursor:"pointer",
        "& .menu-icon": {
          width: "24px",
          height: "24px",
        },
      },
    },
    dropDropdown: {
      "& .MuiMenu-paper":{
        minWidth: "156px",
        filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        marginTop: "-84px",
        // marginLeft: "-20px",
        boxShadow: "none",
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#000000",
          padding: "14px 17px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "flex",
          justifyContent: "flex-start",
        },
        "& .MuiList-padding": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
    },
    dropDropdown2: {
      "& .MuiMenu-paper":{
        filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
        minWidth: "156px",
        borderRadius: "8px",
        backgroundColor: "#fafafa",
        marginTop: "7px",     
        boxShadow: "none",       
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          letterSpacing: "0",
          lineHeight: "20px",
          color: "#000000",
          fontWeight: "500",
          padding: "14px 17px",
          fontFamily: "Expletus Sans",
          justifyContent: "flex-start",
          display: "flex",
        },
        "& .MuiList-padding": {
          paddingBottom: "8px",
          paddingTop: "8px",
        },
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: "0",
      width: 680,
      overflowY: "auto",
      borderRadius: "0",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
      },
      "&.merge-contact-modal": {
        width: 640,
        [theme.breakpoints.down("sm")]: {
          width: 570,
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "90%",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          borderBottom: "1px solid #E8ECF2",
          padding: "24px 40px",
          marginBottom: "0",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            "& .heading-link": {
              marginRight: "24px",
              [theme.breakpoints.down("xs")]: {
                marginRight: "16px",
              },
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
          "& .close-icon": {
            lineHeight: "0",
            color: "#94A3B8",
            "& .MuiSvgIcon-root": {
              height: "20px",
              width: "20px",
              fontSize: "20px",
            },
          },
        },
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "22px",
          lineHeight: "26px",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "16px",
          "& .priority-status": {
            marginRight: "5px",
            color: "#C7263E",
          },
        },
        "& .modal-description": {
          padding: "44px 60px 24px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
          "& .profile-block-wrapper": {
            marginBottom: "32px",
            "& .profile-info": {
              "& .profile-outer": {
                margin: "0 auto 24px",
              },
              "& .profile-name": {
                justifyContent: "center",
              },
            },
          },
          "& .button-wrapper": {
            marginTop: "34px",
            "& .primary-btn": {
              marginBottom: "0",
            },
          },
        },
      },
      "&.view-modal-dialog": {
        width: 640,
        [theme.breakpoints.down("sm")]: {
          width: 570,
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "90%",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0",
          borderBottom: "1px solid #E8ECF2",
          padding: "24px 40px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            "& .heading-link": {
              marginRight: "24px",
              [theme.breakpoints.down("xs")]: {
                marginRight: "16px",
              },
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
          "& .close-icon": {
            lineHeight: "0",
            color: "#94A3B8",
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
              fontSize: "20px",
            },
          },
        },
        "& .modal-title": {
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "16px",
          "& .priority-status": {
            color: "#C7263E",
            marginRight: "5px",
          },
        },
        "& .modal-description": {
          padding: "44px 40px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
        },
      },
      "&.filter-dialogbox": {
        width: 640,
        [theme.breakpoints.down("sm")]: {
          width: 570,
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          maxWidth: "100%",
        },
        "& .modal-heading": {
          marginBottom: "40px",
          "& .filter-heading": {
            alignItems: "center",
            display: "flex",
            "& .auth-back": {
              cursor: "pointer",
              "& .MuiSvgIcon-root": {
                color: "rgba(43, 43, 43, 0.67)",
              },
            },
            "& .modal-title ": {
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "19px",
              marginLeft: "8px",
              fontFamily: "Expletus Sans",
              letterSpacing: "0",
              color: "#2B2B2B",
            },
          },
          "& .right-col": {
            alignItems: "center",
            display: "flex",
            "& .right-icon": {
              lineHeight: "0",
              color: "#94A3B8",
              marginRight: "24px",
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            lineHeight: "19px",
            fontSize: "14px",
            color: "#4BA3B7",
            textDecoration: "none",
            fontFamily: "Expletus Sans",
          },
        },
        "& .modal-dialoginner": {
          padding: "80px 40px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
        },
        "& .modal-description": {
          "& .heading-block": {
            "& .primary-txt": {
              fontSize: "22px",
            },
            "& .sub-txt": {
              lineHeight: "16px",
              fontSize: "12px",
            },
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "80px 60px",
      },
      "& .modal-heading": {
        marginBottom: "20px",
        justifyContent: "space-between",
        display: "flex",
        "& .modal-title ": {
          fontWeight: 400,
          lineHeight: "26px",
          fontSize: "22px",
          color: "#2b2b2b",
          fontFamily: "Expletus Sans",
        },
        "& .heading-left": {
          alignItems: "center",
          display: "flex",
          "& .auth-back": {
            marginRight: "8px",
            cursor: "pointer",
            color: "#2B2B2B",
            opacity: "0.67",
          },
          "& .modal-title ": {
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "19px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
          },
        },
        "& .close-icon": {
          lineHeight: "0",
          color: "#94A3B8",
        },
      },
      "& .modal-description": {
        "& .select-outer": {
          width: "100%",
          "& .MuiInputLabel-formControl": {
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            letterSpacing: "0.1px",
            lineHeight: "19px",
            color: "#94A3B8",
            width: "calc(100% - 46px)",
            display: "flex",
            "&.MuiFormLabel-filled": {
              width: "auto",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            color: "#000000",
            letterSpacing: "0",
            opacity: "1",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            alignItems: "center",
            display: "flex",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .status-name": {
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              letterSpacing: "0.105px",
              lineHeight: "26px",
            },
            "& .status": {
              width: "12px",
              background: "#94A3B8",
              height: "12px",
              marginLeft: "10px",
              borderRadius: "100%",
              "&.open": {
                background: "#4BA3B7",
              },
              "&.cancelled": {
                background: "#F7E223",
              },
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              lineHeight: "23px",
              fontSize: "18px",
              marginLeft: "auto",
            },
          },
        },
        "& .taskview-list": {
          marginBottom: "24px",
          "& .MuiListItem-root": {
            padding: "16px 0",
            display: "flex",
            borderBottom: "1px solid #E8ECF2",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "& .list-label": {
              width: "40%",
              lineHeight: "26px",
              fontSize: "14px",
              fontFamily: "Roboto",
              color: "#94A3B8",
              letterSpacing: "0.105px",
              fontWeight: 400,
            },
            "& .list-description": {
              width: "60%",
              fontSize: "14px",
              paddingLeft: "15px",
              lineHeight: "26px",
              fontFamily: "Roboto",
              color: "#2B2B2B",
              letterSpacing: "0.105px",
              fontWeight: 400,
              "& .list-subtitle": {
                lineHeight: "14px",
                fontSize: "10px",
                letterSpacing: "normal",
                color: "#94A3B8",
              },
              "& .assignee-list": {
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  alignItems: "center",
                  display: "flex",
                  background: "#ECF1F4",
                  justifyContent: "center",
                  height: "24px",
                  width: "24px",
                  borderRadius: "100%",
                  color: "rgba(43, 43, 43, 0.87)",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontWeight: 600,
                  fontFamily: "Roboto",
                  lineHeight: "13.42px",
                  position: "relative",
                  letterSpacing: "-0.244px",
                  marginLeft: "-4px",
                  "&:first-child": {
                    marginLeft: "0",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                marginBottom: "16px",
                alignItems: "center",

                "& .label": {
                  color: "#94A3B8",
                },
                "& .value": {
                  display: "flex",
                  marginLeft: "10px",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
            },
          },
        },
        "& .tasklist-tabwrapper": {
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
            },
          },
          "& .MuiTabs-root": {
            marginBottom: "24px",
            borderBottom: "1px solid #E8ECF2",
            "& .MuiTabs-flexContainer": {
              padding: "0 24px",
            },
            "& .MuiTab-root": {
              fontSize: "14px",
              color: "#2B2B2B",
              fontWeight: 500,
              lineHeight: "20px",
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              padding: "16px 24px",
              opacity: "0.67",
              letterSpacing: "0",
              minWidth: "unset",
              "&.Mui-selected": {
                opacity: "1",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            background: "#F2F8FC",
            borderRadius: "12px",
            padding: "16px",
            fontSize: "14px",
            color: "#2B2B2B",
            fontWeight: 400,
            letterSpacing: "0.105px",
            lineHeight: "26px",
            fontFamily: "Roboto",
          },
          "& .attachments-list": {
            "& .subheader-title": {
              display: "flex",
              position: "static",
              padding: "0",
              justifyContent: "space-between",
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "16px",
              "& .title": {
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                opacity: "0.67",
              },
              "& .tasklist-link": {
                fontSize: "14px",
                color: "#4BA3B7",
                fontFamily: "Expletus Sans",
                lineHeight: "19px",
                fontWeight: 500,
                textDecoration: "none",
                opacity: "0.67",
              },
            },
            "& .list-item": {
              paddingTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              paddingLeft: "0",
              paddingBottom: "16px",
              "& .list-img": {
                marginRight: "8px",
                width: "28px",
              },
              "& .list-name": {
                color: "#2B2B2B",
                lineHeight: "16px",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                margin: "0",
                padding: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },
          },
          "& .tab-heading": {
            justifyContent: "space-between",
            display: "flex",
            marginBottom: "24px",
            alignItems: "center",
            "& .heading-right": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
            "& .primary-txt": {
              color: "#2B2B2B",
              fontWeight: 500,
              fontSize: "16px",
              letterSpacing: "0",
              lineHeight: "21px",
              fontFamily: "Expletus Sans",
              marginBottom: "5px",
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "17px",
              fontFamily: "Roboto",
              letterSpacing: "0",
              marginBottom: "0",
            },
          },
        },
        "& .activitylist-ul": {
          padding: "0",
          "& .activity-li": {
            display: "flex",
            padding: "0",
            marginBottom: "32px",
            justifyContent: "space-between",
            position: "relative",
            background: "transparent",
            "&:last-child": {
              marginBottom: "0",
              "&:before": {
                display: "none",
              },
            },
            "&:before": {
              content: "''",
              position: "absolute",
              borderRight: "1px solid #E8ECF2",
              top: "100%",
              height: "100%",
              left: "16px",
            },
            "& .activity-details": {
              margin: "0",
              "& .activity-info": {
                fontSize: "12px",
                color: "#2B2B2B",
                lineHeight: "16px",
                fontWeight: 400,
                fontFamily: "Roboto",
                letterSpacing: "0.09px",
                marginBottom: "2px",
                "& .name": {
                  marginRight: "5px",
                  color: "#4BA3B7",
                },
              },
              "& .activity-date": {
                color: "#94A3B8",
                lineHeight: "17px",
                fontSize: "10px",
                fontFamily: "Roboto",
                letterSpacing: "0.125px",
                fontWeight: 400,
              },
            },
            "& .activity-icon": {
              minWidth: "unset",
              height: "32px",
              width: "32px",
              marginRight: "16px",
            },
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          "& .primary-txt": {
            fontSize: "24px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            lineHeight: "32px",
            fontWeight: 400,
            marginBottom: "6px",
          },
          "& .sub-txt": {
            fontSize: "14px",
            color: "#94A3B8",
            lineHeight: "26px",
            marginBottom: "0",
            letterSpacing: "0.105px",
          },
        },
        "& .tasklist-member": {
          padding: "0",
          "& .tasklist-li": {
            display: "flex",
            background: "transparent",
            justifyContent: "space-between",
            position: "relative",
            padding: "26px 24px 18px",
            borderBottom: "1px solid #ECF1F4",
            "& .tasklist-left": {
              display: "flex",
              alignItems: "center",
              "& .assignee-info": {
                marginLeft: "12px",
              },
              "& .assignee-profile": {
                display: "flex",
                width: "38px",
                height: "38px",
                background: "#ECF1F4",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Roboto",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "15.485px",
                fontWeight: 600,
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
              "& .assignee-name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
              "& .assignee-email": {
                color: "#94A3B8",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
              },
            },
            "& .tasklist-icon": {
              minWidth: "unset",
            },
          },
        },
        "& .assignee-multiSelect-list": {
          paddingTop: "0",
          "& .MuiListSubheader-gutters": {
            position: "static",
            paddingLeft: "0",
            paddingRight: "0",
            "&.multiselect-subheader": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: "500",
              borderBottom: "1px solid #ECF1F4",
              lineHeight: "19px",
              marginBottom: "5px",
              padding: "15px 20px",
              "& .tasklist-link": {
                marginLeft: "10px",
                fontSize: "14px",
                color: "#C7263E",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                whiteSpace: "nowrap",
                lineHeight: "19px",
                textDecoration: "none",
              },
            },
            "&.multiselect-subtxt": {
              fontFamily: "Roboto",
              color: "#94A3B8",
              fontWeight: 400,
              fontSize: "10px",
              lineHeight: "normal",
              padding: "10px 20px",
              textTransform: "uppercase",
              borderBottom: "1px solid #ECF1F4",
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-input": {
                lineHeight: "17px",
                fontSize: "13px",
                padding: "16px",
                letterSpacing: "0",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
          "& .MuiListItem-root": {
            cursor: "pointer",
            padding: "15px 20px",
          },
          "& .multiselect-membres": {
            alignItems: "center",
            display: "flex",
            width: "100%",
            padding: "15px 20px",
            justifyContent: "space-between",
            borderBottom: "1px solid #ECF1F4",
            "& .total-member": {
              color: "#2B2B2B",
              fontSize: "12px",
              fontFamily: "Expletus Sans",
              lineHeight: "19px",
              fontWeight: 500,
            },
            "& .select-control": {
              "& .MuiSelect-select": {
                fontSize: "12px",
                color: "#2b2b2b",
                opacity: "1",
                letterSpacing: "0",
              },
            },
          },
          "& .assignee-detail": {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .assignee-left": {
              display: "flex",
              "& .assignee-info": {
                marginLeft: "10px",
              },
              "& .assignee-profile": {
                width: "39px",
                height: "39px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#ECF1F4",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontWeight: 600,
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
              "& .assignee-name": {
                lineHeight: "19px",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                color: "#2b2b2b",
                fontWeight: 500,
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .assignee-right": {
              marginLeft: "10px",
              "& .assinee-selected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "none",
                },
                "& .selected-img": {
                  display: "block",
                },
              },
              "& .assinee-unselected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "block",
                },
                "& .selected-img": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .button-wrapper": {
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
        "& .profile-block-wrapper": {
          marginBottom: "17px",
          "& .profile-info": {
            "& .profile-row": {
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginBottom: "8px",
              display: "flex",
              "& .right-col": {
                marginLeft: "15px",
                marginTop: "4px",
              },
            },
            "& .heading-avatar": {
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "32px",
              "& .wrap-avatar": {
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginBottom:"16px",
                flexDirection: "row",
                "& .shortcut-name": {
                  fontFamily: "Expletus Sans",
                  position: "absolute",
                  color: "var(--White, #FFF)",
                  fontSize: "32px",
                  fontWeight: "600",
                  fontStyle: "normal",
                  lineHeight: "42px",
                },
              },
            },
            "& .profile-outer": {
              height: "160px",
              width: "160px",
              padding: "10px",
              border: "1px dashed rgba(43, 43, 43, 0.87)",
              borderRadius: "100%",
              margin: "0 auto 16px",
              [theme.breakpoints.down("xs")]: {
                height: "140px",
                width: "140px",
              },
              [theme.breakpoints.down("sm")]: {
                height: "120px",
                width: "120px",
              },
              "& .profile-icon": {
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                width: "100%",
                background: "#4BA3B7",
                height: "100%",
                fontWeight: 600,
                borderRadius: "inherit",
                fontSize: "43px",
                color: "#FFFFFF",
                lineHeight: "56px",
                overflow: "hidden",
                fontFamily: "Expletus Sans",
                textTransform: "uppercase",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "38px",
                  lineHeight: "48px",
                },
                [theme.breakpoints.down("xs")]: {
                  fontSize: "32px",
                  lineHeight: "42px",
                },
              },
            },
            "& .profile-name": {
              fontSize: "18px",
              fontWeight: 400,
              color: "#000000",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
              marginBottom: "8px",
              alignItems: "center",
              display: "flex",
              "& .edit-icon": {
                marginLeft: "8px",
                height: "20px",
                width: "20px",
                cursor:"pointer",
              },
            },
            "& .job-info": {
              fontSize: "13px",
              fontWeight: 400,
              letterSpacing: "0",
              lineHeight: "17px",
              color: "rgba(43, 43, 43, 0.67)",
              marginBottom: "14px",
              fontFamily: "Roboto",
            },
          },
          "& .contact-statistics": {
            "& .statistics-col": {
              justifyContent: "flex-end",
              display: "flex",
              marginBottom: "24px",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "16px",
              },
              "& .content": {
                fontFamily: "Expletus Sans",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "14px",
                lineHeight: "19px",
                fontWeight: 500,
                marginRight: "6px",
                opacity: "0.67",
                [theme.breakpoints.down("xs")]: {
                  fontWeight: "400",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  letterSpacing: "0.09px",
                  lineHeight: "16px",
                },
              },
              "& .value": {
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                marginRight: "6px",
                lineHeight: "19px",
              },
            },
          },
        },
        "& .contact-info-wrapper": {
          "& .info-block": {
            marginBottom: "24px",
            paddingBottom: "7px",
            borderBottom: "1px solid #E8ECF2",
            "&:last-child": {
              paddingBottom: "0",
              marginBottom: "0",
              borderBottom: "0",
            },
            "& .info-heading": {
              color: "#000000",
              fontSize: "16px",
              fontFamily: "Roboto",
              lineHeight: "22px",
              fontWeight: 400,
              marginBottom: "16px",
            },
            "& .about-info-row": {
              flexWrap: "wrap",
              display: "flex",
              margin: "0 -24px",
              [theme.breakpoints.down("sm")]: {
                margin: "0 -12px",
              },
              "& .about-col": {
                width: "33.33%",
                marginBottom: "16px",
                padding: "0 24px",
                [theme.breakpoints.down("sm")]: {
                  padding: "0 12px",
                },
                [theme.breakpoints.down("xs")]: {
                  width: "100%",
                },
                "& .label": {
                  color: "#94A3B8",
                  fontSize: "10px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.125px",
                  lineHeight: "17px",
                  marginBottom: "6px",
                },
                "& .value": {
                  color: "#000000",
                  fontSize: "13px",
                  fontFamily: "Roboto",
                  lineHeight: "17px",
                  fontWeight: 400,
                },
              },
            },
          },
        },
        "& .contact-accodion": {
          "& .select-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
              "& .down-arrow": {
                position:"absoulute",
                color: "rgba(43, 43, 43, 0.67)",
              },
            },
          },
          "& .MuiAccordion-root": {
            boxShadow: "none",
            borderRadius: "0",
            margin: "0 0 10px",
            "&:before": {
              display: "none",
            },
            "& .MuiAccordionSummary-root": {
              padding: "0",
              marginBottom: "0px",
              minHeight: "unset",
              [theme.breakpoints.down("sm")]: {
                marginBottom: "16px",
              },
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiIconButton-root": {
                padding: "0",
                marginRight: "0",
                "& .MuiSvgIcon-root": {
                  height: "20px",
                  width: "20px",
                  color: "#94A3B8",
                  fontSize: "20px",
                },
              },
            },
            "& .MuiAccordionDetails-root": {
              padding: "0",
              flexDirection: "column",
              display: "flex",
            },
          },
          "& .content-heading": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "16px",
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "16px",
            "& .content-icon":{
              cursor:"pointer",
            },
            "& .title": {
              color: "rgba(43, 43, 43, 0.67)",
              fontSize: "12px",
              fontFamily: "Expletus Sans",
              lineHeight: "16px",
              fontWeight: 500,
            },
            "& .content-filter": {
              alignItems: "center",
              display: "flex",
              "& .select-control": {
                "& .MuiSelect-select": {
                  fontSize: "12px",
                  position: "relative",
                  zIndex: 9,
                  paddingRight: "30px", // this is important
                },
                "&.border-hr": {
                  paddingRight: "10px",
                  marginRight: "10px",
                },
                "& .down-arrow": {
                  width: "20px",
                  fontSize: "20px",
                  color: "rgba(43, 43, 43, 0.67)",
                  height: "20px",
                  position:"absolute", // this is important
                  right:"0",
                  zIndex: 0,
                },
              },
            },
          },

          "& .wrap-add-note-btn": {
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-end",
            "& .cancel-add-note-btn": {
              textDecoration: "none",
              display: "flex",
              textTransform: "none",
              width: "156px",
              height: "48px",
              justifyContent: "center",
              padding: "8px 14px",
              alignItems: "center",
              background: "#fff",
              borderRadius: "6px",
              color: "#000",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              fontSize: "14px",
              marginRight: "16px",
              border: "1px solid #E8ECF2",
            },
            "& .add-note-btn": {
              display: "flex",
              padding: "8px 24px",
              height: "48px",
              justifyContent: "center",
              borderRadius: "6px",
              alignItems: "center",
              background: "#4BA3B7",
              fontFamily: "Expletus Sans",
              color: "#FAFCFE",
              fontSize: "14px",
              fontWeight: "500",
              fontStyle: "normal",
              marginBottom: "24px",
              lineHeight: "normal",
              textDecoration: "none",
              textTransform: "none",
              width: "156px",
            },
            "& .add-note-btn-disable": {
              height: "48px",
              display: "flex",
              padding: "8px 24px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
              color: "#FAFCFE",
              background: "#92C7D3",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              lineHeight: "normal",
              fontWeight: "500",
              marginBottom: "24px",
              textDecoration: "none",
              textTransform: "none",
              width: "156px",
            },
          },
          "& .content-details": {
            width: "100%",
            "& .content-inner": {
              width: "100%",
              display: "flex",
              marginBottom: "16px",
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "22px",
              wordBreak: "break-word",
              "&.no-opacity":{
                opacity : 1,
              },
              "&.grey-color":{
                opacity : 0.5,
              },
              "& .assignee-profile": {
                width: "24px",
                display: "flex",
                height: "24px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                background: "#ECF1F4",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "10px",
                fontFamily: "Roboto",
                lineHeight: "14px",
                fontWeight: 600,
                letterSpacing: "-0.395px",
              },
              "& .assignee-info": {
                flex: 1,
                marginLeft: "8px",
                "& .top-info": {
                  justifyContent: "space-between",
                  marginBottom: "4px",
                  display: "flex",
                  [theme.breakpoints.down("xs")]: {
                    flexDirection: "column",
                  },
                  "& .info-left": {
                    display: "flex",
                    "& .select-modal-field": {
                      "& .form-control": {
                        marginLeft: "6px",
                        marginBottom: "0",
                        "& .MuiOutlinedInput-input": {
                          width: "0",
                          padding: "0",
                        },
                        "& .MuiInputAdornment-positionEnd": {
                          marginLeft: "0",
                      },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "0",
                          padding: "0",
                          justifyContent: "flex-start",
                          display: "flex",
                          "& .MuiOutlinedInput-notchedOutline": {
                            padding: "0",
                            borderColor: "transparent",
                          }
                        }
                      },      
                      "& .MuiInputAdornment-positionEnd": {
                        "& .MuiSvgIcon-root": {
                          color: "#94A3B8",
                          fontSize: "20px",
                          marginLeft:"-8px",
                        },
                      },      
                    },
                    "& .status-item": {
                      alignItems: "center",
                      display: "flex",
                      "& .status-name": {
                        textTransform: "capitalize",
                        marginRight: "4px",
                        color:"#2B2B2B",
                        fontSize:"10px",
                      },
                      "& .status": {
                        width: "8px",
                        height: "8px",
                        background: "#94A3B8",
                        borderRadius: "100%",
                        marginLeft: "auto",
                        "&.open": {
                          background: "#4BA3B7",
                        },
                        "&.cancelled": {
                          background: "#F7E223",
                        },
                        "&.yellow": {
                          background: "#F7E223",
                        },
                        "&.sky-blue": {
                          background: "#12AEF1",
                        },
                        "&.green": {
                          background: "#00BA88",
                        },
                        "&.orange": {
                          background: "#F6A318",
                        },
                        "&.black": {
                          background: "#2B2B2B",
                        },
                      },
                    },
                    "& .name": {
                      color: "#4F4F4F",
                      fontSize: "10px",
                      fontFamily: "Roboto",
                      lineHeight: "17px",
                      fontWeight: 400,
                      letterSpacing: "0.125px",
                    },
                    "& .stage-control": {
                      marginLeft: "6px",
                    },
                  },
                  "& .date": {
                    display: "flex",
                    marginLeft: "10px",
                    alignItems: "center",
                    color: "#94A3B8",
                    fontSize: "10px",
                    fontFamily: "Roboto",
                    lineHeight: "17px",
                    fontWeight: 400,
                    letterSpacing: "0.125px",
                    [theme.breakpoints.down("xs")]: {
                      marginTop: "2px",
                      marginLeft: "0",
                    },
                    "& .attach-icon": {
                      marginRight: "8px",
                    },
                  },
                },
                "& .primary-txt": {
                  lineHeight: "19px",
                  fontSize: "14px",
                  color: "#2b2b2b",
                  fontWeight: 600,
                  fontFamily: "Expletus Sans",
                  marginBottom: "8px",
                },
                "& .assignee-desc": {
                  fontSize: "12px",
                  color: "#4F4F4F",
                  lineHeight: "17px",
                  fontFamily: "Roboto",
                  maxWidth: "282px",
                  fontWeight: 400,
                  width: "100%",
                },
                "& .assignee-desc-dark": {
                  fontSize: "12px",
                  color: "#2b2b2b",
                  fontFamily: "Roboto",
                  lineHeight: "17px",
                  maxWidth: "282px",
                  fontWeight: 400,
                  width: "100%",
                },
                "& .edit-note-wrapper": {
                  flexDirection: "column",
                  display: "flex",
                  marginTop: "8px",

                  "& .txt-edit-note": {
                    width: "100%",
                  },
                  "& .form-control": {
                    marginBottom: "12px", 
                  },
                  "& .wrap-button": {
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-end",
                    "& .btn-cancel": {
                      textTransform: "none",
                      textDecoration: "none",
                      width: "156px",
                      display: "flex",
                      height: "48px",
                      justifyContent: "center",
                      padding: "8px 14px",
                      alignItems: "center",
                      borderRadius: "6px",
                      color: "#000",
                      background: "#fff",
                      fontSize: "14px",
                      fontFamily: "Expletus Sans",
                      border: "1px solid #E8ECF2",
                      fontWeight: "500",
                      marginRight: "16px",
                    },
                    "& .btn-save": {
                      textDecoration: "none",
                      display: "flex",
                      textTransform: "none",
                      height: "48px",
                      width: "156px",
                      justifyContent: "center",
                      padding: "8px 14px",
                      alignItems: "center",
                      background: "#4BA3B7",
                      borderRadius: "6px",
                      color: "#F2F8FC",
                      fontSize: "14px",
                      fontFamily: "Expletus Sans",
                      fontWeight: "500",
                    },
                  },
                },
              },
            },
          },
          "& .product-list-row": {
            flexWrap: "wrap",
            display: "flex",
            margin: "0 -12px",
            [theme.breakpoints.down("sm")]: {
              margin: "0 -8px",
            },
            "& .product-col": {
              padding: "0 12px",
              marginBottom: "24px",
              [theme.breakpoints.down("sm")]: {
                marginBottom: "16px",
                padding: "0 8px",
              },
              "&.col4": {
                flex: "0 0 33.33%",
                maxWidth: "33.33%",
                [theme.breakpoints.down("xs")]: {
                  maxWidth: "50%",
                  flex: "0 0 50%",
                },
              },
            },
            "& .product-inner": {
              borderRadius: "12px",
              padding: "24px 24px 15px",
              border: "1px solid #E8ECF2",
              position: "relative",
              height: "231px",
              [theme.breakpoints.down("sm")]: {
                padding: "24px 16px 16px",
              },
              "& .product-img": {
                textAlign: "center",
                height: "72px",
                width: "72px",
                margin: "0 auto 24px",
                maxWidth: "100%",
              },
              "& .product-details": {
                display: "flex",
                flexDirection: "column",
                justifyContent:" space-between",
                "& .product-info": {
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  fontFamily: "Roboto",
                  color: "#94A3B8",
                  fontSize: "10px",
                  lineHeight: "17px",
                  fontWeight: 400,
                  marginBottom: "4px",
                  letterSpacing: "0.125px",
                  "& .rating": {
                    display: "flex",
                    marginLeft: "10px",
                    alignItems: "center",
                    position: "relative",
                    paddingLeft: "14px",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "6px",
                      paddingLeft: "5px",
                    },
                    "&:before": {
                      content: "''",
                      height: "4px",
                      width: "4px",
                      background: "#E0E0E0",
                      borderRadius: "100%",
                      left: 0,
                      position: "absolute",
                    },
                    "& .star-icon": {
                      color: "#F6A318",
                      height: "10px",
                      width: "10px",
                      marginLeft: "2px",
                      fontSize: "10px",
                    },
                  },
                },
                "& .product-name": {
                  color: "#2B2B2B",
                  fontSize: "14px",
                  fontFamily: "Expletus Sans",
                  lineHeight: "19px",
                  fontWeight: 500,
                  marginBottom: "4px",
                },
                "& .product-desc": {
                  color: "#94A3B8",
                  fontSize: "10px",
                  fontFamily: "Roboto",
                  lineHeight: "17px",
                  fontWeight: 400,
                  letterSpacing: "0.125px",
                  display:"flex",
                  alignItems:"center",
                  marginTop:"6px",
                  "& .arrow-icon":{
                    width:"20px",
                  }
                },
              },
            },
            "& .close-icon": {
              color: "rgba(43, 43, 43, 0.67)",
              right: "16px",
              position: "absolute",
              width: "20px",
              top: "16px",
              fontSize: "20px",
              height: "20px",
              "& .MuiSvgIcon-root": {
                height: "inherit",
                width: "inherit",
                fontSize: "inherit",
                color: "inherit",
              },
            },
          },
          "& .accodion-heading": {
            color: "#000000",
            fontSize: "16px",
            fontFamily: "Roboto",
            lineHeight: "22px",
            fontWeight: 400,
          },
        },
        "& .mergecontact-wrapper": {
          "& .contact-heading": {
            fontSize: "16px",
            color: "#000",
            fontWeight: 400,
            fontFamily: "Expletus Sans",
            lineHeight: "24px",
            marginBottom: "16px",
          },
          "& .merge-list": {
            padding: "0",
            "& .list-item": {
              marginBottom: "16px",
              padding: "0 0 16px",
              borderBottom: "1px solid #ECF1F4",
              "& .MuiListItemText-root": {
                margin: "0",
              },
              "& .list-label": {
                fontSize: "12px",
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontWeight: 400,
                letterSpacing: "0.09px",
                lineHeight: "16px",
                marginBottom: "8px",
              },
              "& .MuiFormControl-root": {
                width: "100%",
                "& .MuiFormControlLabel-root": {
                  justifyContent: "space-between",
                  margin: "0",
                  marginBottom: "4px",
                  "&:last-child": {
                    marginBottom: "0",
                  },
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "Roboto",
                    color: "rgba(43, 43, 43, 0.87)",
                    fontWeight: 400,
                    fontSize: "14px",
                    letterSpacing: "0.105px",
                    lineHeight: "26px",
                  },
                },
                "& .MuiRadio-root": {
                  padding: "0",
                },
              },
            },
          },
        },
        "& .contact-group-list": {
          padding: "0",
          "& .contact-li": {
            justifyContent: "space-between",
            display: "flex",
            position: "relative",
            background: "transparent",
            padding: "16px 20px",
            [theme.breakpoints.down("xs")]: {
              padding: "16px 0",
            },
            "& .contact-left": {
              "& .name": {
                fontSize: "14px",
                color: "#2b2b2b",
                lineHeight: "19px",
                fontWeight: 400,
                fontFamily: "Expletus Sans",
                marginBottom: "4px",
              },
              "& .sub-txt": {
                fontSize: "10px",
                color: "#94A3B8",
                lineHeight: "17px",
                fontFamily: "Roboto",
                letterSpacing: "0.125px",
                fontWeight: 400,
                marginBottom: "0",
              },
            },
            "& .contact-icon": {
              minWidth: "unset",
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          width: "auto",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: "unset",
          padding: "15px 60px",
          margin: "0 -40px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            margin: "0",
            padding: "15px 0",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "19px",
          },
          "& .select-control": {
            "& .MuiSelect-select": {
              fontSize: "12px",
              color: "#2b2b2b",
              opacity: "1",
              paddingRight: "0",
              letterSpacing: "0",
            },
            "& .down-arrow": {
              marginLeft: "6px",
              fontSize: "20px",
            },
          },
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        color: "#2b2b2b",
        letterSpacing: "0",
        lineHeight: "24px",
        fontFamily: "Roboto",
        marginBottom: "27px",
        fontWeight: 400,
        marginTop: "0",
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },
        "& .form-control": {
          "& .MuiInputAdornment-root": {
            "& .MuiSvgIcon-root": {
              fill: "#959595",
              fontSize: "20px",
            },
          },
        },
      },
      "& .form-heading": {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        margin: "0 0 16px",
        letterSpacing: "0",
      },
      "& .profile-team": {
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          textTransform: "uppercase",
          lineHeight: "12px",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto",
        },
        "& .chip-wrapper": {
          "& .MuiChip-root": {
            padding: "5px",
            minHeight: "24px",
          },
        },
      },
      "& .bottom-link": {
        lineHeight: "20px",
        textAlign: "center",
        fontSize: "14px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .modal-form": {
        marginTop: "47px",
      },
      "& .current-team": {
        color: "#94A3B8",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        padding: "17px 16px",
        lineHeight: "20px",
        fontWeight: 500,
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .select-control": {
        "&.multi-select": {
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            alignItems: "center",
            paddingRight: "40px",
            display: "flex",
            "& .select-icon": {
              marginRight: "6px",
            },
          },
          "& .multiple-clear": {
            right: "18px",
            fontSize: "15px",
            position: "absolute",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "&.status-control": {
          padding: "6px 12px",
          background: "rgba(18, 174, 241, 0.07)",
          borderRadius: "12px",
          "& .down-arrow": {
            height: "16px",
            width: "16px",
          },
          "& .MuiSelect-select": {
            fontSize: "12px",
            fontFamily: "Roboto",
            color: "#12AEF1",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            paddingRight: "5px",
          },
        },
        "&.stage-control": {
          "& .MuiSelect-select": {
            fontSize: "10px",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            letterSpacing: "0.125px",
            fontWeight: 400,
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
          },
          "& .down-arrow": {
            height: "16px",
            fontSize: "16px",
            color: "rgba(43, 43, 43, 0.67)",
            width: "16px",
          },
          "& .status": {
            background: "#94A3B8",
            width: "8px",
            height: "8px",
            marginLeft: "6px",
            borderRadius: "100%",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
          },
        },
        "& .select-icon": {
          marginRight: "6px",
          position: "relative",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          paddingTop: "0",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "&.border-hr": {
          marginRight: "24px",
          paddingRight: "24px",
          borderRight: "1px solid #E8ECF2",
        },
      },
      "& .modal-footer": {
        marginTop: "44px",
        "& .half-btn": {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "wrap",
          "& .MuiButton-root": {
            width: "auto",
            marginRight: "10px",
            marginBottom: "0",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        lineHeight: "19px",
        fontSize: "14px",
        width: "100%",
        marginBottom: "17px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .secondary-btn": {
        fontSize: "14px",
        minHeight: "56px",
        marginBottom: "17px",
        lineHeight: "19px",
        width: "100%",
      },
    },
    modalLink: {
      lineHeight: "20px",
      fontSize: "14px",
      color: "#4ba3b7",
      letterSpacing: "0",
      transition: "all 0.5s ease-in-out",
      fontWeight: 500,
      textTransform: "capitalize",
    },
    deleteNoteModal: {
      top: 250,
      position: "absolute",
      bottom: 0,
      right: 30,
      width: 540,
      overflowY: "auto",
      backgroundColor: "#ffffff",
      height: 286,
      borderRadius: "24px",
      maxWidth: "100%",
      padding: "48px",
      "& .heading": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        "& .delete-note-txt": {
          color: "#2B2B2B",
          fontSize: "24px",
          fontFamily: "Expletus Sans",
          lineHeight: "30px",
          fontWeight: "400",
        },
        "& .close-icon": {
          height: "24px",
          color: "#94A3B8",
          width: "24px",
        },
      },
      "& .confirm-txt": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontFamily: "Roboto",
        lineHeight: "26px",
        fontWeight: "400",
        marginTop: "22px",
        letterSpacing: "0.105px",
      },
      "& .wrap-button": {
        flexDirection: "row",
        display: "flex",
        marginTop: "46px",
        justifyContent: "flex-end",
        "& .cancel-btn": {
          display: "flex",
          height: "48px",
          width: "156px",
          padding: "8px 14px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "6px",
          color: "#000",
          background: "#FFF",
          marginRight: "16px",
          border: "1px solid #E8ECF2",
          textTransform: "none",
          fontFamily: "Expletus Sans",
          textDecoration: "none",
          fontSize: "14px",
        },
        "& .delete-btn": {
          display: "flex",
          height: "48px",
          width: "156px",
          padding: "8px 14px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "6px",
          color: "#FFF",
          background: "#4BA3B7",
          textTransform: "none",
          fontFamily: "Expletus Sans",
          textDecoration: "none",
          fontSize: "14px",
        },
      },
    },
    dropDropdownMenuNote: {
      "&.changepriority-dropdown": {
        "& .MuiMenu-paper": {
          marginTop: "0",
        },
      },
      "& .MuiMenu-paper": {
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        minWidth: "164x",
        borderRadius: "12px",
        backgroundColor: "#ffffff",
        marginTop: "12px",
        "& .MuiMenuItem-root": {
          lineHeight: "20px",
          fontSize: "14px",
          letterSpacing: "0",
          padding: "14px 45px",
          color: "#2b2b2b",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          justifyContent: "flex-start",
          display: "flex",
          "& .priority-name": {
            marginRight: "10px",
          },
          "& .priority-icon": {
            marginLeft: "auto",
          },
        },
        "& .MuiList-padding": {
          paddingBottom: "8px",
          paddingTop: "8px",
        },
      },
    },
    chipButton: {
      background: "#ECF1F4",
      fontWeight: 400,
      borderRadius: "6px",
      lineHeight: "12px",
      fontSize: "10px",
      padding: "5px 6px",
      color: "#2B2B2B",
      cursor: "pointer",
      marginBottom: "8px",
      marginRight: "8px",
      height: "auto",
      minHeight: "26px",
      border: "1px solid transparent",
      fontFamily: "Roboto",
      "&.last-child": {
        marginRight: "0",
      },
      "&.secondary-chip": {
        borderColor: "#4BA3B7",
        color: "#4BA3B7",
        backgroundColor: "transparent",
      },
      "&.primary-chip": {
        color: "#FFFFFF",
        backgroundColor: "#4BA3B7",
      },
      "& .MuiChip-avatar": {
        width: "14px",
        height: "14px",
        marginLeft: "0",
        marginRight: "5px",
      },
      "& .MuiChip-label": {
        padding: "0",
      },
    },
    secondaryButton: {
      border: "1px solid #E8ECF2",
      background: "transparent",
      fontSize: "14px",
      borderRadius: "7px",
      color: "#4BA3B7",
      lineHeight: "18px",
      fontWeight: 500,
      textAlign: "center",
      fontFamily: "Expletus Sans",
      maxWidth: "100%",
      textTransform: "unset",
      // minWidth: "198px",
      padding: "12px 10px",
      marginBottom: "0",
      minHeight: "48px",
      textDecoration: "none",
      cursor:"pointer",
      // transition: "all 0.5s ease-in-out",
      "&:hover": {
        color: "#4BA3B7",
        background: "transparent",
      },
    },
    productListViewModal: {
      height: "100vh",
      width: "680px",
      backgroundColor: "#ffffff",
      overflow: "auto",
      position: "relative",
      padding:" 45px 60px",
      "& .modal-heading": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        marginTop: "20px",
        justifyContent: "space-between",
        marginBottom: "48px",        
        "& .title-txt": {
          color: 'rgba(43, 43, 43, 0.87)',
          fontSize: '24px',
          fontFamily: 'Expletus Sans',
          fontStyle: 'normal',
          lineHeight: 'normal',         
          fontWeight: 400,
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "26px",
            fontStyle: "normal",
            letterSpacing: "0.105px",
            fontWeight: "400",
          },
        },
        "& .heading-right": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .heading-icon":{
            marginLeft: "12px",
            "& .pencil-icon": {
              width: "24px",
              height: "24px",
              color: "#94A3B8",
            },
            "& .close-icon": {
              width: "24px",
              height: "24px",
              color: "#94A3B8",
            },
          },
        },
      },
      "& .product-card":{
        display:"flex",
        justifyContent:"space-between",
        alignItems: "center",
        borderBottom: "1px solid #E8ECF2",
        marginBottom:"24px",
        "& .product-img":{
          width:"56px",
          height:"56px",
         },
         "& .card-content":{
           "& .headding":{
            color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
           },
           "& .table-content":{
            "& .table-content-2":{             
              marginBottom:"16px"
             },
             "& .table-content-2 th":{
              color: 'var(--text-Secondary, #94A3B8)',
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              padding:"6px 24px 6px 0",
              textAlign:"start",
              marginBottom:"16px"
             },
             "& .table-content-2 td":{
              color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
              textAlign: 'start',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '16px',
              letterSpacing: '0.09px',
              padding:"0px 24px 0px 0",
              marginBottom:"16px"
             },
           },
           "& .retail-content":{
            display:"flex",
            marginTop:"8px",
            marginBottom:"16px",
            "& .item-quantity":{
              color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              marginRight:"4px",
            },
            "& .retail-price-txt":{
              color: 'var(--text-Secondary, #94A3B8)',
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              display: "flex",            
              alignItems: "center",
            },
           },
         },
         "& .price-txt":{
          "& .price":{
            color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
          },
         },
      }
     
      
    },
    dropdownStyleDeals: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "&.modal-dropdown": {
        marginTop: "0",
      },
      "& .status-item": {
        display: "flex",
        "& .status-name": {
          marginRight: "10px",
          textTransform: "capitalize",
        },
        "& .status": {
          width: "12px",
          height: "12px",
          background: "#94A3B8",
          borderRadius: "100%",
          marginLeft: "auto",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
          "&.sky-blue": {
            background: "#12AEF1",
          },
          "&.orange": {
            background: "#F6A318",
          },
          "&.yellow": {
            background: "#F7E223",
          },
          "&.black": {
            background: "#2B2B2B",
          },
          "&.green": {
            background: "#00BA88",
          },
        },
      },
      "&.multiSelect": {
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        maxWidth: "265px",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "&.assignee-multiSelect": {
          maxWidth: "400px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingRight: "24px",
            paddingLeft: "24px",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "14px",
              color: "#2B2B2B",
              fontWeight: "500",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              borderBottom: "1px solid #ECF1F4",
              paddingBottom: "15px",
              paddingTop: "15px",
              marginBottom: "5px",
              "& .tasklist-link": {
                color: "#C7263E",
                marginLeft: "10px",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                lineHeight: "19px",
                textDecoration: "none",
                whiteSpace: "nowrap",
              },
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-input": {
                lineHeight: "17px",
                fontSize: "13px",
                padding: "16px",
                letterSpacing: "0",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          padding: "14px 24px",
          color: "#2B2B2B",
          "& .priority-name": {
            margin: "0",
            "& .priority-status": {
              marginLeft: "auto",
            },
            "& .MuiTypography-root": {
              display: "flex",
            },
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "& .multiselect-check": {
              display: "block",
            },
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-squareCheck": {
              "& .selected-img": {
                display: "block",
              },
              "& .normal-img": {
                display: "none",
              },
            },
            "& .assignee-detail": {
              "& .assignee-right": {
                "& .assinee-selected": {
                  "& .selected-img": {
                    display: "block",
                  },
                  "& .normal-img": {
                    display: "none",
                  },
                },
              },
            },
          },
          "& .multiselect-check": {
            marginLeft: "5px",
            display: "none",
            maxWidth: "16px",
            flex: "0 0 16px",
          },
          "& .multiselect-squareCheck": {
            flex: "0 0 16px",
            marginLeft: "5px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },     
      "& .MuiMenuItem-root": {
        lineHeight: "20px",
        fontSize: "14px",
        letterSpacing: "0",
        padding: "14px 17px",
        color: "#000000",
        fontWeight: "500",
        display: "flex",
        fontFamily: "Expletus Sans",
        justifyContent: "flex-start",
        whiteSpace: "normal",
        width: "100%",       
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
        "& .select-icon": {
          marginRight: "6px",
        },
      },
      "& .MuiList-padding": {
        paddingBottom: "8px",
        paddingTop: "4px",
      },
      "& .assignee-detail": {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        "& .assignee-left": {
          alignItems: "center",
          display: "flex",
          "& .assignee-info": {
            marginLeft: "10px",
          },
          "& .assignee-profile": {
            height: "39px",
            width: "39px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ECF1F4",
            color: "rgba(43, 43, 43, 0.87)",
            borderRadius: "100%",
            fontFamily: "Roboto",
            fontWeight: 600,
            fontSize: "15.485px",
            letterSpacing: "-0.395px",
            lineHeight: "21.679px",
          },
          "& .assignee-name": {
            lineHeight: "19px",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            color: "#2b2b2b",
            fontWeight: 500,
          },
          "& .assignee-email": {
            lineHeight: "16px",
            fontSize: "12px",
            fontFamily: "Expletus Sans",
            color: "#94A3B8",
            fontWeight: 500,
          },
        },
        "& .assignee-right": {
          marginLeft: "10px",
          "& .assinee-selected": {
            height: "20px",
            width: "20px",
            "& .normal-img": {
              display: "none",
            },
            "& .selected-img": {
              display: "block",
            },
          },
          "& .assinee-unselected": {
            height: "20px",
            width: "20px",
            "& .normal-img": {
              display: "block",
            },
            "& .selected-img": {
              display: "none",
            },
          },
        },
      },
    },
    createDealsBox: {
      // padding: "40px 40px 14px",
      background: "#FFF",
      borderRadius: "12px",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      maxWidth: "591px",
      overflow: "hidden",
      width: "100%",
      maxHeight: "unset",
      marginLeft: "auto",
      display: "inline-block",
      bottom: "95px",
      top: "auto !important",
      position: "fixed",
      right: "32px",
      [theme.breakpoints.down("sm")]: {
        bottom: "65px",
      },
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        left: "0 !important",
        bottom: "0",
        right: "0",
        borderRadius: "0",
        maxWidth: "100%",
        boxShadow: "none",
      },
      "& .deals-heading": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "0",
        padding: "40px 40px 14px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 17px",
        },
        "& .modal-title": {
          fontSize: "18px",
          color: "#2B2B2B",
          lineHeight: "24px",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          marginBottom: "5px",
          letterSpacing: "0",
        },
        "& .sub-txt": {
          lineHeight: "16px",
          fontSize: "12px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          letterSpacing: "0.09px",
          fontWeight: 400,
        },
        "& .heading-right": {
          marginLeft: "10px",
          display: "flex",
          "& .heading-icon": {
            width:"20px",
            marginRight: "24px",
            height:"20px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .task-description": {
        overflowY: "auto",
        padding: "10px 40px",
        height: "calc(100vh - 315px)",
        [theme.breakpoints.down("sm")]: {
          height: "calc(100vh - 355px)",
        },
        [theme.breakpoints.down("xs")]: {
          height: "calc(100vh - 193px)",
          padding: "10px 24px",
        },
        "& .task-form":{
          "& .MuiFormControl-root":{
            "& .MuiFormLabel-root.MuiInputLabel-shrink":{
              "& span":{
                top:"0",
              }
            }
          }
        }
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19px",
        letterSpacing: "0",
        marginBottom: "16px",
        fontFamily: "Expletus Sans",
      },
      "& .form-info-wrapper": {
        marginBottom: "16px",
        "&:last-child": {
          marginBottom: "0",
        },
      },
      "& .form-row": {
        flexWrap: "wrap",
        display: "flex",
        margin: "0 -8px",
        "&:last-child": {
          "& .form-control": {
            marginBottom: "0",
          },
        },
        "& .form-col": {
          flex: "0 0 100%",
          maxWidth: "100%",
          padding: "0 8px",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          "& .form-control": {
            marginBottom: "16px",
          },
          "& .count-selected":{
            color: 'var(--Task-Completed-Color, #2B2B2B)',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 400,
            whiteSpace: 'nowrap',
          }
        },
      },
      "& .attachlist-ul": {
        padding: "0",
        "& .attachlist-li": {
          paddingLeft: "0",
          paddingTop: "15px",
          paddingBottom: "15px",
          borderBottom: "1px solid #E8ECF2",
          background: "transparent",
          "&:last-child": {
            borderBottom: "0",
          },
          "& .attachlist-label": {
            color: "#2B2B2B",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            margin: "0",
            "& .MuiTypography-root": {
              font: "inherit",
              letterSpacing: "inherit",
            },
          },
          "& .attachlist-icon": {
            minWidth: "unset",
            marginRight: "15px",
          },
        },
      },
      "& .multiselect-autocomplete": {
        marginBottom: "16px",
        "&.MuiAutocomplete-hasPopupIcon": {
          "&.MuiAutocomplete-hasClearIcon": {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
              paddingRight: "85px",
            },
          },
        },
        "& .MuiOutlinedInput-root": {
          paddingLeft: "16px",
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
              borderWidth: "1px",
            },
          },
        },
        "& .MuiInputAdornment-root": {
          fontFamily: "Roboto",
          color: "#94A3B8",
          fontSize: "14px",
          lineHeight: "29px",
          height: "auto",
          fontWeight: 400,
        },
        "& .start-wrapper": {
          display: "flex",
        },
        "& .end-icon": {
          top: "calc(50% - 12px)",
          position: "absolute",
          marginLeft: "0",
          right: "60px",
        },
        "& .MuiChip-root": {
          borderRadius: "6px",
          backgroundColor: "#ECF1F4",
          height: "auto",
          padding: "6px 8px",
          color: "#4F4F4F",
          fontSize: "13px",
          fontWeight: 400,
          fontFamily: "Roboto",
          margin: "0 5px 5px 0",
          lineHeight: "17px",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            height: "17px",
            width: "17px",
            margin: "0",
          },
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiIconButton-root": {
            color: "#94A3B8",
          },
        },
      },
      "& .inventory-wrapper": {
        marginBottom: "12px",
        "& .links-wrapper": {
          flexWrap: "wrap",
          display: "flex",
        },
        "& .inventory-link": {
          borderRadius: "15px",
          padding: "5px 12px",
          border: "1px solid #E8ECF2",
          fontFamily: "Roboto",
          color: "rgba(43, 43, 43, 0.87)",
          fontWeight: 400,
          fontSize: "12px",
          letterSpacing: "0.09px",
          lineHeight: "16px",
          textDecoration: "none",
          marginBottom: "12px",
          marginRight: "12px",
        },
        "& .items-list-wrapper": {
          "& .items-list": {
            marginBottom: "16px",
            padding: "24px",
            border: "1px solid #E8ECF2",
            borderRadius: "6px",
            "& .items-title": {
              justifyContent: "space-between",
              display: "flex",
              marginBottom: "5px",
            },
            "& .title-icon": {
              "& .MuiSvgIcon-root": {
                color: "#94A3B8",
                "&.close-icon": {
                  fontSize: "20px",
                },
              },
            },
            "& .primary-txt": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& span": {
                color: "#2B2B2B",
              },
            },
          },
        },
      },
      "& .createtask-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 40px 40px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 55px",
        },
        "& .full-btn": {
          width: "100%",
          "& .MuiButton-root": {
            width: "100%",
          },
        },
        "& .primary-btn": {
          width: "100%",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Roboto",
          fontWeight: 400,
          lineHeight: "19px",
          fontSize: "14px",
          color: "#94A3B8",
          letterSpacing: "0.1px",
          width: "calc(100% - 46px)",
          display: "flex",
          "&.MuiFormLabel-filled": {
            width: "auto",
          },
        },
        "& .MuiSelect-select": {
          letterSpacing: "0",
          fontSize: "14px",
          color: "#000000",
          fontWeight: "400",
          opacity: "1",
          display: "flex",
          fontFamily: "Roboto",
          alignItems: "center",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .status-name": {
            fontSize: "14px",
            color: "#2B2B2B",
            lineHeight: "26px",
            fontWeight: 400,
            letterSpacing: "0.105px",
          },
          "& .status": {
            height: "12px",
            width: "12px",
            borderRadius: "100%",
            background: "#94A3B8",
            marginLeft: "10px",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
            "&.yellow": {
              background: "#F7E223",
            },
            "&.green": {
              background: "#00BA88",
            },
            "&.orange": {
              background: "#F6A318",
            },
            "&.sky-blue": {
              background: "#12AEF1",
            },

            "&.black": {
              background: "#2B2B2B",
            },
          },
        },
        "& .MuiSvgIcon-root": {
          color: "#94A3B8",
          fontSize: "20px",
          marginRight: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
    },
    fileUploadWrapper: {
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "& .file-heading": {
        fontSize: "12px",
        lineHeight: "16px",
        margin: "0 0 7px",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.09px",
        marginBottom: "0",
        display: "flex",
        alignItems: "center",
        "& .attach-icon-deals": {
          marginRight: "4px",
        },
      },
      "& .browse-link-file": {
        color: "#4ba3b7",
        cursor: "pointer",
        fontWeight: 700,
        marginLeft: "3px",
      },
    },
    primaryButton: {
      borderRadius: "6px",
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      textAlign: "center",
      fontWeight: 500,
      maxWidth: "100%",
      fontFamily: "Expletus Sans",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      transition: "all 0.5s ease-in-out",
      marginBottom: "0",
      textTransform: "capitalize",
      cursor:"pointer",
      "&:hover": {
        color: "#ffffff",
        background: "#4ba3b7",
      },
    },
    chooseModalBox: {
      display: "flex",
      justifyContent: "flex-end",
    },
    dealOwnersModal: {
      width: "680px",
      backgroundColor: "#ffffff",
      height: "100vh",
      overflow: "auto",
      position: "relative",
      // padding: "0px 60px",
      "& .modal-heading": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        marginTop: "80px",
        justifyContent: "space-between",
        padding: "0px 60px",
        "& .gr-heading-left": {
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          "& .nav-text": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            fontStyle: "normal",
            marginLeft: "10px",
            lineHeight: "normal",
          },
        },
        "& .heading-left": {
          "& .modal-title": {
            fontFamily: "Expletus Sans",
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "24px",
            fontStyle: "normal",
            lineHeight: "30px",
            fontWeight: "400",
          },
          "& .sub-txt": {
            fontFamily: "Roboto",
            color: "var(--text-secondary, #94A3B8)",
            fontSize: "14px",
            fontWeight: "400",
            fontStyle: "normal",
            letterSpacing: "0.105px",
            lineHeight: "26px",
          },
        },
        "& .heading-right": {
          flexDirection: "row",
          dispplay: "flex",
          alignItems: "center",
          "& .plus-icon": {
            height: "24px",
            width: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            width: "24px",
            color: "#94A3B8",
            height: "24px",
            marginLeft: "16px",
          },
        },
      },
      "& .modal-title-box": {
        padding: "0px 60px",
        margin: "45px 0px",
        "& .title-txt": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
        },
        "& .sub-title-txt": {
          color: "var(--task-completed-color, #2B2B2B)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .search-box": {
        margin: "24px 0",
        padding: "0px 60px",
      },
      "& .assign-owner": {
        "& .selected-owner-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 60px 4px",
          paddingBottom:"15px",
          // borderBottom:"1px solid #ECF1F4",
          "& .selected-owner-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .remove-contact-txt":{
            color: "#c7263e",
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            lineHeight: "normal",
            textTransform: "none",
          },
        },
        "& .selected-owner": {
          "& .select-owner-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "var(--background-background-light, #F2F8FC)",
            cursor: "pointer",
            padding: "8px 60px",
            borderTop:"1px solid #ECF1F4",
            borderBottom:"1px solid #ECF1F4",
            "& .avatar": {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "39px",
              height: "39px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              borderRadius: "19.5px",
              "& .short-name": {
                fontFamily: "Roboto",
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontSize: "15.485px",
                fontWeight: "600",
                fontStyle: "normal",
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
            },
            "& .owner-info": {
              flexDirection: "column",
              display: "flex",
              marginLeft: "12px",
              "& .name": {
                fontFamily: "Expletus Sans",
                color: "var(--task-completed-color, #2B2B2B)",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "normal",
              },
              "& .email": {
                fontFamily: "Expletus Sans",
                color: "var(--text-secondary, #94A3B8)",
                fontStyle: "normal",
                fontSize: "12px",
                lineHeight: "normal",
                fontWeight: "500",
              },
              "& .departments": {
                flexWrap: "wrap",
                display: "flex",
                "& .dep-item": {
                  padding: "6px",
                  display: "flex",
                  marginRight: "16px",
                  gap: "8px",
                  alignItems: "flex-start",
                  background: "var(--background-background-medium, #ECF1F4)",
                  borderRadius: "6px",
                  "& .dep-name": {
                    fontFamily: "Roboto",
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontSize: "10px",
                    fontWeight: "400",
                    fontStyle: "normal",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              right: 84,
              position: "absolute",
              color: "#4BA3B7",
            },
          },
        },
        "& .selected-contact":{
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#FFF",
            padding: "0 0 15px",
            margin:"0 60px 15px",
            cursor: "pointer",
            borderBottom:"1px solid #ECF1F4",
            "& .avatar": {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height: "39px",
              borderRadius: "19.5px",
              width: "39px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "15.485px",
                fontWeight: "600",
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
            },
            "& .contact-info": {
              flexDirection: "column",
              display: "flex",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "normal",
              },
              "& .departments": {
                flexWrap: "wrap",
                display: "flex",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  alignItems: "flex-start",
                  marginRight: "16px",
                  borderRadius: "6px",
                  gap: "8px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontSize: "10px",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontStyle: "normal",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              color: "#4BA3B7",
              right: 84,
            },
          },

        },
      },
      "& .un-assign-owner": {
        marginTop: "20px",
        padding: "0 60px",
        "& .top-title": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          marginTop:"24px",
        },
        "& .top-list": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
          "& .count-owner": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            opacity:"0.5",
          },
          "& .select-control": {
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                right: "18px",
                fontSize: "15px",
                zIndex: 1,
                top: "3px",
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              letterSpacing: "0.1px",
              fontSize: "12px",
              opacity: "0.671",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingTop: "0",
              paddingBottom: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .un-assign-owner-list": {
          paddingTop: "16px",
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 0px",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "39px",
              height: "39px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              borderRadius: "19.5px",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontSize: "15.485px",
                fontFamily: "Roboto",
                fontWeight: "600",
                fontStyle: "normal",
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
            },
            "& .owner-info": {
              flexDirection: "column",
              display: "flex",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                fontFamily: "Expletus Sans",
                color: "var(--text-secondary, #94A3B8)",
                fontStyle: "normal",
                fontSize: "12px",
                lineHeight: "normal",
                fontWeight: "500",
              },
              "& .departments": {
                flexWrap: "wrap",
                display: "flex",
                "& .dep-item": {
                  display: "flex",
                  marginRight: "16px",
                  padding: "6px",
                  gap: "8px",
                  alignItems: "flex-start",
                  background: "var(--background-background-medium, #ECF1F4)",
                  borderRadius: "6px",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontSize: "10px",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontStyle: "normal",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              right: 60,
              position: "absolute",
              color: "#4BA3B7",
            },
          },
          "& .owner-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0 0 15px",
            marginBottom:"10px",
            borderBottom:"1px solid #ECF1F4",
            cursor: "pointer",
            marginTop:"24px",
            "&:last-child":{
              marginBottom:"0",
            },
            "& .avatar": {
              alignItems: "center",
              display: "flex",
              height: "39px",
              justifyContent: "center",
              width: "39px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              borderRadius: "19.5px",
              "& .short-name": {
                fontFamily: "Roboto",
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontStyle: "normal",
                fontSize: "15.485px",
                lineHeight: "21.679px",
                fontWeight: "600",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              flexDirection: "column",
              display: "flex",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "normal",
              },
              "& .email": {
                fontFamily: "Expletus Sans",
                color: "var(--text-secondary, #94A3B8)",
                fontStyle: "normal",
                fontSize: "12px",
                lineHeight: "normal",
                fontWeight: "500",
              },
              "& .departments": {
                flexWrap: "wrap",
                display: "flex",
                "& .dep-item": {
                  display: "flex",
                  marginRight: "16px",
                  padding: "6px",
                  gap: "8px",
                  alignItems: "flex-start",
                  background: "var(--background-background-medium, #ECF1F4)",
                  borderRadius: "6px",
                  "& .dep-name": {
                    fontFamily: "Roboto",
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontStyle: "normal",
                    fontSize: "10px",
                    lineHeight: "normal",
                    fontWeight: "400",
                  },
                },
              },
            },
            "& .circleCheck": {
              color: "#E8ECF2",
              position: "absolute",
              right: 60,
            },
          },
        },
        "& .bottom-button": {
          bottom: "24px",
          width: "100%",
          textTransform: "none",
          textDecoration: "none",
          color: "white",
          display: "flex",
          height: "48px",
          padding: "8px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
        },
      },
    },
    modalCenterDialogBox: {
      top: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      left: "50%",
      width: 580,
      padding: "48px",
      backgroundColor: "#ffffff",
      borderRadius: "24px",
      // [theme.breakpoints.down("md")]: {
      //   padding: "35px 35px 20px",
      //   width: 520,
      // },
      // [theme.breakpoints.down("sm")]: {
      //   padding: "25px 25px 20px",
      //   maxWidth: "90%",
      // },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "327px",
        padding: "24px",
        width: "90%",
        backgroundColor: "#F2F8FC",
        borderRadius: "12px",
      },
      "& .modal-heading": {
        justifyContent: "space-between",
        display: "flex",
        marginBottom: "42px",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "23px",
        },
      },
      "& .modal-title": {
        fontSize: "24px",
        color: "#2B2B2B",
        lineHeight: "30px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        fontWeight: 400,
        // [theme.breakpoints.down("md")]: {
        //   fontSize: "20px",
        //   lineHeight: "24px",
        // },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
      "& .modal-desc": {
        letterSpacing: "0.0075em",
        fontSize: "14px",
        color: "#2b2b2b",
        lineHeight: "26px",
        fontWeight: 400,
        fontFamily: "Roboto",
        [theme.breakpoints.down("xs")]: {
          lineHeight: "17px",
          fontSize: "13px",
        },
      },
      "& .select-outer": {
        marginBottom: "24px",
        width: "100%",
        "& .MuiInputLabel-formControl": {
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          letterSpacing: "0.1px",
          lineHeight: "19px",
          color: "#94A3B8",
          width: "calc(100% - 46px)",
          display: "flex",
          "&.MuiFormLabel-filled": {
            width: "auto",
            fontSize: "12px",
            color: "rgba(43,43,43,0.87)",
            fontFamily: "Roboto",
            lineHeight: "16px",
            fontWeight: 400,
            letterSpacing: "0.0075em",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          color: "#000000",
          letterSpacing: "0",
          opacity: "1",
          fontFamily: "Roboto",
          fontWeight: "400",
          alignItems: "center",
          display: "flex",
          paddingRight: "45px",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .status-name": {
            fontSize: "14px",
            color: "#2B2B2B",
            fontWeight: 400,
            letterSpacing: "normal",
            lineHeight: "19px",
          },
          "& .status": {
            width: "12px",
            background: "#94A3B8",
            height: "12px",
            borderRadius: "100%",
            marginLeft: "10px",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
            "&.sky-blue": {
              background: "#12AEF1",
            },
            "&.orange": {
              background: "#F6A318",
            },
            "&.yellow": {
              background: "#F7E223",
            },
            "&.green": {
              background: "#00BA88",
            },
            "&.black": {
              background: "#2B2B2B",
            },
          },
        },        
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiSvgIcon-root": {
          color: "#94A3B8",
          fontSize: "20px",
          right: "16px",
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            lineHeight: "23px",
            fontSize: "18px",
            marginLeft: "auto",
          },
        },
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },        
        "& .form-row": {
          flexWrap: "wrap",
          display: "flex",
          margin: "0 -8px",
          "& .form-col": {
            flex: "0 0 100%",
            padding: "0 8px",
            maxWidth: "100%",
            "&.col6": {
              maxWidth: "50%",
              flex: "0 0 50%",
            },
            "& .form-control": {
              marginBottom: "24px",
            },
          },
        },
      },
      "& .close-icon": {
        lineHeight: "0",
        color: "rgba(43, 43, 43, 0.87)",
      },
      "& .modal-footer": {
        justifyContent: "flex-end",
        display: "flex",
        marginTop: "32px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "24px",
        },
        "& .primary-btn, & .secondary-btn ": {
          minWidth: "157px",
          width: "auto",
          [theme.breakpoints.down("xs")]: {
            padding: "4px 6px",
            minWidth: "70px",
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        fontSize: "14px",
        minHeight: "48px",
        marginBottom: "0",
        lineHeight: "19px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
        },
      },
      "& .secondary-btn": {
        fontSize: "14px",
        minHeight: "48px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          border: "0",
          minHeight: "30px",
          color: "#4BA3B7",
        },
      },
    },
  });
// Customizable Area End
