// Customizable Area Start
import React from "react";
import { Box, TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomPwdRequirement from "./CustomPwdRequirement.web";
interface CustomInputProps {
  value?: string | number | null;
  inputID?: string;
  customClass?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPassChange?: (isValid: boolean) => void;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  fullWidth?:boolean;
  type?: string;
  errors?: string | React.ReactNode;
  success?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
  isRequired?: boolean;
  rows?: number;
  profile?: boolean;
  onBlur?: (event: React.FocusEvent) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  onKeyDown?:(event: React.KeyboardEvent) => void;
  onFocus?: (event: React.FocusEvent) => void;
  showRequirements?: boolean;
  helperTextClass?: string;
  passwordValue?: string;
  showError?: boolean;
  refTest?: any;
  name?: string;
  isMultiline? :boolean;
  select?: boolean;
  selectOption?: any;
  onClick?: (event: any) => void;
  successClass?:any;
  autoComplete?:string;
  isDealForm?:boolean;
}
const useStyles = makeStyles((theme) => ({
  formControl: {
    "&.success-class":{
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#4ba3b7",
        fontFamily: "Roboto",
        fontWeight: "500",
      },
      "& .MuiFormLabel-filled ~ .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":{
        borderColor: "#4ba3b7",
      },
    },
    "&.font-roboto" : {
    "& .MuiFormLabel-root": {
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    "& .MuiInputAdornment-root": {
      color: "#2B2B2B",
  },
  "& .MuiInputAdornment-positionEnd": {
    color: "#94A3B8",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    opacity: "1",        
  }
  },
    marginBottom: "24px",
    "& .PrivateNotchedOutline-legendNotched-127 ":{     
      width: "fit-content",
      maxWidth: "70px",
      transition:" max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",     
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(25,25,25,0.32)",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },  
    "& .MuiFormLabel-root": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#94a3b8",
      letterSpacing: "0.0075em",
      fontFamily: "Expletus Sans",
      fontWeight: 500,
      display: "flex",
      width: "calc(100% - 28px)",
      "& .MuiInputLabel-asterisk": {
        marginLeft: "auto",
        fontSize: "18px",
        lineHeight: "23px",
        color: "#e86577",
        top: "-27px",
        position: "relative",
      },
      "&.MuiInputLabel-shrink": {
        width: "auto",
        top: "0",
      },
      "&.Mui-error": {
        color: "#C7263E",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      "&.contacts-search":{
       
        "& .MuiOutlinedInput-input": {
          "&::-webkit-input-placeholder": { 
            color: "#2b2b2b",
            opacity: "0.37",
            fontFamily: "Expletus Sans",
          },
          "&::-moz-placeholder": { 
            color: "#2b2b2b",
            opacity: "0.37",
            fontFamily: "Expletus Sans",
          },
          "&:-ms-input-placeholder": {
            color: "#2b2b2b",
            opacity: "0.37",
            fontFamily: "Expletus Sans",
          },
          "&:-moz-placeholder": {
            color: "#2b2b2b",
            opacity: "0.37",
            fontFamily: "Expletus Sans",
          }
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e8ecf2",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "23px",
      },
      "&.MuiOutlinedInput-multiline": {
        padding: "0",
      },
      
      "& .MuiInputAdornment-root": {
        color: "#94A3B8",
        "& .MuiTypography-root": {
          font: "inherit",
          color: "#94a3b8",
          letterSpacing: "normal",
        }
    },
      "& .MuiInputAdornment-positionEnd": {
        color: "#94A3B8",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        opacity: "1",        
      }
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e8ecf2",
      borderWidth: "1px",
      fontSize: "12px",
    },
    
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontFamily: "Roboto",
      fontWeight: 400,
      letterSpacing: "0.0075em",
      padding: "19px 16px",
      height: "unset",
      "&.MuiOutlinedInput-inputAdornedStart": {
        paddingLeft: "0",
      },
      "&::-webkit-input-placeholder": { 
        color: "#94a3b8",
        opacity: "1",
      },
      "&::-moz-placeholder": { 
        color: "#94a3b8",
        opacity: "1",
      },
      "&:-ms-input-placeholder": {
        color: "#94a3b8",
        opacity: "1",
      },
      "&:-moz-placeholder": {
        color: "#94a3b8",
        opacity: "1",
      }
    },
    "& .MuiInputLabel-outlined": {
      color: "#94a3b8",
    },
    "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#94A3B8",
      letterSpacing: "0.0075em",
      fontFamily: "Roboto",
      fontWeight: "400",
      width: "auto",
      "&.MuiInputLabel-shrink": {
        "& .MuiInputLabel-asterisk": {
          top: "0",
        }
      },
      "& ~.MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
          fontSize: "12px",        
        },
      },
      "&.Mui-error": {
        // color: "rgba(43,43,43,0.87)",
        color: "#C7263E",
      },
    },
    "& .MuiInputLabel-outlined.Mui-focused ": {
      "& ~.MuiOutlinedInput-root.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#C7263E",
        },
      },
    },
    "& .MuiOutlinedInput-root.Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#C7263E",
      },
    },
    "& .MuiFormLabel-filled ~.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C7263E",
    },
    "& .visibility-icon": {
      color: "#94a3b8",
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
    "& .close-icon": {
      color: "#94a3b8",
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
    "& .Mui-error .visibility-icon": {
      color: "#C7263E !important",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "12px",
      letterSpacing: "0.0075em",
      color: "#00BA88",
      fontFamily: "Roboto",
      fontWeight: "400",
      "&.Mui-error": {
        color: "#C7263E",
      },
      "&.Mui-default": {
        color: "#2B2B2B",
      },
      "&.info-text": {
        color: "#94A3B8",
      },
    },
    "& .secondary-link": {
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "20px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textDecoration: "none",
    },
    "& .assignee-list": {
      display: "flex",
      alignItems: "center",
      "& .assignee-bubble": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#ECF1F4",
        width: "24px",
        height: "24px",
        borderRadius: "100%",
        border: "2px solid #FFF",
        color: "rgba(43, 43, 43, 0.87)",
        fontSize: "9.586px",
        fontFamily: "Roboto",
        fontWeight: 600,
        lineHeight: "13.42px",
        letterSpacing: "-0.244px",
        position: "relative",
        marginLeft: "-4px",
        "&:first-child": {
          marginLeft: "0",
        },
      },
    },
  },
}));

const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: "red",
  },
  root1: {
    color: "green",
  },
}));
const CustomInputContent = (props: CustomInputProps) => {
  const {
    value,
    inputID,
    customClass,
    errors,
    success,
    isRequired,
    onChange,
    label,
    placeholder,
    startAdornment,
    endAdornment,
    showRequirements,
    type,
    onPassChange,
    helperTextClass,
    disabled,
    onBlur,
    passwordValue,
    showError,
    refTest,
    onFocus,
    name,
    autoFocus,
    fullWidth,
    onKeyDown,
    isMultiline,
    rows,
    select,
    selectOption,
    onClick,
    successClass,
    autoComplete,
    isDealForm,
  } = props;
  const classes = useStyles();
  let password, dealFormClass;
  if(isDealForm){
    dealFormClass = 'font-roboto';
  }

if (passwordValue != null) {
  password = passwordValue;
} else if (value != null) {
  password = value.toString();
} else {
  password = "";
}

  return (
    <Box className={`form-control ${classes.formControl} custom-input-wrapper ${successClass} ${dealFormClass}`}>
      <TextField
        required={isRequired}
        id={inputID}
        type={type}
        name={name}
        multiline={isMultiline}
        inputRef={refTest}
        error={(errors != "" && errors != undefined) || showError}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={onChange}
        helperText={success != "" && success!=undefined ? success : (errors ?? "")}
        InputProps={{
          endAdornment: endAdornment,
          startAdornment: startAdornment,
          className: customClass,
          ...(type === "number" && {
            onKeyDown: (e) => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
              }
            }
          }),
        }}
        FormHelperTextProps={{
          error: errors != "" && errors != undefined && !helperTextClass,
          classes: { root: helperTextClass },
        }}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus==true?true:false}
        fullWidth={fullWidth==true?true:false}
        onKeyDown={onKeyDown}
        minRows={rows}
        onClick={onClick}
        select={select ? true : false}
        autoComplete={autoComplete}
      >
        {select && selectOption && selectOption.length > 0 && selectOption.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
      {showRequirements && (
        <CustomPwdRequirement
          password={password}
          onPassChange={onPassChange}
        />
      )}
      
    </Box>
  );
};

export default CustomInputContent;
// Customizable Area End
