Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.updateApiMethod = "PUT";
exports.editApiMethod = "PaTCH";
exports.txtDelete = "Delete";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "InventoryManagement";
exports.labelBodyText = "InventoryManagement Body";
exports.InventoryEndPoint = "bx_block_inventory_management/products";
exports.productsDropdownAPIEndPoint = "bx_block_inventory_management/get_product_dropdowns?dropdown_name=";
exports.addItemAPIEndPoint = "catalogue/catalogues";
exports.certificationAPIEndpoint = "bx_block_catalogue/certifications";
exports.filterCatalogue="bx_block_inventory_management/filter_catalogue";
exports.countryAPIEndpoint = "bx_block_catalogue/all_country_names";
exports.countryRegionsAPIEndpoint = "bx_block_catalogue/regions_by_country?country_name=";
exports.filterCountryRegionsAPIEndpoint = "bx_block_catalogue/regions_by_country?";
exports.regionSubregionAPIEndpoint = "bx_block_catalogue/sub_regions_by_country?country_name=";
exports.filterRegionSubregionAPIEndpoint = "bx_block_catalogue/sub_regions_by_country?";
exports.getInventoryAPIEndPoint="bx_block_inventory_management/products";
exports.cloneAPIEndPoint="bx_block_inventory_management/clone_items";
exports.removeItemAPIEndPoint="bx_block_inventory_management/remove_items";
exports.generatePDFAPIEndPoint="bx_block_inventory_management/generate_pdf";
exports.getPriceLabelAPIEndPoint="bx_block_catalogue/prices";
exports.getTrophyNameAPIEndPoint="bx_block_inventory_management/get_trophy_names";
exports.getNumberFiltersAPIEndPoint="bx_block_inventory_management/get_number_filters"
exports.sendToContactEndPoint="/bx_block_contactslist/contact_products/send_email";



exports.btnExampleTitle = "CLICK ME";
exports.ShowAsOption1 = "Retail Price";
exports.ShowAsOption2 = "Wholesale Price";
exports.ShowAsOption3 = "Custom Price";
exports.ShowAsOption4 = "New Price Label";
exports.submitButton = "Submit";
exports.addPrice = "Add Price";
exports.taxtext = "Tax excluded";
exports.cancelButton = "Cancel";
exports.createButton = "Create";
exports.taxincludedText = "(Tax included)";
exports.itemprice = "$236.00";
exports.priceTitle = "Price & Tax";
exports.priceTaxTitle = "Price and Tax";

exports.InventoryHeading = "Inventory";
exports.InventorySubtitle = "View and manage all of your company’s inventory items.";
exports.InventorySubtitleMobile = "View and manage all of your company’s inventor...";
exports.exportInventoryBtnTitle = "Export Inventory";
exports.settingBtnTitle = "Settings";
exports.noInventoryTxt = "No Inventory";
exports.inventorySubtxt1 = "There are no items added to your inventory.";
exports.inventorySubtxt2 = "Click the button to begin.";
exports.addBtn = "New Item";
exports.filtersTxt = "Filters";
exports.clearFilterLink = "Clear all";
exports.productTxt = "Products";
exports.filterSearchPlaceholder = "Start typing to search...";
exports.SortTxt = "Sort by";
exports.ShowasTxt = "Show as";
exports.SortOption1 = "Recently added";
exports.SortOption2 = "Price - High to Low";
exports.SortOption3 = "Price - Low to High";
exports.SortOption4 = "A to Z";
exports.SortOption5 = "Z to A";
exports.addManuallyTxt = "Add Manually";
exports.bulkUploadTxt = "Bulk Upload";
exports.procedureTxt = "Producer";
exports.moreOptionCategory = "more";
exports.brandTxt = "Brand";
exports.filterText = "Filters";
exports.searchPlaceholder = "Search here";
exports.itemPrice = '$54.00';
exports.itemTitle = "Petite cave";
exports.itemInfo = "Sauvignon Blanc, 750 ml";
exports.retailPriceText = "Retail Price";
exports.WholesalePriceText = "Wholesale Price";
exports.customPriceText = "Custom Price";
exports.year = "2010";
exports.ratings="4.5";
exports.catergoryName1 = "Products";
exports.catergoryName2 = "Tools";
exports.catergoryName3 = "Assets";
exports.catergoryName4 = "Consumables";
exports.wineText = "Wine";
exports.beerText = "Beer";
exports.spiritText = "Spirit";
exports.otherText = "Other Product";
exports.machineText = "Machinery";
exports.hardwareText = "Hardware";
exports.landText = "Land";
exports.vesselsText = "Vessels";
exports.otherDetails = "Describe your wine through all senses";
exports.FertilizersText = "Fertilizers";
exports.PhytoProductsText = "Phyto-product";
exports.ChemicalsText = "Chemicals";
exports.addInventoryTitle = "Please choose a category";
exports.addInventorySubTitle = "Confirm which metrics you want in the app";
exports.productDescription = "Maintain an accurate inventory of what's available to sell and connect this with deals to ensure you're meeting customer demand.";
exports.toolsdescription = "Catalogue your essential tools and equipment and add them to tasks to track when and where they are utilized."
exports.assetsDescription = "Record your vital assets such as land plots, barrels, and tanks. This helps you manage when and how these assets are utilized.";
exports.consumableDescription = "Track the items you consume to ensure you're never caught short.";
exports.stepLabel1 = "Step 1";
exports.stepLabel2 = "Step 2";
exports.addingItemSteps = ["Choose Category", "Basic Info", "Location", "Details", "Tasting Notes", "Allergens"];
exports.addingItem3Steps = ["Choose Category", "Basic Info", "Additional Information"];
exports.addingTitle = "Basic Info";
exports.addingSubtext = "Enter the basic information about your item.";
exports.locationTitle = "Location";
exports.detailsTitle = "Details";
exports.locationSubtext = "Include specifics on where this";
exports.originText = "originated.";
exports.detailSubtext = "Provide additional details about this product.";
exports.beerAddingSteps= ["Choose Category", "Basic Info", "Location", "Details", "More Details", "Tasting Notes", "Allergens"];
exports.otherProdAddingSteps= ["Choose Category", "Basic Info", "Location", "Details", "Allergens"];
exports.additionalinfoTitle = "Additional Information";
exports.additionalinfoTitleSubtext = "Enter additional information about this";

exports.continueButton = "Continue";
exports.backButton = "Back";
exports.nextButton = "Next Step";
exports.finishStepMsg = "Wine Added Successfully";
exports.deafultStep = "Unknown step";
exports.productNamePlaceHolder = "Name";
exports.producerPlaceHolder = "Producer";
exports.branPlaceHolder = "Brand";
exports.varietyPlaceHolder = "Grape Variety";
exports.varietyperPlaceHolder = "Grape Variety %";
exports.rawMaterialPlaceHolder = "Raw Material";
exports.rawMaterialPerPlaceHolder = "Raw Material %";
exports.complementPlaceHolder = "Name Complement";
exports.colorPlaceHolder = "Color";
exports.dosagePlaceHolder = "Dosage";
exports.quantityPlaceHolder = "Quantity";
exports.appellationTypeLabel ="Appellation Type";
exports.appellationLabel ="Appellation";
exports.countryLabel = "Country";
exports.regionLabel = "Region";
exports.subRegionLabel = 'Sub region';
exports.certificateLabel = 'Certificate';
exports.USvalue = "US";
exports.modalTitle = "Choose Age / Vintage";
exports.modalSubText = "Please fill the below details.";
exports.ageText = "Age";
exports.ageCapitalText = "AGE";
exports.vintageText = "Vintage";
exports.vintageCapitalText = "VINTAGE";
exports.whiteColor = "White";
exports.redColor = "Red";
exports.roseColor = "Rose";
exports.GtinPlaceholder = "GTIN";
exports.skuPlaceholder = "SKU";
exports.ibuPlaceholder = "IBU";
exports.srmPlaceholder = "SRM";
exports.ogPlaceholder = "OG";
exports.fgPlaceholder = "FG";
exports.ebcPlaceholder = "EBC";
exports.certificationPlaceholder ="Certification";
exports.agingProcessPlaceholder ="Aging Process";
exports.prodProcessPlaceholder ="Production Process";
exports.alcoholPlaceholder = "Alcohol";
exports.volumePlaceholder ="Container with Volume";
exports.agingPlaceholder = "Aging Potential";
exports.descPlaceholder ="Short Description";
exports.priceAndTaxText = "+ Add Price and Tax";
exports.createPriceText = "New Price Label";
exports.trophyText = "+ Add Trophy";
exports.addAnotherTrophyText = "+ Add Another Trophy";
exports.gtinDes= "Global Trade Item Number (GTIN)";
exports.submitButton = "Submit";
exports.certificationTitile = "Choose Certification";
exports.tastingTitle = "Tasting Notes";
exports.tastingSubtext = "Describe your %% through all senses";
exports.openingTimePlaceholder = "Opening Time";
exports.tempPlacholder = "Temperature (°C)";
exports.tempTitle = "Temperature";
exports.eyeLabel = "Eye";
exports.noseLabel = "Nose";
exports.aromaLabel = "Main Aromas";
exports.flavoursLabel = "Flavours";
exports.mouthLabel = "Mouth";
exports.foodLabel = "Food Pairing";
exports.trophyButton = "Add Trophy";
exports.trophyModalTitle = "Trophy Details";
exports.uploadTitle = "Upload Trophy Image";
exports.resultLabel = "Trophy Result";
exports.trophyYearPlacehoder = "Trophy Year";
exports.trophynameLabel = "Trophy Name";
exports.trophycommentPlaceholder = "Trophy Comment";
exports.uploadText1 = "Click here to";
exports.uploadText2 = "your files.";
exports.uploadImgIext = "Max 6 attachments allowed.";
exports.browseText = "browse";
exports.allergensTitle = "Allergens";
exports.allergensSubtext = "Enter all possible allergens.";
exports.finishButton = "Finish";
exports.successTitle = "Wine Added Successfully";
exports.itemsuccessMsg ="Your wine product has been added successfully.";
exports.itemdescription = "Add more items or go back to your inventory.";
exports.backInvetoryButton = "Back to Inventory";
exports.addAnotherButton = "Add Another Item";
exports.finishButton = "Finish";
exports.successTitle = " Added Successfully";
exports.updateTitle = " Updated Successfully";
exports.yourText = "Your ";
exports.itemsuccessMsg =" product has been added successfully.";
exports.itemupdateMsg =" product has been updated successfully.";
exports.itemsuccessMsg2 =" has been added successfully.";
exports.itemupdateMsg2 =" has been updated successfully.";
exports.itemdescription = "Add more products or go back to your inventory.";
exports.itemdescription2 = "Add more items or go back to your inventory.";
exports.backInvetoryButton = "Back to Inventory";
exports.addAnotherButton = "Add Another Item";
exports.beerLocationFeild1 = "Style";
exports.eraLocationFeild = "Era";
exports.originFeild = "Region of origin";

exports.editText = "Edit";
exports.wineName = "Petite cave wine";
exports.itemPrice = "$789";
exports.yearText = "1992";
exports.wineType = "Red wine";
exports.wineVarity = "Abbuoto";
exports.itemInfoTitle = "Basic Information";
exports.sendButton = "Send to Contact";
exports.addFavButton = "Add to Contact";
exports.stockText = "In Stock";
exports.availableText = "Available";
exports.emptyValue = "----";
exports.ageAndVintage = "Age / Vintage";
exports.updateButton = "Update";

exports.moreDetailsTitle = "More Details";
exports.detailsSubtext = "Provide additional details about this product.";
exports.hopPleaceholder = "HOP";
exports.mashingPlaceholder = "Mashing";
exports.strengthPlaceholder = "Strength";
exports.fermentationPlaceholder = "Fermentation";
exports.ingredientPlaceholder = "Ingredients";
exports.uploadFileText = "Click here to";

exports.categoryText = "Category";
exports.styleText = "Style";
//chemical start
exports.brandEndPoint = "catalogue/brands";
exports.deleteBrandEndPoint = "bx_block_catalogue/brands";

exports.QuantityUnits = [{id:"Litre",value:"Liters (L)"}, {id:"g",value:"Grams (g)"}, {id:"kg",value:"Kilograms (kg)"}, {id:"pound",value:"Pounds (lbs)"}, {id:"oz",value:"Ounces (oz)"}];
exports.AreaUnits = [{id:"ha",value:"Hectare (ha)"}, {id:"acre",value:"Acre (ac)"}, {id:"m2",value:"Square Meters (m²)"}];
exports.VesselUnits = [{id:"Litre",value:"Liters (L)"},{id:"hl",value:"Hectoliters (hl)"}, {id:"US gal",value:"Gallons (gal)"}];
exports.targetEndPoint = "bx_block_catalogue/targets";
exports.createCatalogueEndPoint = "catalogue/catalogues";
exports.manageSelectorEndPoint = "bx_block_catalogue/selectors/"
exports.viewPermittedCategoryEndPoint = "/bx_block_inventory_management/view_categories"
exports.nullValueDisplay = "---"

exports.ProductsStaticId = 1
exports.WineStaticId = 1
exports.BeerStaticId = 2
exports.SpritStaticId = 3
exports.OtherProductStaticId = 4

exports.ToolsStaticId = 2
exports.MachineryStaticId = 5
exports.HardwareStaticId = 6

exports.AssetsStaticId = 3
exports.LandStaticId = 7
exports.VesselStaticId = 8

exports.ConsumablesStaticId = 4
exports.FertilizerStaticId = 9
exports.PhytoProductStaticId = 10
exports.CheimcalStaticId = 11
//chemical end

exports.commentPlaceholder = "Comment";
exports.tastingCommentPlaceholder = "Comments";
exports.attachmentText = "Attachments";
exports.descriptionPlaceholder = "Description";
exports.stockQuanPlaceholder = "Quantity in stock";
exports.serialPlaceholder = "Serial No.";
exports.revisionPlaceholder = "Revision Date";
exports.brandPlaceholder = "Start Typing Brand Title";
exports.helperText = "This is used to calculate inventory cost for projects.";
exports.perHourText = "Per Hour";
exports.hoursPlaceholder = "Hourly Cost";
exports.dollarSymbol = "$";
exports.allergensError = "Please enter Allergens";
exports.numberValidationError = "Please enter number only.";
exports.vintageErrorText = "Please enter only 4 digits for vintage.";
exports.ageErrorText = "Please enter max 3 digits for age.";
exports.errorValidationMsg = "Please enter valid value.";
exports.serialText = "Serial Number";
exports.errorValidationMsg = "Please enter 2 digits number only.";
exports.emptyProducerError = "Please enter producer";
exports.emptyProductError = "Please enter product name";
exports.emptyComplementError = "Please enter complement";
exports.emptyStyleError = "Please enter style";
exports.emptyCategoryError = "Please enter category";
exports.priceError = "Please add valid value of price";
exports.yearValidationMsg = "Please enter only 4 digits."
exports.serialText = "Sereal Number";
exports.hourSymbol = " / hr";

exports.itemDescription = "Wine is an alcoholic drink typically made from fermented grapes. Yeast consumes the sugar in the grapes and converts it to ethanol and carbon dioxide";
exports.dummyText = "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.";
exports.openingTimeError = 'Please enter valid time from 0.5 to 24.'
exports.pricePaceholder = "Start typing price label";
exports.agingError = "Please enter 4 digits in year."
exports.gtinError = "Please enter valid 13 digits GTIN.";
exports.maxDosageTxt="Max. Dosage";
exports.areaTxt="Area";
exports.addNewTargetTxt="Add New Target";
exports.noTargetFountTxt="No Target found with";
exports.targetTxt="Target";
exports.noTargetCreatedTxt="No Target to create";
exports.searchTargetTxt="Start Typing Target Title";
exports.unitOfMeasurementTxt="Unit of Measurement";
exports.vloumeTxt="Volume";
exports.riskTxt="Risk";
exports.nContentTxt="N Content";
exports.pContentTxt="P Content";
exports.kContentTxt="K Content";
exports.addNewBrandTxt="Add New Brand";
exports.noBrandTxt="No Brand found with";
exports.noBrandCreatedTxt="No Brand to create";
exports.expirationDateTxt="Expiration Date";
exports.lotNumberTxt="Lot No.";
exports.costTxt="Cost";
exports.perTxt="per";
exports.quantityInfoTxt ="Quantity unit of measurement chosen in basic info";
exports.addColorTxt="Add New Color";
exports.noColorFoundTxt="No Color found with";
exports.noColorCreatedTxt="No Color to create";
exports.searchColorTxt="Start Typing Color Name";
exports.addNewMaterialTxt="Add New Material";
exports.noMaterialFoundTxt ="No Material found with";
exports.materialTxt="Material";
exports.noMaterialCreatedTxt="No Material to create";
exports.searchMaterialTxt="Start Typing Material";
exports.addNewSpecificationTxt="Add New Specification";
exports.noSpecificationFoundTxt ="No Specification found with";
exports.specificationsTxt="Specifications";
exports.noSpecificationCreatedTxt="No Specification to create";
exports.enterPriceTxt="Enter Price";
exports.itemPriceTxt ="Item Price";
exports.confirmationMsgForColorTxt="Are you sure you want to delete color ?";
exports.deleteColorTxt="Delete Color";
exports.confirmationMsgForBrandTxt="Are you sure you want to delete Brand ?";
exports.deleteBrandTxt="Delete Brand";
exports.googleMapTxt="Google Maps Url";
exports.nContentTxtSmall="n content";
exports.kContentTxtSmall="k content";
exports.pContentTxtSmall="p content";
exports.brandTextSmall="brand";
exports.materialTxtSmall="material";
exports.specificationsTxtSmall="specifications";
exports.serialNumberTxtSmall="serial no.";
exports.colorTxtSmall="color";
exports.lotNumberTxtSmall="Lot no.";
exports.costQuantityTxt="Cost/Quantity";
exports.totalAreaTxtSmall="total area";
exports.valumeTxtSmall="volume";
exports.maxDosageTxtt ="Max dosage";
exports.wineDrinkTxt ="Wine is an alcoholic drink typically made from fermented grapes. Yeast consumes the sugar in the grapes and converts it to ethanol and carbon dioxide";
exports.startTypingStockTxt ="Start typing in stock available"
exports.searchTxt="Search";
exports.quantityUnitTxt = "Quantity Unit";
exports.areaUnitTxt="Area Unit";
exports.vloumeUnitTxt="Volume Unit";
exports.noPriceFountTxt="No Price found with";
exports.noPriceLabelTxt="Please enter Price Label";
exports.priceLabelTxt="Price Label";
exports.searchPriceTxt="Start Typing Price Label";
exports.noPriceErorrTxt="Please add at least one Price";
exports.inventoryNoFilterItem = "There are no items that match your search.";
exports.modalConfirmation="Are you sure you want to delete Products ?";
exports.deleteProduct="Delete Product";
exports.priceNameTxt="Price";
exports.contactsLists="/bx_block_contactslist/contacts";
exports.addAsFavPRoductAPiEndpoint="/bx_block_contactslist/add_favourite_products";
exports.exportInventoryEndPoint="/bx_block_catalogue/export_inventory?"
exports.exportInventoryTitle="Export Inventory";
exports.exportInventorySubTitle="Please choose the item type you would like to download";
exports.exportInventoryBtnDownload="Download"
exports.addasfavorite = "Add as favorite"
exports.sendToContactTxt = "Send to contact";
exports.inventoryText="View your contacts in the marketing tab.";
exports.inventoryHead="Added to Contact's Favorite Products";
exports.chooseContactTxt="Choose Contact";
exports.selectContactTxt="Select contacts to add these products as their favorites";
exports.contactsText="Contacts";
exports.contactText="Contacts";
exports.editProductText="Edit Products";
exports.adjustDataText="Adjust the items you want to continue with.";
exports.adjustQuantityTxt="Adjust Quantity";
exports.chooseContacts="Choose Contacts";
exports.subText="Select the contacts to send these product listings to.";
exports.createContactTxt="Create Contact";
exports.writeMessage="Write Message";
exports.craftMsg="Craft your message and send.";
exports.modifyContactTxt="Modify contact";
exports.subjectTxt="Subject";
exports.messageTxt="Message";
exports.nameValidationApi="bx_block_catalogue/validate_catalogue_name"
// Customizable Area End
