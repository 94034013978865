import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { ResponseDataType,ImageDataType } from "./InventoryTypes";
import StorageProvider from "../../../framework/src/StorageProvider";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  seletedItem: string;
  stepNumber: number;
  backBtn: ()=>void;
  nextBtn: ()=>void;
  // Customizable Area End
}

interface S {
// Customizable Area Start
product: string;
producer: string;
quantity: string;
colour: string | unknown;
complement: string;
verityPercent: string ;
brand: string;
age:  string;
ageTab: boolean;
imageFile: string[];
imagePreview: ImageDataType[];
prodImages: ImageDataType[];
vintage: string;
isModalOpen: boolean;
dosage: string | unknown;
grapeVariety: string | unknown;
rawMaterial: string | unknown;
rawMaterialPercent: string;
error: {
  product: string;
  producer: string;
  complement: string;
  brand: string;
}
grapeVarietyData : {
    id: number,
    name: string,
}[];
colorData: {
    id: number,
    name: string,
}[];
dosageData: {
    id: number,
    name: string,
}[];
rawMaterialData:{
    id: number,
    name: string,
}[];
style: string;
category:string ;
categoryData:{
  id: number,
  name: string,
}[];
errorRawMaterialPercent: string;
errorVerityPercent: string;
ageError: string;
vintageError: string;
ageValue: string;
vintageValue: string;
removedImages: number[];
selectSearchVal:string;
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class ItemBasicInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  getGrapeVarietyApiCallId:string = "";
  getColorApiCallId:string = "";
  getDosageApiCallId: string = "";
  getRawMaterialApiCallId: string = "";
  getCategorySpiritApiCallId: string = "";
  userSessionData: any;
  userToken: {meta:{token:string}};
  numValidation:RegExp;
  ageLimit :RegExp;
  vintageLimit:RegExp;
  onlyNumberValidate:RegExp;
  grapePercent:RegExp;
  validationApiId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      product: '',
      producer: '',
      quantity: '',
      colour: '',
      complement: '',
      verityPercent: '',
      brand: '',
      age: '',
      ageTab: false,
      imageFile: [],
      imagePreview: [],
      prodImages: [],
      vintage: '',
      isModalOpen: false,
      dosage: '',
      grapeVariety: '',
      rawMaterial: '',
      rawMaterialPercent: '',
      grapeVarietyData : [],
      colorData: [],
      dosageData: [],
      rawMaterialData: [],
      style: '',
      category: '',
      categoryData:[],
      error: {
        product: '',
        producer: '',
        complement: '',
        brand: '',
      },
      errorRawMaterialPercent:'',
      errorVerityPercent: '',
      ageError: '',
      vintageError: '',
      ageValue: '',
      vintageValue: '',
      removedImages:[],
      selectSearchVal: '',
      // Customizable Area End
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData); 
    this.numValidation = new RegExp(/^([1-9]\d*|0)(\.\d*)?$/);
    this.ageLimit = new RegExp(/^\d{1,3}$/);
    this.vintageLimit = new RegExp(/^\d{1,4}$/);
    this.onlyNumberValidate = new RegExp(/^\d*$/);
    this.grapePercent=new RegExp(/^(100|[1-9]\d?)$/);
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if(apiRequestCallId && responseJson){
          switch (apiRequestCallId){
            case this.getGrapeVarietyApiCallId:
              this.grapeVarietyResponse(responseJson);
              break;
            case this.getColorApiCallId:
              this.colorResponse(responseJson);
              break;
            case this.getDosageApiCallId:
              this.dosageResponse(responseJson)
              break;
            case this.getRawMaterialApiCallId:
              this.rawMaterialResponse(responseJson);
              break;
            case this.getCategorySpiritApiCallId:
              this.categoryResponse(responseJson);
            break;           
            default:
            break;
          }
        }
    }
    // Customizable Area End
  }

grapeVarietyResponse=(response:ResponseDataType)=>{
  this.setState({grapeVarietyData: response.options});
}
colorResponse=(response:ResponseDataType)=>{
  this.setState({colorData: response.options});
}
dosageResponse=(response:ResponseDataType)=>{
  this.setState({dosageData: response.options});
}
rawMaterialResponse=(response:ResponseDataType)=>{
  this.setState({rawMaterialData: response.options});
}
categoryResponse=(response:ResponseDataType)=>{
  this.setState({categoryData: response.options})
}

handleColour = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ colour: event.target.value })
}
getRadioStyle = (color: string) => {
  return {
    color: color.toLowerCase(), // Assuming color names are lowercase
  };
};
handleProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
  const productValue = event.target.value;
  this.setState({ product: productValue });    
}
handleproducer = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ producer: event.target.value })
}
handleBrand = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ brand: event.target.value })
}
handleQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
  let quantitynum = event.target.value;
  if(this.numValidation.test(quantitynum) || quantitynum==''){
    this.setState({ quantity: quantitynum})
  }
}
handleComplement = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ complement: event.target.value })
}
handleNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const name = event.target.name as keyof S;
  let value:string = event.target.value;  
  let isValidInput = this.onlyNumberValidate.test(value);
  switch(name){
    case "age":
      isValidInput = this.ageLimit.test(value);
      break;
    case "vintage":
      isValidInput = this.vintageLimit.test(value);
      break;
    case "verityPercent":
      isValidInput= this.grapePercent.test(value);
  }
  
  if (isValidInput || value === '') {
    if(value!=''){
      this.setState({ [name]: Number(value) } as unknown as Pick<S, keyof S>);
    }
    else{
      this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
    }
    if(name == "age" || name == "vintage"){
      this.setState({ageError:""})
    }
  }
}

handleDosage = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ dosage: event.target.value })
}
handleGrapeVeriety = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ grapeVariety: event.target.value })
}
handleRawMaterial = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ rawMaterial: event.target.value })
}

handleCategory = (event: React.ChangeEvent<{ value: unknown; }>) => {
  this.setState({ category: String(event.target.value) })
}
handleStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ style: event.target.value })
}
continueAgeVintage=()=>{
    if(this.state.ageTab === true){
      if(this.ageLimit.test(String(this.state.age))){
        this.setState({
          ageValue: String(this.state.age),
          vintageValue: String(this.state.vintage),
          isModalOpen: false,
          ageError: '',
        })
      }else{
        this.setState({ageError: configJSON.ageErrorText })
      } 
    }
    else if(this.vintageLimit.test(String(this.state.vintage))){
      this.setState({
        ageValue: String(this.state.age),
          vintageValue: String(this.state.vintage),
          isModalOpen: false,
          ageError: '',
      })
    }else{
      this.setState({ageError: configJSON.vintageErrorText })
    }
}
chooseAgeTab = () => {
    this.setState({ ageTab: true,vintage:this.vintageLimit.test(String(this.state.vintage))?this.state.vintage:this.state.vintageValue,ageError:""
    })
}
chooseVintageTab = () => {
    this.setState({ ageTab: false,age:this.ageLimit.test(String(this.state.age))?this.state.age:this.state.ageValue,ageError:""
    })
}
uploadFile = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files != null){
        const selectedFiles:ImageDataType[] = Array.from(e.target.files).map((file) => ({
          name: file.name,
          url: URL.createObjectURL(file),
          type: file.type,
        }));
        const addedImgLength = this.state.imagePreview.length;
        if(addedImgLength<6){
          this.setState({imagePreview: [...this.state.imagePreview,...selectedFiles.slice(0,6-addedImgLength)]});
        }
    }
}
onClickEditIcon = () => {this.inputRef.current && this.inputRef.current.click()}

vintageModal = () => {
    this.setState({ isModalOpen: true,ageError: '',age:this.state.ageValue,vintage:this.state.vintageValue })
}
closeModal = () => {
    this.setState({ isModalOpen: false })
}

delImage=(index:number)=>{
  const newImgArr = [...this.state.imagePreview.slice(0, index), ...this.state.imagePreview.slice(index + 1)];
  this.setState({imagePreview: newImgArr});
}

inputValidations =()=>{
  let error = this.state.error;
  let errorStatus = false;
  if(this.state.product.trim() == ""){
    error.product = configJSON.emptyProductError
    errorStatus = true;
  }
  if(errorStatus){
    this.setState({error: error})
  }
  return errorStatus  
}
 
handleItemInfo=()=>{
  let errorFlag = this.inputValidations();
  if(errorFlag){
    return
  }else{
    const infoData = {
      "product_name": this.state.product,
      "producer": this.state.producer,
      "brand": this.state.brand,
      "grape_variety":this.state.grapeVariety,
      "grape_variety_per": this.state.verityPercent,
      "complement": this.state.complement,
      "color": this.state.colour,
      "dosage": this.state.dosage, 
      "quantity": this.state.quantity,
      "raw_material": this.state.rawMaterial,
      "raw_material_per": this.state.rawMaterialPercent,
      "item_image": this.state.imagePreview,
      "prodImages":this.state.prodImages,
      "removedImages":this.state.removedImages,
      "age": this.state.age ?? "",
      "vintage": this.state.vintage ?? "",
      "category":this.state.category,
      "style":this.state.style,
      "ageTab":this.state.ageTab,
    };
    StorageProvider.set("itemInfodata", JSON.stringify(infoData));
    this.props.nextBtn()
  }
}

getGrapeVariety = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGrapeVarietyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "grape variety"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};

getColors = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getColorApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "color"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};

getDosage = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDosageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "dosage"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};

getRawMaterial = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRawMaterialApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "raw material"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};

getSpiritCategory= () => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCategorySpiritApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.productsDropdownAPIEndPoint + "category"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

 setDataFromStorage = (basicJSON:any) => {
  this.setState({
    product: basicJSON.product_name ,
    producer: basicJSON.producer ,
    quantity: basicJSON.quantity ?? '',
    colour: basicJSON.color ?? '',
    complement: basicJSON.complement,
    verityPercent: basicJSON.grape_variety_per ?? '',
    brand: basicJSON.brand ?? '',
    age: basicJSON.age ,
    grapeVariety: basicJSON.grape_variety ?? '',
    dosage:basicJSON.dosage ?? '',
    rawMaterial: basicJSON.raw_material ?? '',
    rawMaterialPercent: basicJSON.raw_material_per ?? '',
    vintage:basicJSON.vintage ,
    ageValue: basicJSON.age,
    vintageValue: basicJSON.vintage,
    style:basicJSON.style,
    category:basicJSON.category,
    prodImages:basicJSON.prodImages,
    imagePreview:basicJSON.item_image ?? [],
    removedImages:basicJSON.removedImages ?? [],
    ageTab:basicJSON.ageTab,
  })
 }
  async componentDidMount(): Promise<void> {
    const basicData =  await StorageProvider.get("itemInfodata");
    if(basicData){
      const basicJSON = await JSON.parse(basicData)
      this.setDataFromStorage(basicJSON)
    }
    this.getGrapeVariety()
    this.getColors()
    this.getDosage()
    this.getRawMaterial()
    this.getSpiritCategory()
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
   t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.removeError(prevState)  
  }
  removeError = (prevState:S) => {
    let productError = this.state.error;
    let removeError = false;
  
  
    if (prevState.product !== this.state.product) {
      productError.product = "";
      removeError=true;
    }
    if (prevState.producer !== this.state.producer) {
      productError.producer = "";
      removeError=true;
    }
    if (prevState.complement !== this.state.complement) {
      productError.complement = "";
      removeError=true;
    }
    
    if (removeError) {
      this.setState({ error: productError });
    }
  };
  
  removeAddedProdImage = (id:number) => {
    const updatedArray = this.state.prodImages.filter((img:ImageDataType)=>img.id!=id)
    this.setState({ prodImages: updatedArray,removedImages:[...this.state.removedImages,id] });
  }
  multiSelectSearchChange = (event:any) => {
    this.setState({
      selectSearchVal: event.target.value
    },() => this.displayGrapeOptions())
  }
  clearSearchValue = () => {
    this.setState({
      selectSearchVal: "",
    })
  }
  containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  displayGrapeOptions = () => {
   return this.state.grapeVarietyData.filter((obj: any) => this.containsText(obj.name, this.state.selectSearchVal));

  }
  // Customizable Area End
}
